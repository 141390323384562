import React, {useEffect, useState} from "react"

// import { AvField } from "availity-reactstrap-validation"
// import { useOvermind } from "../../../../overmind";

import { loadOptions } from "../../../constants/remoteLists";
import Select from "react-select"
import {customStyles} from "./customStyles";
import {useOvermind} from "../../../overmind";

function CustomSelect(props) {
	const {isMulti, listId=''} = props
	const [options, setOptions] = useState(props.options || [])
	const [mappedOptions, setMappedOptions] = useState([])
	const [loading, setLoading] = useState(listId ? true : false)
    const [value, setValue] = useState(props.value)
    const clients = useOvermind('clients')
    const [mappedValue, setMappedValue] = useState(null)


    // const {
    //     state   : {
    //         [formId]:{}
    //     },
    //     actions : {
    //         getModelValueByPath
    //     }
    // } = useOvermind('forms')

    const handleChange = (selected) => {
		const value = isMulti
			? selected.reduce(function(map, obj) {
				map.push(obj.value)
				return map;
			}, [])
			: selected?.value || ''

        if (props.onChange)
            props.onChange(value)


		//console.log('setValue', value)
        setValue(value)

    }

	const getMappedOptions = (options) => {
		const s2Options = options && options.reduce(function(map, obj) {
			map.push({value:obj.id, label:obj.caption, canRemove:obj.canRemove})
			return map;
		}, isMulti || props.noEmptyOption ? [] : [{value:'', label:props.emptyOptionText||<b>(vazio)</b>}])

		// return [
		// 	{label:props.caption, options:s2Options},
		// ]
		//console.log('s2Options', s2Options)
		return s2Options
	}

	useEffect(() => {

		setMappedOptions(getMappedOptions(options))
	}, [options])

	useEffect(() => {
		// if (!value || !mappedOptions)
		// 	return
		//if (props.name=='address.state') alert(value)
		// console.log('fired value', {name:props.name, value})

		if (value) {
			//console.log('value', value)
			const values = isMulti
				? value?.reduce(function(map, objValue) { //reducer to keep added order
					const opt = mappedOptions?.find(option => option.value==objValue)
					if (opt) {
						map.push(opt)
					}
					return map;
				}, [])
				//mappedOptions?.filter(option =>value.includes(option.value))
				: mappedOptions?.find(option => option.value==value)
			// const values = isMulti
			// 	? mappedOptions?.reduce(function(map, obj) {
			// 		if (value.indexOf(obj.value)>-1)
			// 			map.push(obj)
			// 		return map
			// 	}, [])
			// 	: mappedOptions?.find(option => option.value==value)
			//console.log('values', {values, value, mappedOptions})
			setMappedValue(values || [])
		} else {
			setMappedValue([])
		}
	}, [mappedOptions, value, props.value])


	useEffect(() => {
		//console.log(`${props.name} changed `, props.modelValue)
		if (props.value)
			setValue(props.value)
	}, [props.value])

	useEffect(() => {
		if (listId) {
			setLoading(true)
			const params = props.listParams ? {clients} : null
			loadOptions(listId, params)
				.then((res) => {
					//console.log("OPTIONS", res)
					//const value=getModelValueByPath({formId, path:props.name})
					//alert(value)
					setOptions(res)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, [listId]);

	return <Select
		styles={customStyles}
		classNamePrefix="select2-selection"
		{...props}


		options={mappedOptions || []}
		// value={mappedValue || ''}
		value={mappedValue}
		//value={{value:'AM', label:'none'}}
		placeholder={mappedValue?.label || ''}
		//value=""
		menuPortalTarget={props.menuPortalTarget || document.body}

		onInputChange={(value, action) => {
			if (action.action !== "input-blur" && action.action !== "menu-close") {
				//console.log('onInputChange', value)
				//setValue(value)
			}
			// only set the input when the action that caused the
			// change equals to "input-change" and ignore the other
			// ones like: "set-value", "input-blur", and "menu-close"
			//if (action.action === "input-change") setValue(value); // <---
		}}

		// onBlurResetsInput={false}
		type={"text"}


		autoComplete="disabled"

		isMulti={isMulti}
		// onFocus={handleFocus}
		onChange={handleChange}
		required={props.required}
		// validate={{val:() => isValidNumber(value, props) }}
		validate={props.validation}
		noOptionsMessage={() => <div className="text-danger">(sem mais opções)</div>}

	/>
}

export default CustomSelect;
