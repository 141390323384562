import api from "../../helpers/gestora/api";

export  const listBlockedDevices = ( { state }, clientId) => {
	return api
		.get(`blockedDevice/admin/list/${clientId}`)
		.then(response => response.data)
}

export  const saveBlockedDevices = ( { state }, {clientId, deviceTypes}) => {
	return api
		.post(`blockedDevice/admin/block/${clientId}`, {deviceTypes})
		.then(response => response.data)
}

export  const unblockDevices = ( { state }, {clientId, answers, deviceTypes, forceByAdmin=false}) => {
	return api
		.post(`blockedDevice/admin/unblock/${clientId}`, {answers, deviceTypes, forceByAdmin})
		.then(response => response.data)
}

export  const listHistory = ( { state }, clientId) => {
	return api
		.get(`blockedDevice/admin/history/${clientId}`)
		.then(response => response.data)
}

export  const listClientDevices = ( { state }, {clientId, ...filters}) => {
	return api
		.post(`clientDevice/admin/list/${clientId}`, filters)
		.then(response => response.data)
}

export  const deleteSessions = ( { state }, {clientId, ...filters}) => {
	return api
		.post(`clientDevice/admin/delete/${clientId}`, filters)
		.then(response => response.data)
}

export const changeClientPassword = ( { state }, {clientId}) => {
	return api
		.post(`admin/clients/changePass/${clientId}`)
		.then(response => response.data)
}


