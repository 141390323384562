import React, {createRef, useEffect, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
    Button, Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";
import Field from "../../../../components/Common/CustomForm/Field";
import {
	RegFormApprovalRenewEnum,
	registrationFormRenewReasonOptions
} from "../../../../constants/formLists";

import {dateBrToISO} from "../../../../renders/utils";

function ChangeRenewStatusModal(props) {

    const {
    	formId,
		changingStatus,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

    const {
        state   : {
            [formId]:{model}
        },
        actions : {
			submitForm, setModelValueByPath
        }
    } = useOvermind('forms')

	const client = useOvermind('clients')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [error, setError] = useState(false)
	const [loaded, setLoaded] = useState(false)

    useEffect(() => {
		//if (cancelButtonRef) cancelButtonRef.focus()

		const currentTime = new Date()
		const date = moment(currentTime).format('DD/MM/YYYY')
		const time = moment(currentTime).format('HH:mm')

		setModelValueByPath({
			formId,
			path:'changeStatus',
			value : {date, time}
		})
		setLoaded(true)
    }, [])

	const handleConfirm = async (e, values, action) => {

    	setSaving(true)

		//console.log(values)

		const createDate = `${dateBrToISO(values.changeStatus.date.trim())} ${values.changeStatus.time.trim()}`
		//alert(createDate)

    	client.actions.saveRegistrationFormRenewStatus({
				formId,
				...values.changeStatus,
				createDate:createDate,
				status:changingStatus.id,
			})
			.then(() => {
				setSaved(true)
				onConfirm(changingStatus.id)
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch(() => {
				setError(true)
				setSaving(false)
				setSaved(false)
			})

		//e.preventDefault()
    	//onConfirm()
	}


	const handleCancel = () => {
    	onCancel()
	}
	if (!loaded)
		return null

    return (
        <>
			<SweetAlert
				title={
					(saving && !error)
						? (saved
							? "Salvo!"
							: "Salvando..."
						)
						: error
							? "Erro"
							: "Confirmação"
					}
				// warning={!error && !saving}
				style={{
					overflow:'auto',
					marginTop:'80px',

				}}
				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				onConfirm={handleConfirm || null}
				onCancel={handleCancel || null}
			>
				{error ? (
						<>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					saving ? (
						<>
							<br/>
							{client.state.savingText}
							<br/>
						</>
					) : (
						<>
							<Row>
								<Col md={12} className="p-4 pb-0">
									<Row className="p-0 pb-4">
										<Col md={12}>
											Alterar status de renovação para {" "}
											<strong className={`text-${changingStatus.color}`} style={{fontWeigh:800}}>
												{changingStatus?.caption}
											</strong>?
										</Col>
									</Row>
									{
										changingStatus.id==RegFormApprovalRenewEnum.NOT_APPROVED
										? (
										<Row className="pb-0 " style={{textAlign:'left'}}>
											<Col md={12}>
												<Field
													className="font-size-em1"
													type="title"
													id="changeStatus.title"
													formId={formId}
													caption="Detalhes"
												/>
												{changingStatus.id==RegFormApprovalRenewEnum.NOT_APPROVED ?
													(
													<Field
														globalReadOnly={false}
														type="select"
														options={registrationFormRenewReasonOptions}
														required={true}
														legacyValidation={true}
														id="changeStatus.reason"
														value={model.changeStatus?.reason}
														formId={formId}
														caption="Motivo"
													/> ) : null}

												<Field
													globalReadOnly={false}
													legacyValidation={true}
													required={false}
													type="textarea"
													id="changeStatus.description"
													value={model.changeStatus?.description}
													formId={formId}
													caption="Observações (opcional)"
													// placeHolder={"(opcional)"}
												/>
											</Col>
										</Row>
									) : null}

										<Row className="p-0" style={{textAlign:'left'}}>
											<Col md={12}>
												<Field
													type="title"
													id="changeStatus.datetimeTitle"
													formId={formId}
													caption="Data e horário"
												/>
												<Field
													globalReadOnly={false}
													type="date"
													required={true}
													legacyValidation={true}
													id="changeStatus.date"
													value={model.changeStatus?.date}
													formId={formId}
													caption="Data"
												/>
												<Field
													globalReadOnly={false}
													type="time"
													required={true}
													legacyValidation={true}
													id="changeStatus.time"
													value={model.changeStatus?.time}
													formId={formId}
													caption="Horário"
												/>
											</Col>
										</Row>

									<Row className="p-0 m-0">
										<Col md={12} className="p-0 m-0 d-flex align-items-center justify-content-center" >
											<button
												className="btn btn-outline-danger"
												onClick={handleCancel}
												style={{margin:'1em'}}
											>
												Cancelar
											</button>
											<Button
												autoFocus
												color={'primary'}
												style={{margin:'1em'}}
												type="button"
												onClick={() => {
													submitForm({formId, action:'savestatus', method:handleConfirm})
												}}
											>
												Confirmar
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					)
				)}

			</SweetAlert>

        </>
    )
}

export default ChangeRenewStatusModal;
