import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import {sleep} from "../../../../helpers/gestora/api";
import Button from "../../../../components/Common/Button/Button"
import {onlyNumbers, optionValue} from "../../../../renders/utils";
import {parseMoney} from "../../../../helpers/parser_helper";
import {yesNoOptions} from "../../../../constants/formLists";
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";


function FundTypesView(props) {

    const {
    	formId,
		viewId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const readOnly = !UserCan('funds.edit')

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const fundTypes = useOvermind('fundTypes')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [data, setData] = useState(null)

    useEffect(() => {
    	setError(false)
    	setSaving(false)
    	setSaved(false)
		setLoading(true)
		// resetForm({formId})
		fundTypes.actions.loadById({formId, id:viewId})
			.then(record => {

				setData(record)

				record.publishNewVersion = '0'

				setModelValueByPath({formId, path:'edit', value:record})
				incFormValidatedCount({formId})

			})
			.catch(e => {
				setError(e.response?.data || e.toString())
			})
			.finally(() => {
				setLoading(false)
			})

    }, [viewId])

	const handleConfirm = async (e) => {

		const values = _.cloneDeep(model)

		//console.log(values)

		//const createDate = `${dateBrToISO(values.changeStatus.date.trim())} ${values.changeStatus.time.trim()}`
		//alert(createDate)

		const data = {
			...values.edit,
            //cnpj : onlyNumbers(values.edit.cnpj), //saving as string
            initialValue : parseMoney(values.edit.initialValue),
            minimumValue : parseMoney(values.edit.minimumValue),
            stayValue : parseMoney(values.edit.stayValue),
            minimumRescueValue : parseMoney(values.edit.minimumRescueValue),
			publishNewVersion : values.edit.publishNewVersion==='1',
		}

		if (data.publishNewVersion && !confirm('Tem certeza que deseja publicar uma nova versão do termo?'))
			return



		console.log('handleConfirm',data)


    	setSaving(true)
    	fundTypes.actions.save({
				formId,
				id:viewId,
				data,
			})
			// .then(sleep(1000))
			.then(() => {
				setSaved(true)
				onConfirm()
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch((e) => {
				setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
				setSaving(false)
				setSaved(false)
			})

		//e.preventDefault()
    	//onConfirm()
	}

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'titleModal',
					caption:'Detalhes do Fundo',
					type:'title',

					col:12
				},
				{
					id:'edit.name',
					caption:'Nome do fundo',
					type:'text',
					required:true,
					readOnly,
					row:true,
					col:12
				},
				{
					id:'edit.bank',
					caption:'Banco',
					type:'text',
					readOnly,
					required:true,
					col:6
				},
				{
					id:'edit.cnpj',
					caption:'CNPJ',
					type:'cnpj',
					readOnly,
					required:true,
					col:6
				},
				{
					id:'edit.agency',
					caption:'Agência',
					type:'text',
					readOnly,
					// emptyOptionText : <b>Todos</b>,
					required:true,
					col:4
				},
				{
					id:'edit.account',
					caption:'Conta',
					type:'number',
					readOnly,
					// emptyOptionText : <b>Todos</b>,
					required:true,
					col:4
				},
				{
					id:'edit.digit',
					caption:'Dígito',
					type:'number',
					// emptyOptionText : <b>Todos</b>,
					readOnly,
					required:true,
					col:4
				},
				{
					id:'titleRescue',
					caption:'Aporte',
					readOnly,
					type:'title',
					col:12
				},
				{
					id:'edit.initialValue',
					caption:'Aporte mínimo inicial',
					type:'money',
					readOnly,
					required:true,
					col:6
				},
				{
					id:'edit.minimumValue',
					caption:'Aporte mínimo subsequente',
					type:'money',
					readOnly,
					required:true,
					col:6
				},
				{
					id:'titleRescue',
					caption:'Resgate',
					readOnly,
					type:'title',
					col:12
				},
				{
					id:'edit.stayValue',
					caption:'Valor mínimo de permanência',
					type:'money',
					readOnly,
					required:true,
					col:6
				},
				{
					id:'edit.minimumRescueValue',
					caption:'Valor mínimo de resgate',
					type:'money',
					readOnly,
					required:true,
					col:6
				},
				{
					id:'edit.termQuotation',
					caption:'Prazo Cotização',
					type:'text',
					row:true,
					readOnly,
					required:true,
					col:6
				},
				{
					id:'edit.termRescue',
					caption:'Prazo Resgate',
					readOnly,
					type:'text',
					required:true,
					col:6
				},
				{
					id:'titleFiles',
					caption:'Arquivos',
					readOnly,
					type:'title',
					col:12
				},
				{
					id:'edit.publishNewVersion',
					caption : 'Publicar nova versão?',
					info    : 'Escolhendo "Sim" será solicitado aos clientes realizarem uma nova assinatura do termo de adesão.',
					type    : 'radiogroup',
					options:yesNoOptions,
					readOnly,
					required:true,
					col:12,
					inline:true,
				},
			    {
			    	id:'edit.bladeFile',
					caption:'Lâmina',
					type:'upload',
					accept:'.pdf',
					readOnly,
					file_caption:'Lâmina',
					required:true,
					row:true,
					col:6
				},
			    {
			    	id:'edit.regulationFile',
					caption:'Regulamento',
					type:'upload',
					accept:'.pdf',
					readOnly,
					file_caption:'Regulamento PDF',
					required:true,
					col:6
				},
			],
		},
	], [viewId]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	const handleCancel = () => {
    	onCancel()
	}

	if (!viewId)
		return null

	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <UserCanAll permission="funds.consult">
			<SweetAlert
				// title={<h7 className="text-primary font-weight-700 font-family-title font-size-rem3 ">Detalhes do Fundo</h7>}
				// warning={!error && !saving}

				style={{
					overflow:'auto',
					marginTop:'0px',
					width:'60%',
					maxHeight:'100%',
					minHeight:'300px',
					justifyContent:'flex-start',

				}}
				openAnim={{ name: 'opacityIn', duration: 500 }}
				//closeAnim={{ name: 'slideOut', duration: 500 }}

				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						<Row className="pr-4 ps-4">
							<Col md={12} className="pt-2 ">
								<Row >
								<Col md={12} className="w-100 d-block align-items-start">
									<FormWizard
										validationInRealTime={true}
										screenId={formId}
										screens={statusScreens}
										formId={formId}
										// OnInputValueChange={handleInputValueChange}
										tabId={formId}
									/>
								</Col>
								</Row>
								<Row >
									<Col md={12} className="d-flex align-items-center justify-content-center">
										<Button
											autoFocus
											className="btn btn-outline-danger"
											onClick={handleCancel}
											style={{margin:'1em'}}
										>
											Fechar
										</Button>
										<UserCanAll permission="funds.edit">
										<Button
											loading={saving}
											disabled={!!formValidationResult?.first}
											colorIcon={true}
											className="btn btn-primary "
											// disabled={currentScreen==0}
											style={{margin:'1em'}}
											onClick={handleConfirm}
											>
											Salvar
										</Button>
										</UserCanAll>
									</Col>
								</Row>
							</Col>
						</Row>
					</LoadingSection>
				)}

			</SweetAlert>

        </UserCanAll>
    )
}

export default FundTypesView;
