import {isCpfFormat} from "../components/Common/CustomForm/Utils/Validations";
import {formatCnpj, formatCpf} from "../renders/utils";

export const parseMoney = (value) => {
	if (!value)
		return 0
    const negative = value.length>0 && value[0]=='-'

    const parsedValue = value
		.toString()
        .replace('-R$ ', '')
        .replace('R$ ', '')
        .replace(/\_/g, '')
        .replace(/\./g, '')
        .replace(/\,/g, '.')

    return new Number(negative ? '-'+parsedValue : parsedValue)
}

export const parsePercent = (value) => {
	if (!value)
		return 0
    const negative = value.length>0 && value[0]=='-'

    const parsedValue = value
		.toString()
        .replace('-', '')
        .replace('%', '')
        .replace(/\_/g, '')
        .replace(/\./g, '')
        .replace(/\,/g, '.')

    return new Number(negative ? '-'+parsedValue : parsedValue)
}


export const parseDocument = (value) => {

	try {
		if (isCpfFormat(value)) {
			return formatCpf(value)
		} else {
			return formatCnpj(value)
		}
	} catch(e) {
		return value
	}

}


export const formatPhone = (phone) => {

	try {
		const formatNumber = phone.length === 11
			? phone.match(/(\d{2})(\d{5})(\d{4})/)
			: phone.match(/(\d{2})(\d{4})(\d{4})/);
			return '('+ formatNumber[1]+') '+formatNumber[2]+'-'+formatNumber[3];
	} catch(e) {
		return phone
	}

}


