import React from "react"
import RegistrationFormSection from "../RegistrationFormSection";

import {securityScreens} from "../Configuration/SecurityDataConfig";

function SecurityData(props) {
    return <RegistrationFormSection screens={securityScreens} {...props} />
}

export default SecurityData;
