import React, {createRef, useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";
import {
	registrationFormPendingReasonOptions
} from "../../../constants/formLists";

import {dateIsoToBr} from "../../../renders/utils";
import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";

import api, {sleep} from "../../../helpers/gestora/api";
import Button from "../../../components/Common/Button/Button"


function InvestmentsViewPending(props) {

    const {
    	formId,
		viewId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

    const {
        state   : {
            [formId]:{model}
        },
        actions : {
			setModelValueByPath
        }
    } = useOvermind('forms')

	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'view.createDate',
					caption:'Data do envio',
					type:'text',
					disabled:true,
					row:true,
					col:12
				},
				{
					id:'view.reason',
					caption:'Motivo',
					type:'select2',
					noEmptyOption:true,
					options:registrationFormPendingReasonOptions,
					disabled:true,
					required:true,
					row:true,
					col:12
				},
				{
					id:'view.message',
					caption:'Mensagem',
					type:'textarea',
					disabled:true,
					required:true,
					row:true,
					col:12
				},
			],
		},
	], [viewId]);

    useEffect(() => {
    	if (!viewId)
    		return

    	setError(false)
    	setSaving(false)
    	setSaved(false)
		setLoading(true)
		api
			.get(`registrationForm/statusbyid/${viewId}`)
			//.then(sleep(2000))
			.then(res => {
				console.log('registrationForm/statusbyid', res.data)
				const data = res.data
				setModelValueByPath({formId, path:'view.message', value:data.message})
				setModelValueByPath({formId, path:'view.createDate', value:dateIsoToBr(data.createDate)})
				setModelValueByPath({formId, path:'view.reason', value:data.reason
				})

				// if (res.data.length>0) {
				// 	setCurrentStatus(parseStatus(res.data[0]))
				// }
				// setRegFormStatus(
				//
				// 	statusOptions.find(option => option.id == client.state.currentRegistrationForm?.form?.registrationForm?.isApprovedAdm)
				//
				// )
				// setHistory(res.data)
			})
			.catch(e => {
				setError('Não foi possível carregar as informações de status')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
		}, [viewId])



	const handleConfirm = async (e) => {
		onConfirm()
	}


	const handleCancel = () => {
    	onCancel()
	}

	if (!viewId)
		return null

    return (
        <>
			<SweetAlert
				title={"Motivo da pendência"}
				style={{
					overflow:'auto',
					maxHeight:'100%'
				}}

				success={null}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						<Row>
							<Col md={12} className="pt-4">
								<FormWizard
									screenId={formId}
									screens={statusScreens}
									formId={formId}
									// OnInputValueChange={handleInputValueChange}
									tabId={formId}
								/>
							</Col>
							</Row>
							<Row >
								<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
									<button
										autoFocus
										className="btn btn-outline-danger"
										onClick={handleCancel}
										style={{margin:'1em'}}
									>
										Fechar
									</button>
								</Col>
							</Row>
					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default InvestmentsViewPending;
