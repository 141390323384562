import React from "react"
import RegistrationFormSection from "../RegistrationFormSection";

import {captureDocumentsScreens} from "../Configuration/CaptureDocumentsConfig"

function CaptureDocuments(props) {
    return <RegistrationFormSection screens={captureDocumentsScreens} {...props} />
}

export default CaptureDocuments;
