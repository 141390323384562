import api, {sleep} from "../../helpers/gestora/api";


export const addCategory = async ( { state, actions }, {formId, category} ) => {
	state.riskProfiles.categories.push(category)
	await actions.riskProfiles.updateTotalPoints()
}

export const updateTotalPoints = async ( { state, actions }) => {
	const categories = state.riskProfiles.categories
	let total = -1
	for(const category of categories) {
		total +=  parseInt(category.maxScore)-(parseInt(category.minScore)-1)
	}
	state.riskProfiles.totalPoints = total<9 ? 0 : total
	//state.riskProfiles.pointsOK = total===4500


	let count=0, categoryA, categoryB, index, categoriesRangesPointOK=true
	const categoriesRangesOK = []

	for (let i=0; i<categories.length; i++) {
		categoryA = categories[i]
		let ok = true
		for (let j=0; j<categories.length; j++) {
			if (j===i) continue
			categoryB = categories[j]
			if (
				(
					(parseInt(categoryA.minScore)>=parseInt(categoryB.minScore) )
					&&
					(parseInt(categoryA.minScore)<=parseInt(categoryB.maxScore) )
				)
				||
				(
					(parseInt(categoryA.maxScore)>=parseInt(categoryB.minScore) )
					&&
					(parseInt(categoryA.maxScore)<=parseInt(categoryB.maxScore) )
				)
				// ||
				// 	(parseInt(categoryA.maxScore)>4500 )
				||
					(parseInt(categoryA.minScore)>0 && i===0 )
			) {
				ok=false
				categoriesRangesPointOK = false
				break
			}
		}
		categoriesRangesOK.push(ok)
	}
	// console.log('categoriesRangesOK', categoriesRangesOK)
	state.riskProfiles.categoriesRangesOK = categoriesRangesOK
	state.riskProfiles.categoriesRangesPointOK = categoriesRangesPointOK
}

export const clearCategories = async ( { state, actions } ) => {
	state.riskProfiles.categories = []
	await actions.riskProfiles.updateTotalPoints()
}

export const setCategories = async ( { state, actions  }, categories ) => {
	const parsed = []
	for (const category of categories) {
		parsed.push({
			...category,
			minScore : category.minScore.toString()
		})
	}
	state.riskProfiles.categories = parsed
	await actions.riskProfiles.updateTotalPoints()
}

export const deleteByIndex = async ( { state, actions }, index ) => {

	const previous = index>0 ? state.riskProfiles.categories[index-1] : null
	const next = index<state.riskProfiles.categories.length-1 ? state.riskProfiles.categories[index+1] : null


	if (next && previous) {
		next.minScore = (parseInt(previous.maxScore)+1).toString()
	} else if (!previous && next) {
		next.minScore = '0'
	}

	state.riskProfiles.categories.splice(index, 1);
	await actions.riskProfiles.updateTotalPoints()
}

export const replaceByIndex = async ( { state, actions }, {index, item} ) => {

	if (index===0) {
		item.minScore = '0'
	}

	if (index===state.riskProfiles.categories.length-1) {
		//item.maxScore = '4500'
	} else {
		const next = state.riskProfiles.categories[index+1]
		next.minScore = (parseInt(item.maxScore)+1).toString()
	}

	if (index !== -1) {
		state.riskProfiles.categories[index] = item;
	}

	await actions.riskProfiles.updateTotalPoints()
}

export const loadById = async ( { state, actions }, {formId, id} ) => {

	return api
		.get(`riskProfiles/${id}`)
		.then(async res => {
			await actions.riskProfiles.setCategories(res.data.categories)
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, {formId, id, data} ) => {

	const request = parseInt(id)
		? api.put(`riskProfiles/${id}`, data)
		: api.post(`riskProfiles/`, data)

	return await request
		.then(async res => {
			return res.data
		})
}

export const deleteById = async ( { state, actions }, id ) => {

	return api.delete(`riskProfiles/${id}`)
		.then(async res => {
			return res.data
		})
}

export const markActive = async ( { state, actions }, id ) => {

	return api.get(`riskProfiles/activate/${id}`)
		.then(async res => {
			return res.data
		})
}

