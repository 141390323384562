import React, {createRef, useEffect, useState} from "react"

import { AvForm, AvField } from "availity-reactstrap-validation"

import { scroller } from "react-scroll";
import { useOvermind } from '../../../overmind'

const _ = require('lodash')

function CustomForm( {
        children,
        formId,
		validateOnLoad = false,
        onPreviousLast = () => {},
        onNextLast = () => {},
        onSubmit = () => {},
		autoFocusField='',
        onCustomSubmit = () => console.log(`Custom submit not set`),
		...props
}) {

    let formRef

    // const  = props

    //const [formLoaded, setFormLoaded] = useState(false)
    const [firstSubmit, setFirstSubmit] = useState(true)

    const {
        state   : {
            [formId]:{model, currentScreen, currentGroup, totalScreens, submitAction, readOnly}
        },
        actions : {
            setForm, getModelValueByPath, setCurrentScreen, updateFormModel
        }
    } = useOvermind('forms')

    const setScreen = (screenIndex) => {
        setCurrentScreen({formId, value:screenIndex})
    }

    useEffect(() => {
        const FormCtrl = formRef?.getChildContext()?.FormCtrl
		const formInitiator = {formId, FormCtrl, FormRef:formRef}

        setForm(formInitiator)
    }, [])

    useEffect(() => {
    	if (autoFocusField) {
    		//focus after field has been enabled (autofill prevented using disabled=true for 500ms)
    		const interval =  100
			setTimeout(() => {
				try {
					//document.body.click()
					document.querySelector(`[name="${autoFocusField}"]`).focus()
				} catch(e) {
					//alert(e)
				}
			}, interval)
		}
    	if (validateOnLoad ) {
			//validateForm({formId})
		}
    }, [currentScreen, currentGroup])

    const getAction = () => {
        let action = document
            ?.getElementById(`_submit-btn-${formId}`)
            ?.getAttribute('submitaction')

        if (!action) {
            console.log(`Unable to find button action for ${formId}, using 'next'`)
            action = 'next'
        }
        return action
    }

    const getCustomMethod = () => {
        return document.getElementById(`_submit-btn-${formId}`)?.submitmethod
    }

    const handleValidSubmit = (event, values) => {
    	//alert('valid')

        //console.log(formRef.context.FormCtrl)return

        //const action = event.nativeEvent.submitter.getAttribute('submitaction')
        const action = getAction()

            //const cloneModel = JSON.parse(JSON.stringify(model));
            //const cloneModel = _.cloneDeep(model);


        /*
        const currentValues = JSON.parse(JSON.stringify(model))
        const newValues = JSON.parse(JSON.stringify(values))
        const newModel = {
            ...currentValues,
            ...newValues
        }
        console.log('model update', {currentValues, newValues, newModel})
        setFormModel({formId, model:newModel})
         */

        //updateFormModel({formId, newValues:values})

        //alert(totalScreens)

        if (action=='submit') {
			onSubmit(event, values)
		} else if (action=='next') {
            if (!totalScreens || currentScreen==totalScreens-1) {
                onNextLast(event, values)
            } else {
                setScreen(currentScreen+1)
            }
        } else if (action=='previous') {
            if (currentScreen==0) {
                onPreviousLast(event, values)
            } else {
                setScreen(currentScreen-1)
            }

        } else {
        	const method = getCustomMethod()
			method(event, values, action)
        	//onCustomSubmit(event, values, action)
        }

    }

    const handleInvalidSubmit = async (event, errors, values) => {

    	// if (firstSubmit) {
		// 	for (let error in errors) {
		// 		let name = errors[error]
		// 		const value = getModelValueByPath({formId, path:name})
		// 	}
		// 	setFirstSubmit(true)
		// 	setTimeout(() => {
		//
		// 	}, 400)
		// 	return
		// }

		//alert('invalid')
        //const action = event.nativeEvent.submitter.getAttribute('submitaction')
        const action = getAction()
        if (action=='previous' || action=='submit') {
            return handleValidSubmit(event, values)
        }

        console.log('errors', errors)

        //document.querySelector('.invalid-feedback-upload').classList.add('.is-touched')


        //console.log('errors', errors)
        //const input = FormRef.current.getInput(errors[0])
        //const inputs = FormRef.getInputs()
        //console.log(inputs.field1) //.context.FormCtrl.getInput('field1')
        //let i = ReactDOM.findDOMNode(inputs.field1)
        // let divs = React.Children
        //     .toArray(children)
        //     .filter((item) => item.props.className.indexOf('form-field-control') > -1);

        //console.log('errors', errors)
        //console.log('FormRef.getValues()', formRef.getValues())

        try {
            let firstErrorFieldName, input
            /*

            //BUGGED, order of 'errors' and 'values' is changed by client JS
            //object hash don't obey order, using querySelector instead

            const values = formRef.getValues()
            const inputs = formRef.getInputs()
            console.log('inputs', inputs)
            for (let k in values) {
                console.log(k)
                if (errors.indexOf(k)>-1) {
                    firstErrorFieldName = k
                    break
                }
            }
            console.log('firstErrorFieldName', firstErrorFieldName)*/

            const duration = 500
            //try { input = document.querySelector(`[name=${firstErrorFieldName}]`) } catch(e) { }
            try {
                try {
                    input = document.querySelector(`.form-input-upload-invalid:first-child,INPUT.av-invalid:first-child,SELECT.av-invalid:first-child,TEXTAREA.av-invalid:first-child`)
                } catch(e) {}

                if (!input) {
                    input = document.querySelector(`.form-input-upload-invalid,INPUT.av-invalid,SELECT.av-invalid,TEXTAREA.av-invalid`) //fallback for older browsers (':first-child')
                }
                firstErrorFieldName = input.name
                //console.log({firstErrorFieldName, input})
            } catch(e) {
                console.log('Error finding input: ', {input})
            }
            //console.log('input2', input)
            if (firstErrorFieldName && input) {
                //console.log(`form-control-${firstErrorFieldName}`)
				const selector = `form-control-${firstErrorFieldName}`
				//alert(`form-control-${firstErrorFieldName}`)
				try {
					scroller.scrollTo(selector, {
						containerId : 'main-content',
						smooth: true,
						offset: -14,
						duration,
					})
				} catch(e) {
					scroller.scrollTo(selector, {
						smooth: true,
						offset: -14,
						duration,
					})
				}
                if (input) {
                    setTimeout(() => {
                        try {input.focus()} catch(e) {}
                    }, duration-(1000/16) )
                }
            }
        } catch(e) {
            console.log('onInvalidSubmit: not able to scroll', e)
        }

    }

    // const handleSubmit = () => {
    //     const formData = new FormData(document.querySelector('form'))
    //     for (var pair of formData.entries()) {
    //       // console.log(pair[0] + ': ' + pair[1]);
    //     }
    // }

    //Don't send the state model to the AvForm
    //const cloneModel = JSON.parse(JSON.stringify(model));
    const cloneModel = _.cloneDeep(model)
    //cloneDeep is faster, slightly more memory used
	// const cloneModel = _.cloneDeepWith(model, (value) => {
	// 	//value = 'a'
    // 	return typeof value=='string' ? value+'a' : undefined;
	// })


    return (
        <AvForm
			style={{width:'100%'}}
            ref={c => (formRef = c)}
            onValidSubmit={handleValidSubmit}
            onInvalidSubmit={handleInvalidSubmit}
            model={cloneModel}
            id={props.formId}
            // onSubmit={handleSubmit}
        >
            {children}
            <input type='submit' id={`_submit-btn-${formId}`} style={{display:'none'}}/>
        </AvForm>
    )
}

export default CustomForm;
