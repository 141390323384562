import React, {createRef, useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";
import {
	registrationFormPendingReasonOptions
} from "../../../../constants/formLists";

import {dateIsoToBr} from "../../../../renders/utils";
import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";

import api, {sleep} from "../../../../helpers/gestora/api";
import Button from "../../../../components/Common/Button/Button"


function RenewAttachments(props) {

    const {
    	formId,
		viewId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

    const {
        state   : {
            [formId]:{model}
        },
        actions : {
			setModelValueByPath
        }
    } = useOvermind('forms')

	const renew = useOvermind('renew')

	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)


	const screens = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'renewAttachment.approvedDate',
					caption:'Data de aprovação',
					type:'text',
					disabled:true,
					row:true,
					col:12
				},
				{
					id:'renewAttachment.attachment',
					caption:'Anexo de renovação',
					//subtitle : 'Envie a procuração caso possua um procurador ou o documento de representação caso possua um representante.',
					type:'upload',
					accept:'.pdf',
					file_caption:'Anexo de renovação',
					required:true,
					globalReadOnly:false,
					row:true,
					col:12
				},
			],
		},
	], [viewId]);

    useEffect(() => {
    	if (!viewId)
    		return

    	setError(false)
    	setSaving(false)
    	setSaved(false)
		setLoading(true)

		renew.actions.loadById({formId, id:viewId})
			//.then(sleep(2000))
			.then(data => {
				console.log('renew/get', data)

				setModelValueByPath({formId, path:'renewAttachment.approvedDate', value:dateIsoToBr(data.approved_date)})

				// setModelValueByPath({formId, path:'view.createDate', value:dateIsoToBr(data.createDate)})
				// setModelValueByPath({formId, path:'view.reason', value:data.reason
			})
			.catch(e => {
				setError('Não foi possível carregar as informações de status')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [viewId])

	const handleConfirm = async (e) => {
		// const values = _.cloneDeep(model)

		//console.log(values)

		//const createDate = `${dateBrToISO(values.changeStatus.date.trim())} ${values.changeStatus.time.trim()}`
		//alert(createDate)

		const data = {}

    	setSaving(true)
    	renew.actions.save({
				formId,
				id:viewId,
				data,
			})
			// .then(sleep(1000))
			.then(() => {
				setSaved(true)
				onConfirm()
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch((e) => {
				setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
				setSaving(false)
				setSaved(false)
			})
	}

	const handleCancel = () => {
    	onCancel()
	}

	if (!viewId)
		return null

    return (
        <>
			<SweetAlert
				title={"Anexo de renovação"}
				style={{
					overflow:'auto',
					maxHeight:'100%'
				}}

				success={null}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						<Row>
							<Col md={12} className="pt-4">
								<FormWizard
									screenId={formId}
									screens={screens}
									formId={formId}
									selectedScreen={0}
									// OnInputValueChange={handleInputValueChange}
									tabId={formId}
								/>
							</Col>
							</Row>
							<Row >
								<Col md={12} className="d-flex align-items-center justify-content-center">
									<Button
										autoFocus
										className="btn btn-outline-danger"
										onClick={handleCancel}
										style={{margin:'1em'}}
									>
										Fechar
									</Button>
									<Button
										loading={saving}
										colorIcon={true}
										className="btn btn-primary"
										// disabled={currentScreen==0}
										style={{margin:'1em'}}
										onClick={handleConfirm}
										>
										Salvar
									</Button>

								</Col>
							</Row>
					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default RenewAttachments;
