import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import {AuthInfo, UserCan} from "../../helpers/gestora/auth";
import UserCanAny from "../Common/UserCan/UserCanAny";
import UserCanAll from "../Common/UserCan/UserCanAll";

//i18n
//import { withTranslation } from "react-i18next"

const SidebarContent = props => {
	const authInfo = AuthInfo()

	const ref = useRef();
	// Use ComponentDidMount and ComponentDidUpdate method symultaniously
	useEffect(() => {
		const pathName = props.location.pathname

		const initMenu = () => {
			new MetisMenu("#side-menu")
			let matchingMenuItem = null
			const ul = document.getElementById("side-menu")
			const items = ul.getElementsByTagName("a")
			for (let i = 0; i < items.length; ++i) {
				if (pathName === items[i].pathname) {
					matchingMenuItem = items[i]
					break
				}
			}
			if (matchingMenuItem) {
				activateParentDropdown(matchingMenuItem)
			}
		}
		initMenu()
	}, [props.location.pathname])

	useEffect(() => {
		ref.current.recalculate()
	})

	function scrollElement(item) {
		if (item) {
			const currentPosition = item.offsetTop
			if (currentPosition > window.innerHeight) {
				ref.current.getScrollElement().scrollTop = currentPosition - 300
			}
		}
	}

	function activateParentDropdown(item) {
		item.classList.add("active")
		const parent = item.parentElement
		const parent2El = parent.childNodes[1]
		if (parent2El && parent2El.id !== "side-menu") {
			parent2El.classList.add("mm-show")
		}

		if (parent) {
			parent.classList.add("mm-active")
			const parent2 = parent.parentElement

			if (parent2) {
				parent2.classList.add("mm-show") // ul tag

				const parent3 = parent2.parentElement // li tag

				if (parent3) {
					parent3.classList.add("mm-active") // li
					parent3.childNodes[0].classList.add("mm-active") //a
					const parent4 = parent3.parentElement // ul
					if (parent4) {
						parent4.classList.add("mm-show") // ul
						const parent5 = parent4.parentElement
						if (parent5) {
							parent5.classList.add("mm-show") // li
							parent5.childNodes[0].classList.add("mm-active") // a tag
						}
					}
				}
			}
			scrollElement(item);
			return false
		}
		scrollElement(item);
		return false
	}

	return (
		<React.Fragment>
			<SimpleBar className="h-100" ref={ref}>
				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled debug4" id="side-menu">

						<UserCanAll permission={['clients.consult']}>
						<li>
							<Link to={`/dashboard`} className=" ">
								<i className="fas fa-home"></i>
								<span>Dashboard</span>
							</Link>
						</li>
						</UserCanAll>

						{/*<li className="menu-title">Clientes</li>*/}

						{/*<li>*/}
						{/*	<Link to="/clients/list" className=" ">*/}
						{/*		<i className="bx bx-calendar"></i>*/}
						{/*		<span>Fichas cadastrais</span>*/}
						{/*	</Link>*/}
						{/*</li>*/}

						<UserCanAll permission={['clients.consult']}>
						<li>
							<Link to={`/clients/search#${Math.random()}`} className=" ">
								<i className="bx bx-calendar"></i>
								<span>Fichas cadastrais</span>
							</Link>
						</li>
						</UserCanAll>

						<UserCanAll permission={['investments.consult']}>
						<li>
							<Link to={`/investments/search#${Math.random()}`} className=" ">
								<i className="bx bx-dollar-circle"></i>
								<span>Movimentações</span>
							</Link>
						</li>
						</UserCanAll>

						<UserCanAny permission={['firstrelations.consult', 'clientLog.consult', 'icatuCertificates.consult']}>
						{/*<li className="menu-title">Início</li>*/}
						<li ><a className="has-arrow waves-effect" href="/" aria-expanded="true">
							<i className="fas fa-search debug" /><span>Relatórios</span></a>
							<ul className="sub-menu mm-collapse" aria-expanded="false" >


								<UserCanAny permission={['icatuCertificates.consult']}>
								<li>
									<Link to={`/icatuCertificates/search`} className=" ">
										<i className="fas fa-exclamation-triangle small font-size-14 "/>
										<span className="font-size-em-1">APIs Icatu</span>
									</Link>
								</li>
								</UserCanAny>

								<UserCanAny permission={['clientLog.consult']}>
								<li>
									<Link to={`/clientLog/dashboard`} className=" ">
										<i className="fas fa-atlas small font-size-14 "/>
										<span className="font-size-em-1">Registro de Acessos</span>
									</Link>
								</li>
								</UserCanAny>

								<UserCanAll permission={['firstrelations.consult']}>
									<li>
										<Link to={`/investmentsfirstrelation/search#${Math.random()}`} className=" ">
											<i className="far fa-handshake small font-size-14" ></i>
											<span className="font-size-em-1">Recompensa por. Rel.</span>
										</Link>
									</li>
								</UserCanAll>


							</ul>
						</li>
						</UserCanAny>


						<UserCanAny permission={['users.consult', 'profiles.consult']}>
						{/*<li className="menu-title">Outras opções</li>*/}
						<li ><a className="has-arrow waves-effect" href="/" aria-expanded="true">
							<i className="fas fa-users font-size-14" /><span>Usuários</span></a>
							<ul className="sub-menu mm-collapse" aria-expanded="false" >

								<UserCanAll permission={['users.consult']}>
								<li>
									<Link to={`/supportTables/users/search#${Math.random()}`} className=" ">
										<i className="fas fa-user font-size-14 small "/>
										<span className="font-size-em-1">Usuários</span>
									</Link>
								</li>
								</UserCanAll>

								<UserCanAll permission={['profiles.consult']}>
								<li>
									<Link to={`/supportTables/profiles/search#${Math.random()}`} className=" ">
										<i className="fas fa-code-branch font-size-14 small "/>
										<span className="font-size-em-1">Perfis</span>
									</Link>
								</li>
								</UserCanAll>
							</ul>
						</li>
						</UserCanAny>

						<UserCanAny permission={[
							'funds.consult', 'banks.consult',
							'riskProfiles.consult', 'suitabilities.consult',
						]}>
						{/*<li className="menu-title">Opções</li>*/}
						<li ><a className="has-arrow waves-effect" href="/" aria-expanded="true">
							<i className="fas fa-cog font-size-16" /><span>Configurações</span></a>
							<ul className="sub-menu mm-collapse" aria-expanded="false" >

								<UserCanAll permission={['funds.consult']}>
								<li>
									<Link to={`/supportTables/fundTypes/search#${Math.random()}`} className=" ">
										<i className="fas fa-chart-line font-size-14 small "/>
										<span className="font-size-em-1">Fundos</span>
									</Link>
								</li>
								</UserCanAll>

								<UserCanAll permission={['banks.consult']}>
								<li>
									<Link to={`/supportTables/banks/search#${Math.random()}`} className=" ">
										<i className="mdi mdi-bank font-size-14 small "/>
										<span className="font-size-em-1">Bancos</span>
									</Link>
								</li>
								</UserCanAll>

								<UserCanAll permission={['riskProfiles.consult']}>
								<li>
									<Link to={`/supportTables/riskProfile/search#${Math.random()}`} className=" ">
										<i className="fas fa-hand-holding-usd font-size-14 small "/>
										<span className="font-size-em-1">Perfis de Investimento</span>
									</Link>
								</li>
								</UserCanAll>

								<UserCanAll permission={['suitabilities.consult']}>
								<li>
									<Link to={`/supportTables/suitability/search#${Math.random()}`} className=" ">
										<i className="fas fa-clipboard-list font-size-14 small "/>
										<span className="font-size-em-1">Suitability</span>
									</Link>
								</li>
								</UserCanAll>

								<UserCanAll permission={['mailsig.consult']}>
								<li>
									<Link to={`/supportTables/mailsig/search#${Math.random()}`} className=" ">
										<i className="fas fa-signature font-size-14 small "/>
										<span className="font-size-em-1">Assinaturas de e-mail</span>
									</Link>
								</li>
								</UserCanAll>

							</ul>
						</li>
						</UserCanAny>

						{/*<li className="menu-title">Tabelas de apoio</li>*/}
						{/*<li>*/}
						{/*	<Link to={`/supportTables/fundTypes/search#${Math.random()}`} className=" ">*/}
						{/*		<i className="mdi mdi-bank"></i>*/}
						{/*		<span>Fundos</span>*/}
						{/*	</Link>*/}
						{/*</li>*/}



					</ul>
				</div>
			</SimpleBar>
		</React.Fragment>
	)
}

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
}

export default withRouter(SidebarContent)
