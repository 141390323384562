import React, {useState, useEffect} from "react"

import FieldText from "./../Inputs/Text";
import FieldCpf from "./../Inputs/Cpf";
import FieldCnpj from "./../Inputs/Cnpj";
import FieldCpfCnpj from "./../Inputs/CpfCnpj";
import FieldPhone from "./../Inputs/Phone";
import FieldEmail from "./../Inputs/Email";
import FieldDate from "./../Inputs/Date";
import FieldCep from "./../Inputs/Cep";
import FieldNumber from "./../Inputs/Number";
import FieldMoney from "./../Inputs/Money";
import FieldCheckGroup from "./../Inputs/CheckGroup";
import FieldRadioGroup from "./../Inputs/RadioGroup";
import FieldSelect from "./../Inputs/Select";
import FormTitleDefault from "./../SubTitles/TitleDefault";
import FieldUpload from "./../Inputs/Upload";
import FieldPassword from "./../Inputs/Password";

import * as Validations from "./../Utils/Validations";
import FieldTextArea from "../Inputs/TextArea";
import FieldTime from "../Inputs/Time";
import FieldSelect2 from "../Inputs/Select2";
import FieldSelect2Multi from "../Inputs/Select2Multi";
import FieldDateRange from "../Inputs/DateRange";
import FormTitleCollapsible from "../SubTitles/CollapsibleTitle";
import FieldPercent from "../Inputs/Percent";

//Registered field types
export const registeredFieldTypes = {
    title           : {component:FormTitleDefault, floatingLabels:false, noLabels:true},
    collapsibletitle: {component:FormTitleCollapsible, floatingLabels:false, noLabels:true},
    text            : {component:FieldText, defaultValidator:Validations.isValidValue, floatingLabels:true},
    textarea        : {component:FieldTextArea, defaultValidator:Validations.isValidValue, floatingLabels:true},
    password        : {component:FieldPassword, defaultValidator:Validations.validatePasswordStrong, floatingLabels:true},
    cpf             : {component:FieldCpf, defaultValidator:Validations.isValidCpf, floatingLabels:true},
    cnpj            : {component:FieldCnpj, defaultValidator:Validations.isValidCnpj, floatingLabels:true},
    cpfcnpj         : {component:FieldCpfCnpj, defaultValidator:Validations.isValidCpfCnpj, floatingLabels:true},
    phone           : {component:FieldPhone, defaultValidator:Validations.isValidPhoneNumber, floatingLabels:true},
    email           : {component:FieldEmail, defaultValidator:Validations.isValidEmail, floatingLabels:true},
    date            : {component:FieldDate, defaultValidator:Validations.isValidDate, floatingLabels:false},
    daterange       : {component:FieldDateRange, defaultValidator:Validations.isValidDateRange, floatingLabels:false},
    time            : {component:FieldTime, defaultValidator:Validations.isValidTime, floatingLabels:false},
    cep             : {component:FieldCep, defaultValidator:Validations.isValidCep, floatingLabels:true},
    number          : {component:FieldNumber, defaultValidator:Validations.isValidNumber, floatingLabels:true},
    money           : {component:FieldMoney, defaultValidator:Validations.isValidMoney, floatingLabels:true},
    percent         : {component:FieldPercent, defaultValidator:Validations.isValidPercent, floatingLabels:true},
    checkgroup      : {component:FieldCheckGroup, defaultValidator:Validations.isValidOptions, floatingLabels:false},
    radiogroup      : {component:FieldRadioGroup, defaultValidator:Validations.isValidValue, floatingLabels:false},
    select          : {component:FieldSelect, defaultValidator:Validations.isValidValue, floatingLabels:false},

    select2         : {component:FieldSelect2, defaultValidator:Validations.isValidValue, floatingLabels:false},
    select2multi    : {component:FieldSelect2Multi, defaultValidator:Validations.isValidOptions, floatingLabels:true},

    upload          : {component:FieldUpload, defaultValidator:Validations.isValidUpload, floatingLabels:false},
}
