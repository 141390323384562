import React, {useState, useEffect, useMemo} from 'react';
import DataTable from 'react-data-table-component';
import MetaTags from 'react-meta-tags';

import api, { sleep } from "../../../helpers/gestora/api"
import {Card, CardBody, Col, Container, Row, Spinner, Button} from "reactstrap";

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../overmind";


const ProgressComponent = () => {
	return  (
		<Spinner
			style={{ width: "1em", height: "1em"  }}
			color="primary"
		/>
	)
}

const NoDataComponent = <Row>
	<Col md={12}  className="pt-4">Nenhum registro encontrado</Col>
</Row>

const customStyles = {
	rows: {
		style: {
			minHeight: '3em', // override the row height
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#eff2f7',
				backgroundColor:'var(--bs-table-bg)',
				boxShadow:'inset 0 0 0 9999px var(--bs-table-accent-bg)',
			},
		},
	},

	headRow: {
		style: {
			borderBottomColor: '#eff2f7',
		}
	},
	headCells: {
		style: {
			fontWeight:'bold',
			paddingLeft: '8px', // override the cell padding for head cells
			paddingRight: '8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const paginationServerOptions = {
	persistSelectedOnSort : false,
}

const paginationComponentOptions = {
	rowsPerPageText: 'Resultados por página:',
	rangeSeparatorText: 'de',
	noRowsPerPage: false,
	selectAllRowsItem: false,
	selectAllRowsItemText: 'Todos'
}


export const ListRegistrationForm = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [orderBy, setOrderBy] = useState('id');
	const [orderDirection, setOrderDirection] = useState('desc');

	const location = useLocation()
	const history = useHistory()

	const app = useOvermind('app')
	const formId = 'SearchBar'

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			submitForm, setModelValueByPath,
			setFormState, setFieldState, setFormModel, resetForm,
			updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	const fetchUsers = () => {

		setLoading(true);
		//console.log('orderDirection', orderDirection)

		return api
		//.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`)
			.get(`/registrationForm`,
				{
					params:{
						page : page,
						size : perPage,
						searchParams : app.state.searchParams,
						fieldSort : orderBy,
						sortDirection : orderDirection,
					}
				})
			//.then(sleep(2000))
			.then(response => {
				setData(response.data.registrationForms);
				setTotalRows(response.data.page.totalElements);
				setLoading(false);
			})
			.catch(e => {
				alert(e.toString())
			})
			.finally(() => {
				setLoading(false);
			});

	};

	useEffect(() => {
		//console.log(location)
		//if (!location.state?.fromSearch) app.actions.setSearchString('')

		fetchUsers()

	}, [
		page, perPage, orderBy, orderDirection,
		app.state.searchParams.searchString,
		app.state.searchParams.status,
		app.state.searchParams.renewStatus,
	]);


	const handleClick = row => {
		history.push({
			pathname:`/clients/registrationform/${row.id}#${Math.random()}`,
			//state : {clientId:row.id}
		})
		//console.log(row)
	};

	const handlePageChange = page => {
		setPage(page)
	};

	const handlePerRowsChange = (newPerPage, page) => {
		console.log('handlePerRowsChange', {newPerPage, page})
		setPage(page);
		setPerPage(newPerPage);
	};

	const handleSortChange = (row, orderDir) => {
		console.log('handleSortChange', {id:row.id, orderDir, from:orderDirection})
		setOrderDirection(orderDir)
		setOrderBy(row.id)
	};

	const handleClearSearch = (row, orderDir) => {
		//document.getElementById('app-search-string').value = ''
		setFormModel({
			formId,
			model: {
				searchParams : {
					searchString : '',
					status : '',
					renewStatus : '',
				},
			}
		})

		incFormValidatedCount({formId})
	};

	const handleNewRegistrationForm = async (row, orderDir) => {
		await incFormResetCount({formId})
		history.push({
			pathname:`/clients/registrationform`,
			//state : {clientId:row.id}
		})
	};

	const columns = useMemo(() => [
		{
			name: 'Editar',
			// eslint-disable-next-line react/display-name
			cell:(row) =>
				<button
					type="button"
					className="btn btn-light m-1 p-2 mt-2 font-weight-normal font-size-12"
					onClick={() => handleClick(row)}
				>
					<i className="fa fa-edit font-size-12 align-middle me-0"></i>
				</button>
			,

			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
		{
			id : 'id',
			name: 'ID',
			width:10,
			maxWidth:30,
			grow:0,
			selector: row => row?.id,
			sortable: true,
		},
		{
			id : 'holderName',
			name: 'Titular',
			grow:1,
			selector: row => row?.clientHolder?.name,
			sortable: true,
		},
		{
			id : 'holderDocument',
			name: 'CPF/CNPJ',
			grow:1,
			selector: row => row?.clientHolder?.document,
			sortable: true,
		},
		{
			id : 'holderAssociate',
			name: 'Planejador',
			grow:1,
			selector: row => row?.clientHolder?.associate?.name,
			sortable: true,
		},
		{
			id : 'coholderName',
			name: 'Cotitular',
			grow:1,
			selector: row => row?.clientCoHolder?.name,
			sortable: false,
		},
	], []);

	//console.log(orderDirection)
	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>Fiduc Gestão - Admin</title>
				</MetaTags>
				<Container fluid>
					<Row className={"mb-2"}>
						<Col md={9}>
							<h4 >
								Fichas Cadastrais
								{" "}
								{loading && <ProgressComponent />}
							</h4>
						</Col>
						<Col md={3} style={{textAlign:"right"}}>
							{/*{app.state.searchParams.searchString &&*/}
							{/*	<button className="btn btn-danger btn-sm p-2 m-1"*/}
							{/*		onClick={handleClearSearch}*/}
							{/*	>*/}
							{/*		<i className="fas fa-window-close" />*/}
							{/*		{" "}*/}
							{/*		Limpar filtros*/}
							{/*	</button>*/}
							{/*}*/}
								<button className="btn btn-primary btn-sm p-2 m-1"
									onClick={handleNewRegistrationForm}
								>
									<i className="fas fa-plus" />
									{" "}
									Nova ficha
								</button>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Card>
								<CardBody className={'pt-0'}>
									<DataTable
										noHeader={true}
										columns={columns}
										customStyles={customStyles}
										data={data}
										pagination
										paginationServer
										paginationTotalRows={totalRows}
										sortServer
										onChangeRowsPerPage={handlePerRowsChange}

										defaultSortAsc={true}
										defaultSortField={'id'}
										onChangePage={handlePageChange}
										onSort={handleSortChange}
										paginationServerOptions={paginationServerOptions}
										paginationComponentOptions={paginationComponentOptions}
										noDataComponent={NoDataComponent}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default {
	title: 'Pagination/Remote',
	component: ListRegistrationForm,
};
