import React from "react"
import {Spinner} from "reactstrap";

function Button(
	{
		children,
		title='',
		color='primary',
		buttonStyle,
		...props
	}
	) {

    return (
        <button
            className={`${props.className} btn btn-${color} btn-block} `}

            type={props.type || "button"}
            disabled={props.loading || props.disabled}
			title={title}
            style={{
            	...{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				...props.colorIcon ? {
					//color : props.loading || props.disabled ? 'white' : undefined,
					//opacity : props.loading || props.disabled ? 0.5 : undefined,
					//borderColor : props.loading || props.disabled ? 'gray' : undefined,
            	} : {},
				...buttonStyle
			}}
			{...props}
        >
          {props.loading ? (
            <Spinner
              style={{ width: "0.5rem", height: "0.5rem", marginRight:"0.5em", marginTop: '.2em', marginBottom:'.2em' }}
              type="grow"
              color={"light"}
            />
          ):null}
            {children}
        </button>

    )
}

export default Button;
