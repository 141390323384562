import React, {useState, useEffect, useMemo} from 'react';

import {Col, Container, Row} from "reactstrap";
import { useLocation, useHistory } from "react-router-dom"
import moment from "moment"
moment.locale('pt-BR')

import { useOvermind } from "../../../overmind";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import TabDefault from "../../../components/Common/Tabs/TabDefault";
import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";
import DefaultBox from "../../../components/Common/Box/DefaultBox/DefaultBox";
import Button from "../../../components/Common/Button/Button";
import GraphPie from "../../../components/Common/Graph/Pie";
import GraphDoubleTimeline from "../../../components/Common/Graph/DoubleTimeline";
import CustomForm from "../../../components/Common/CustomForm/Form";
import Field from "../../../components/Common/CustomForm/Field";
import {datePeriodMappedMonthly, datePeriodOptions, datePeriodOptionsMonthly} from "../../../constants/formLists";
import {defaultGraphColors} from "../../../helpers/gestora/configuration";
import GraphTimeline from "../../../components/Common/Graph/Timeline";
import TabButtons from "../../../components/Common/Tabs/TabButtons";
import {useLocalStorage} from "../../../helpers/gestora/UseLocalStorage";

const mainTabs = [
	{id:'registration', caption:'Cadastros'},
	{id:'investment', caption:"Investimentos"},
	{id:'login', caption:"Logins"},
]

export const PieBox = ({data, id, type, description='', refreshGraph}) => {

	const total = useMemo(() =>
		data?.sections
			.reduce((total, obj) => obj.total + total, 0)
	, [data])

	return <>
		<Row className="px-0 mx-0 d-flex flex-row flex-grow-0 bg-fiduc-subtable rounded-3 mb-2 border border-dark">
		<Col sm={12} md={4} lg={3} className="debug1 d-flex flex-column p-2 flex-grow-0"  >
			<div className="debug2 text-align-center font-weight-700">{description}</div>
			<div className="debug2" style={{minHeight:'200px'}}>
				<GraphPie
					seriesData={data?.sections && data?.sections?.map((value) => value.total ?? 0)}
					labelsData={data?.sections && data?.sections?.map((value) => value.name ?? 0)}
				/>
			</div>
			<div className="debug2 d-flex flex-column flex-grow-1">
				{data?.sections && data?.sections?.map((item, index) => {
					return <div key={index} className="d-flex flex-row  debug2 px-2">
						<div className="d-flex flex-grow-0 debug3 align-items-center px-1"><div className="icon-circle" style={{backgroundColor:defaultGraphColors[index]}}/></div>
						<div className="d-flex flex-grow-1 debug3">{item.name}</div>
						<div className="d-flex flex-grow-0 debug3 text-align-right justify-content-end ">{item.total}</div>
						<div className="d-flex flex-grow-0 debug3 text-align-right justify-content-end" style={{minWidth:'4rem'}}>
							{total>0 ? (item.total/total*100).toFixed(2) : 0}%
						</div>
					</div>
				})}

			</div>
		</Col>
		<Col sm={12} md={8} lg={9} className="flex-grow-1 p-2">
			{refreshGraph
				? null
				: <TimelineBox
					series={data?.timeline?.series}
					labels={data?.timeline?.labels}
					description="Timeline"
					type={type}
				/>
			}

		</Col>
	</Row>
	</>
}

export const TimelineBox = ({series, labels, type, description=''}) => {

	return <>
		<div className="debug1 d-flex flex-column">
			<div className="debug2 text-align-center font-weight-700 " >{description || `\u00A0`}</div>
			<div className="debug2" style={{minHeight:'200px'}}>
				<GraphTimeline
					series={series}
					labels={labels}
					type={type}
				/>
			</div>
		</div>
	</>
}

const defaultDate = [
	moment().startOf('year').format('YYYY-MM-DD'),
	//moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

export const ClientLogDashboard = () => {

	const location = useLocation()
	const history = useHistory()

	const formId = 'ClientLogDashboard'

	const [clientLogType, setClientLogType] = useState('registration')
	const [loading, setLoading] = useState(true)
	const [refreshGraph, setRefreshGraph] = useState(false)
	const [data, setData] = useState(null)
	const [selectedPeriod, setSelectedPeriod] = useState(defaultDate)
	const [graphType, setGraphType] = useLocalStorage(`client-log-graph-type`, 'line')
	const [uniqueClients, setUniqueClients] = useLocalStorage(`client-log-graph-unique`, false)

	const clients = useOvermind('clients')
	const menuPortalTarget = document.getElementById('root')

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			// submitForm,
			setModelValueByPath,
			// setFormState, setFieldState, setFormModel, resetForm,
			// updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	const refreshData = () => {
		setLoading(true)
		clients.actions
			.getClientLogDashboard({
				unique:uniqueClients,
				type:clientLogType,
				periodStart : selectedPeriod[0],
				periodEnd : selectedPeriod[1],
			})
			.then(responseData => setData(responseData))
			.catch(e => alert(`Ocorreu um erro ao carregar as informações ${e?.toString()}`))
			.finally(() => setLoading(false))
	}

	useEffect(refreshData, [clientLogType, uniqueClients])

	useEffect(() => {
		// console.log({p:model?.period})
		const selectedPeriodStart = selectedPeriod?.length && selectedPeriod[0]
		const selectedPeriodEbd = selectedPeriod?.length && selectedPeriod[1]
 		const periodStart = model?.period?.length && model?.period[0] ? moment(model?.period[0]).format('YYYY-MM-DD') : null
		const periodEnd = model?.period?.length>1 && model?.period[1] ? moment(model?.period[1]).format('YYYY-MM-DD') : null
		if (
			(periodStart!=selectedPeriodStart || periodEnd != selectedPeriodEbd)) {
			setSelectedPeriod([
				periodStart,
				periodEnd
			])
		}
	}, [model?.period])

	useEffect(() => {
		if (selectedPeriod===defaultDate) return
		if (!selectedPeriod)
			return

		refreshData()
	}, [selectedPeriod])

	return (<UserCanAll permission="clientLog.consult">
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Registro de acessos"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
			<Container fluid className="" >
				<Row className={"mb-0 "}>
					<Col md={12} className=" ">
						<TabDefault
							tabs={mainTabs}
							activeTab={clientLogType}
							onChange={type => setClientLogType(type)}
							TabActions={<>
								{(clientLogType!=='registration') &&
								<div className="d-flex align-items-end pe-2 " style={{marginBottom:'.15rem'}}>
									<div className="d-flex flex-row justify-content-around " role="group">
									<Button
										disabled={false}
										colorIcon={true}
										className={`btn ${uniqueClients ? "btn-primary" : "btn-outline-primary"} m-0 btn-sm m-0 `}
										title={"Somente clientes únicos"}
										style={{borderRadius:'0.5rem', minHeight:'1.9rem'}}
										// disabled={currentScreen==0}
										onClick={() => setUniqueClients(!uniqueClients)}>
										<i
											className={`m-0 fa ${uniqueClients ? "fas fa-user-check" : "fas fa-user"} align-middle font-size-rem0`}
											style={{minWidth:'1.5rem'}}
										/>
									</Button>
									</div>
								</div>
								}
								<div className="d-flex align-items-end pe-4 " style={{marginBottom:'.15rem'}}>
									<TabButtons
										tabs={[
											{id: 'line', iconClass:"dripicons-graph-line", title:"Gráfico de linhas"},
											{id: 'bar', iconClass:"dripicons-graph-bar", title:"Gráfico de Barras"},
										]}
										activeTab={graphType}
										onChange={type => {
											setRefreshGraph(true)
											setGraphType(type)
											setTimeout(() => setRefreshGraph(false))
										}}

									/>
								</div>
								<div className="debug4 d-flex pt-2 pe-4" style={{minWidth:'20rem'}}>
									<Field
										formId={formId}
										id="period"
										type="daterange"
										caption="Período"
										emptyOptionText={<b>Tudo</b>}
										defaultValue={1}
										col={12}
										xs={12}
										modelValue={1}
										menuPortalTarget={menuPortalTarget}

										options={datePeriodOptionsMonthly}
										mappedOptions={datePeriodMappedMonthly}
									/>
								</div>
							</>}
						/>
					</Col>
				</Row>
				<LoadingSection loading={loading}>

					<DefaultBox
						className="pt-0"
						containerStyle={{marginTop:'-1px', paddingTop:'0px'}}
					>
							<PieBox
								data={data?.byOsName}
								id='osName'
								description={`Sistema operacional`}
								type={graphType}
								refreshGraph={refreshGraph}
							/>
							<PieBox
								data={data?.byPagePlatform}
								id='pagePlatform'
								description={`Plataforma`}
								type={graphType}
								refreshGraph={refreshGraph}
							/>
							{/*<div className={"py-2 debug4"}>*/}
							{/*	<div style={{backgroundColor:'red', height:'2px', opacity:.5}}/>*/}
							{/*</div>*/}
							<PieBox
								data={data?.byDeviceType}
								id='deviceType'
								description={`Tipo do Device`}
								type={graphType}
								refreshGraph={refreshGraph}
							/>
							<PieBox
								data={data?.byDeviceName}
								id='deviceName'
								description={`Nome do Device`}
								type={graphType}
								refreshGraph={refreshGraph}
							/>
					</DefaultBox>
					{/*<div className="py-1" />*/}
					{/*<DefaultBox*/}
					{/*	title={"Timeline"}*/}
					{/*>*/}
					{/*	<TimelineBox*/}
					{/*		data={data?.byDeviceName.timeline}*/}
					{/*		description="Registros por dia"*/}
					{/*	/>*/}
					{/*</DefaultBox>*/}

					<div className={"py-5"}/>

				</LoadingSection>
			</Container>
			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	)
}

export default {
	title: 'Pagination/Remote',
	component: ClientLogDashboard
};
