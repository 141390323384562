import {
} from "../../../../constants/formLists";

import moment from "moment"
import RegistrationFormStatus from "../Status/RegistrationFormStatus";
import RenewStatus from "../Status/RenewStatus";

moment.locale('pt-BR')

const StatusComponent = [

    {
    	id:'status.component',
		component:RegistrationFormStatus,
	},
]

const RenewStatusComponent = [

    {
    	id:'status.component',
		component:RenewStatus,
	},
]

export const statusScreens = [
    {
    	id:'status',
        caption:'Ficha cadastral',
        fields : StatusComponent,
    },
    {
    	id:'renewStatus',
        caption:'Status Renovação',
        fields : RenewStatusComponent,
    },
]
