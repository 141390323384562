import React, {useEffect, useState} from "react"

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

import moment from "moment"
moment.locale('pt-BR')

function DateRange({
		value = [
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD')
		],
		children,
		onChange=() => {},
		...props
	}) {

	//const [selectedDate, setSelectedDate] = useState(value)
	const [firstLoad, setFirstLoad] = useState(true)

	const selectDate = (date) => {
		// if (firstLoad) {
		// 	setFirstLoad(false)
		// 	return
		// }


		if (date && date?.length>1) {
			console.log('selectedDate', date)
			//setSelectedDate(date)
			onChange(date)
		}
	}

	const options = {
		mode: 'range',
		altInput: true,
		allowInput: false,
	    enableTime: false,
		altFormat: "j \\d\\e F/Y",
		dateFormat: "Y-m-d",
		locale: Portuguese,
		inline: false
	}

    return (
		<Flatpickr
			value={value}
			options={options}
			onChange={selectDate}

		/>

    )
}

export default DateRange;
