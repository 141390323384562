import React, {useEffect, useState, useMemo} from "react"

import { useOvermind } from '../../overmind'

import { matchPath, useLocation, useHistory, useParams } from "react-router-dom"
import {ListRegistrationForm} from "../Clients/List";

const _ = require('lodash')

function ContainerSwap(props) {

	const [container, setContainer] = useState(props.component || null)
	const params = useParams();
	const location = useLocation()
	const app = useOvermind('app')

	//console.log('location', location.pathname)

	const handleSearchChange = (data) => {
		setContainer(<ListRegistrationForm />)
	}

	useEffect(() => {
		if (app.state.searchCount)
			handleSearchChange(app.state.searchParams)
	}, [app.state.searchCount])

	useEffect(() => {
		if (location.pathname)
			setContainer(props.component || null)
	}, [location.pathname])

	const isInResultPage = !!matchPath(
		location.pathname,
		'/clients/list'
	);


	return container
}

export default ContainerSwap;
