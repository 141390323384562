import {loadAllRemoteLists} from "../../constants/remoteLists";

export  const loadRemoteLists = ( { state } ) => {
	if (!state.remoteListsLoaded && !state.remoteListsLoading) {
		state.remoteListsLoading = true
		return loadAllRemoteLists()
			.then(() => {
				state.remoteListsLoaded = true
				console.log('Remote lists loaded!')
			})
	} else {
		return Promise.resolve()
	}

}

export const setSearchParams = ( { state }, searchParams ) => {
    state.app.searchParams = searchParams
    state.app.searchCount++
}

