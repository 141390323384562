import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import {useOvermind, useOvermindForm} from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../../components/Common/Button/Button"
import {yesNoOptions} from "../../../../constants/formLists";
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";
import ModalForm from "../../../../components/Common/Modals/ModalForm";
import {getFullHtmlTemplate, getHtmlTemplate} from "../htmlTemplates";
import {downloadFileText} from "../../../../common/url";
import {copyRichTextToClipboard, selectTextRange} from "../../../../common/clipboard";

import {
	isIOS,
	isSafari,
	isMobile,
	deviceDetect,
} from 'react-device-detect'

import logo from "../../../../assets/images/logos/logo-dark.svg"

const steps = [
	'start', 'confirm-code', 'fill-form', 'copy-code'
]

function MailSigGuestUpdatePage(props) {


	const formId = 'MailSigGuestForm'
	const mailsig = useOvermind('mailsig')

	const [loading, setLoading] = useState(false)
	const [stepIndex, setStepIndex] = useState(0)
	const [data, setData] = useState({})
	//const [data, setData] = useState(null)

	const form = useOvermindForm(formId)

	const handleDownloadHtml = async (row) => {
		const html = getFullHtmlTemplate(row.email)
		downloadFileText(html)
	}

	const handleCopyHtml = async (row) => {
		const html = document.getElementById(`html-signature-${row.id}`).innerHTML
		// const html = getFullHtmlTemplate(row.filename)

		copyRichTextToClipboard(html,
			() => alert(`O código HTML foi copiado para área de transferência! \n\nEle pode ser colado diretamente no campo de assinatura do painel de emails (G-Mail, Outlook, etc...)`),
			() => alert("Não foi possível copiar o texto para a área de transferência.")
		)

	}

	const selectAll = async (row) => {

		alert(`O texto do html foi selecionado, copie o texto selecionado e cole em seu gerenciador de e-mails. \n\nEle pode ser colado diretamente no campo de assinatura do painel de emails (G-Mail, Outlook, etc...)`)
		setTimeout(() => {
			const element = document.getElementById(`html-signature-${row.id}`)
			if (document.selection) { // IE
				const range = document.body.createTextRange();
				range.moveToElementText(element);
				range.select();
			} else if (window.getSelection) {
				// selectTextRange(element, 0, element.innerHTML.length)
				const range = document.createRange();
				range.selectNode(element);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(range);
			}
		}, 100)

		// const html = getFullHtmlTemplate(row.filename)

		// setTimeout(() =>{
		// 	// try { element.focus() } catch(e) {}
		// }
		// )

	}

	const nextStep = () => {
		if (stepIndex<steps.length-1)
			setStepIndex(stepIndex+1)
	}

	const previousStep = () => {
		if (stepIndex>0)
			setStepIndex(stepIndex-1)
	}

	const handleConfirm = async (values) => {
		const step = steps[stepIndex]
		// console.log({values})
		if (step==='start') {
			setLoading(true)
			mailsig.actions
				.generateConfirmCode(values.email)
				.then(() => {
					nextStep()
				})
				.catch(e => {
					alert('Ocorreu um erro ao processar')
					console.log(e)
				})
				.finally(() => setLoading(false))

		} else if (step==='confirm-code') {

			setLoading(true)
			mailsig.actions
				.confirmCode({email:values.email, code:values.code})
				.then(response => {
					console.log('response.data', response.data)
					setData(response.data)
					form.actions.setModelValueByPath({formId, path:'name', value:response.data.name})
					form.actions.setModelValueByPath({formId, path:'phone', value:response.data.phone})
					console.log('state', form.state.model)

					form.actions.incFormValidatedCount({formId})
					nextStep()
				})
				.catch(e => {
					alert('Não foi possível continuar. Por favor, verifique o código digitado.')
					console.log(e)
				})
				.finally(() => setLoading(false))
		} else if (step==='fill-form') {
			setLoading(true)
			mailsig.actions
				.saveByCode({
					email:values.email,
					code:values.code, data:{
						email : values.email,
						name : values.name,
						phone : values.phone,
					}})
				.then(response => {
					nextStep()
				})
				.catch(e => {
					alert('Não foi possível salvar.')
					console.log(e)
				})
				.finally(() => setLoading(false))
		}

    	// setSaving(true)
    	// mailsig.actions.save({
		// 		formId,
		// 		id:viewId,
		// 		data,
		// 	})
		// 	// .then(sleep(1000))
		// 	.then(() => {
		// 		setSaved(true)
		// 		onConfirm()
		// 		setTimeout(() => {
		// 			setSaving(false)
		// 		}, 2000)
		// 	})
		// 	.catch((e) => {
		// 		// const errCode = e.response?.data?.error?.message
		// 	})

	}

	const CopyComponent = ({data}) => {
		return <div className="d-flex flex-column m-0 p-0  ">
			<div className="d-flex flex-row justify-content-center mb-4">
					<Button
						title={"Fazer download da página HTML "}
						color={`success me-1`}
						iconClass="bx bxs-download"
						onClick={() => handleDownloadHtml(data)}
					>
						<i className="bx bxs-download font-size-12 me-2" />
						Download HTML
					</Button>
				{!(isIOS || isSafari)
					?
						<Button
							title={"Copiar para área de transferência"}
							color={`success ms-1 `}
							onClick={() => handleCopyHtml(data)}
						>
							<i className="far fa-copy font-size-12 me-2" />
							Copiar código
						</Button>
					:
						<Button
							title={"Selecionar o texto"}
							color={`success ms-1 `}
							onClick={() => selectAll(data)}
						>
							<i className="fas fa-text-height font-size-12 me-2" />
							Selecionar texto html
						</Button>
				}
			</div>
			<div className="flex-grow-1 flex-fill mr-3 ms-2 p-0 overflow-auto"
				 style={{
				 	border:'dashed 2px var(--bs-green)',
					 maxHeight:'16rem'
				 }}
			>
					<div
						id={`html-signature-${data.id}`}
						dangerouslySetInnerHTML={{__html:getHtmlTemplate(data.email)}}
					/>
			</div>
		</div>
	}

	const fields = useMemo(() => {
		const step = steps[stepIndex]

		if (step==='start') {
			return [
				{ id:'titleSig', caption:'Iniciar configuração', type:'title', row:true, col:12,
					subtitle : "Informe seu e-mail para iniciar a configuração da sua assinatura de e-mail."
				},
				{ id:'email', caption:'E-mail', type:'email', required:true, row:true, col:12 },
			]
		} else if (step==='confirm-code') {
			return [
				{ id:'titleSig', caption:'Confirme o código', type:'title', row:true, col:12,
					subtitle: "Foi enviado um código de confirmação para o seu e-mail, informe no campo abaixo para prosseguir."
				},
				{ id:'email', caption:'E-mail', type:'email', required:true, row:true, col:12, readOnly:true },
				{ id:'code', caption:'Código de confirmação', type:'text', required:true, row:true, col:12},
			]
		} else if (step==='fill-form') {
			return [
				{ id:'titleSig', caption:'Atualize os dados', type:'title', row:true, col:12,
				subtitle : "Informe seu Nome e Telefone conforme deseja mostrar em sua assinatura de e-mail."},
				{ id:'email', caption:'E-mail', type:'email', required:true, row:true, col:12, readOnly:true },
				{ id:'name', caption:'Nome', type:'text', required:true, row:true, col:12 },
				{ id:'phone', caption:'Telefone', type:'phone', required:false, col:12 },
			]
		} else if (step==='copy-code') {
			return [
				{ id:'titleSig', caption:'Copie o código', type:'title', row:true, col:12,
					subtitle : "Os dados da sua assinatura foram atualizados! Caso ainda não tenha instalado sua assinatura, escolha as opções abaixo:"
				},
				// { id:'email', caption:'E-mail', type:'text', required:true, row:true, col:12, readOnly:true },
				{ id:'copy-component', component:CopyComponent},
			]
		}

		return []

	}, [stepIndex]);

	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <>
			<ModalForm
				formId={formId}
				modalStyle={{
					width: stepIndex === steps.length-1 ? '70%' : '75vh'
				}}

				saving={loading}
				topContent={<>
					<div className="pb-4 d-flex flex-column">
						<div className="">
							<img src={logo} height={24} />
						</div>
						<div className="pt-2 font-family-title font-weight-800">
							Assinaturas de e-mail
						</div>

					</div>
				</>}

				confirmBtnCaption={"Próximo"}
				cancelBtnCaption={"Voltar"}
				closeOnClickOutside={false}
				haveCancelBtn={stepIndex>0}
				haveConfirmBtn={stepIndex<steps.length-1}

				data={data}
				isVisible={!!data}
				fields={fields}
				onConfirm={(values) => handleConfirm(values)}
				onCancel={previousStep}

			/>
		</>
    )
}

export default MailSigGuestUpdatePage;
