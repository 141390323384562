import React, {useEffect, useState} from "react"

import KYCInfo from "../components/KYCInfo";
import {dateIsoToBr} from "../../../../../renders/utils";
import DefaultTable from "../../../../../components/Common/Tables/DefaultTable/DefaultTable";

function KYCProfessionalData(
{
	KYCData,
	...props
}) {


	const [info, setInfo] = useState(null)
	const [professions, setProfessions] = useState(null)

	useEffect(() => {
		const info = KYCData[0] && KYCData[0].professionData

		setInfo([
			{caption:'Número total de profissões exercidas', value:info?.totalActiveProfessions},
			{caption:'Total de renda', value:info?.totalIncome},
			{caption:'Empregado atualmente?', value:info?.isEmployed, formatter:(value) => value ? 'Sim' : 'Não'},
		])
		setProfessions(info?.professions || [])

	}, [KYCData])

	return (
		<>
			<KYCInfo data={info} />
			<br />
			<DefaultTable
				columns={[
					{id:'income', caption:'Renda'},
					{id:'companyName', caption:'Companhia'},
					{id:'companyIdNumber', caption:'Doc. Companhia'},
					{id:'startDate', caption:'Início', formatter:(row) => dateIsoToBr(row.startDate)},
					{id:'endDate', caption:'Término', formatter:(row) => dateIsoToBr(row.endDate)},
				]}
				data={professions}
			/>
		</>
	)
}

export default KYCProfessionalData;

