import React from "react"
import {isCnpj, isCpf} from "../../../../helpers/validation_helper";
import {dateBrToISO, onlyNumbers} from "../../../../renders/utils";
import {parseMoney, parsePercent} from "../../../../helpers/parser_helper";
import { registeredFieldTypes } from "./../Utils/InputTypes";

const _ = require('lodash')


export const validateField = (field, model, fieldState) => {

	if (!field.type)
		return true

	let valid=true
	const validator = field.validation || registeredFieldTypes[field.type].defaultValidator

	const visible = !(fieldState[field.id]?.visible!==undefined && !fieldState[field.id]?.visible)
	if (visible && validator) {
		//console.log('fewefwe', {id:field.id, values})
		if (field.requiredMethod)
			field.required = field.requiredMethod(model)


		const value = _.get(model, field.id)
		valid = validator(value, field, model)
		return valid
	} else {
		return true
	}
}

export const validateFormScreens = (screenList, model, fieldState) => {

	const result = {}
	let first=null

	for (const screen of screenList) {

		for (const field of screen.fields) {
			if (!field.type)
				continue

			const valid = validateField(field, model, fieldState)

			if (valid!==true) {
				if (!result[screen.id])
					result[screen.id] = {}

				const fieldResult = {field, msg:valid}
				result[screen.id][field.id] = fieldResult;
				if (!first)
					first = fieldResult
				//field.validate()
			}

		}
	}

	return {first, errors:result}


}

export const validateFieldStates = (screenList, model, setFieldStateFunc, setInputValue) => {

	for (const screen of screenList) {

		for (const field of screen.fields) {

            if (field.onChange) {
                field.onChange(model, setFieldStateFunc, setInputValue)
            }

		}
	}

}

export function isValidCep(value, props) {
	const pValue = value ? value : '';
	const isEmpty = (pValue=='_____-___') || !pValue
	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
 		return true
	}

	return pValue?.replace(/[^\d]+/g,'').length==8 ? true : props.errorMessage || 'CEP Inválido'
}

export const requiredIfPF = (values, form) => {
	if (values.client.welfare!='0')
		return false
	return !values.client.document || isCpfFormat(values.client.document) ? true : false
}

export const isCpfFormat = (value) => {
	const res = onlyNumbers(
			value?.replace(/[^\d]+/g,'').trim()
		)
		.toString()
		.length
	return res< 12
}

export const isValidCpfCnpj = (value, props) => {
	const pValue = value ? value : '';
	const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()

	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
		return true
	}

	if (isCpfFormat(pValue)) {
		return isCpf(pValue) ? true : props.errorMessage || 'Informe um CPF válido'
	} else {
		return isCnpj(pValue) ? true : props.errorMessage || 'Informe um CNPJ válido'
	}
}

export const isValidCpf = (value, props) => {
	const pValue = value ? value : '';
	const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()

	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
		return true
	}

	return isCpf(pValue) ? true : props.errorMessage || 'Informe um CPF válido'
}

export const isValidCnpj = (value, props) => {
	const pValue = value ? value : '';
	const isEmpty = (pValue=='___.___.___.__') || (pValue=='__.___.___/____-__') || !pValue.trim()

	if (props.required && isEmpty) {
		return 'Campo obrigatório'
	} else if (!props.required && isEmpty) {
		return true
	}

	return isCnpj(pValue) ? true : props.errorMessage || 'Informe um CNPJ válido'
}

export const isValidDate = (value, props) => {
	//return true
	//console.log('isValidDate', value)
	const pValue = value ? value.trim() : '';

	if (props?.required && !pValue)
		return "Campo obrigatório"

	if (!props?.required || dateBrToISO(pValue))
		return true
	else
		return "Data inválida"

}

export const isValidDateRange = (value, props) => {
	//return true
	//console.log('isValidDate', value)
	const pValue = typeof value=='object' ? value.length : 0;

	if (props.required && pValue<2)
		return "Campo obrigatório"

	if (!props.required)
		return true
	else
		return "Período inválido"

}

export const isValidTime = (value, props) => {
	//return true
	//console.log('isValidTime', value)
	const pValue = value ? value.trim() : '';

	if (props.required && !pValue)
		return "Campo obrigatório"

	const pValues = pValue.split(':')

	const hour = parseInt(pValues[0])
	const minute = parseInt(pValues[1])

	if (!pValues.length || value.indexOf('_')>0 || isNaN(minute) || isNaN(hour) )
		return "Horário inválido"

	if (hour>23)
		return "Hora não pode ser maior que 23"

	if (minute>59)
		return "Minutos não pode ser maior que 59"

	return true

}

export const isValidEmail = (value, props) => {
	const pValue = value ? value.trim() : '';
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (props.required && !pValue)
		return "Campo obrigatório"

	if (re.test(pValue))
		return true
	else
		return "E-mail inválido"

}

export const isValidMoney = (value, props) => {
	const parsedValue = parseMoney(value)

	if (!props.required) {
		return !isNaN(parsedValue)
			? true
			: 'Digite um valor válido'
	}

	return !isNaN(parsedValue) && parsedValue!=0
		? true
		: props.errorMessage || `Campo obrigatório`


}

export const isValidPercent = (value, props) => {
	const parsedValue = parsePercent(value)

	if (!props.required) {
		return !isNaN(parsedValue)
			? true
			: 'Digite um valor válido'
	}

	return !isNaN(parsedValue) && parsedValue!=0
		? true
		: props.errorMessage || `Campo obrigatório`


}

export const isValidPhoneNumber = (value, props) => {
	const parsedValue = value?.replace(/[^\d]+/g,'') || '';

	let validated
	if (!props.required && !parsedValue) {
		return true
	} else {
		validated = parsedValue?.length >= 2 && (parsedValue?.[2] === "9")
			? parsedValue.length==11
			: parsedValue.length==10
	}

	return validated ? true : `Digite um número de telefone válido`
}

export const isValidNumber = (value, props) => {
	const pValue = value ? value.toString().trim() : '';
	//console.log('pValuenumber', pValue)
	if (value==='0') return true

	if (!props.required) {
		return !isNaN(pValue)
			? true
			: 'Digite um número válido'
	}

	return pValue && !isNaN(pValue) && pValue!==0
		? true
		: `Campo obrigatório`

}

export const validatePasswordStrong = (password, props) => {
    if (
      password === undefined ||
      password === null ||
      password === "" ||
      password.length < 8
    ) {
      return false;
    }
    // This regex checks if there is at least one special char in the string.
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharsRegex.test(password)) {
      return 'false';
    }
    // This regex checks if there is at least one capital char in the string.
    const capitalCharRegex = /\w*[A-Z]\w*/;
    if (!capitalCharRegex.test(String(password))) {
      return false;
    }
    // This regex checks if there is at least one numeric char in the string.
    const numericCharRegex = /\d+/;
    if (!numericCharRegex.test(String(password))) {
      return false;
    }
    return true;

}

export const isValidValue = (value, props) => {

	//console.log({props, value})
	const pValue = value ? value?.toString().trim() : '';

	if (props.required) {
		return pValue
			? true
			: 'Campo obrigatório'
	}

	return true

}

export const isValidOptions = (value, props) => {
	//console.log({props, value})
	const pValue = value ? value : [];

	if (props.required) {
		return pValue
			? pValue.length>0
			: 'Campo obrigatório'
	}

	return true

}

export const isValidUpload = (value, props) => {
	return isValidValue(value, props)
	const pValue = value ? value : [];
//	console.log("UPLOAD", value)

	if (props.required) {
		return pValue
			? pValue.length>0
			: 'Campo obrigatório'
	}

	return true

}

