import React, { useState } from "react"

import InputMask from "react-input-mask";
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
import {isValidCep } from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldCep(props) {

    const [value, setValue] = useState(props.value || ' ')

    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

	const rest = getValidDomProperties(props)
    //const {caption, type, inputRef, formId, screenId, validation,  ...rest} = props

    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask="99999-999"
            maskChar={value && value.trim() ? " " : null}
            tag={[Input, InputMask]}
            {...rest}
            onChange={handleChange}
            autoComplete="disabled"
            placeholder=""
			required={props.required}
            // validate={{val:() => isValidCep(value, props) }}
            validate={props.validation}
        />
    )
}

export default FieldCep;
