import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../helpers/gestora/api"
import {Col, Row, Badge} from "reactstrap";

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
import {dateBrToISO, MoneyFormat} from "../../../renders/utils";

import CustomForm from "../../../components/Common/CustomForm/Form";
import {
	datePeriodOptions,
	docFundTypes,
	investmentOperationMapped,
	investmentOperationOptions, investmentStatusCanceledReasonMapped,
	investmentStatusMapped,
	investmentStatusOptions, investmentStatusPendingReasonMapped,
	riskProfileMapped,
	riskProfileOptions
} from "../../../constants/formLists";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";
moment.locale('pt-BR')
import Button from "../../../components/Common/Button/Button"

import {parseMoney} from "../../../helpers/parser_helper";
import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import {DataBank, DataDate} from "../../../components/Common/Tables/Datatable/DataTypes";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../common/url";
import {useLocalStorage} from "../../../helpers/gestora/UseLocalStorage";

const defaultDate = [
	moment().startOf('month').format('YYYY-MM-DD'),
	// moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

const statusTabs = [
	{id:'all', caption:"Busca"},
]

//console.log('statusTabs', statusTabs)

export const InvestmentsFirstRelationSearch = () => {
	const formId = 'InvestmentFirstRelationSearch'
	const location = useLocation()
	const history = useHistory()

	const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	// const [compactMode, setCompactMode] = useState(true)
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [approvingMany, setApprovingMany] = useState(false)
	const [sendingEmailMany, setSendingEmailMany] = useState(false)
	const [exportingResult, setExportingResult] = useState(false)

	const app = useOvermind('app')

	const apiEndpoint = {
		path : `admin/investmentsfirstrelation/search`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			submitForm, setModelValueByPath,
			setFormState, setFieldState, setFormModel, resetForm,
			updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	const handleExportResult = (evt, fileTitle) => {

		setExportingResult(true)

		api
			.post(`admin/investmentsfirstrelation/downloadxls`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))


	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			periodStart : model?.period?.length && model?.period[0],
			periodEnd : model?.period?.length && model?.period[1],
			associateId : model?.associateId,
		})
	}

	useEffect(() => {
		if (history.location?.state?.period) {
			if (model?.period) {
				refreshList()
			}
		} else {
			refreshList()
		}
	}, [filterStatus, filterParams, model?.period, model?.associateId])

	useEffect(() => {
		setModelValueByPath({formId, path:'period', value:history.location?.state?.period || null})

	}, [history.location])

	// useEffect(() => {
	// 	updateModelValueValues({formId, values:{
	// 		fundId:null,
	// 		operation:null,
	// 	}})
	//
	// 	// if (!history.location?.state)
	// }, [location])


	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'clientId',
			name: <CustomHeader onComplete={handleFilterChange} id={'clientId'} formId={formId} fieldProps={{type:'text'}}>Código Fiduc</CustomHeader>,
			grow:0,
			width:'128px',
			selector: row => `#FIDUC${row?.clientId}`,
			sortable: true,
			compact : true,
			wrap : true,
		},
		// {
		// 	id : 'associateHubspotCode',
		// 	// name: "Código Planejador",
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'associateHubspotCode'} formId={formId} fieldProps={{type:'text'}}>Código Planejador</CustomHeader>,
		// 	grow:0,
		// 	width:'172px',
		// 	selector: row => row?.associateHubspotCode,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		// },
		{
			id : 'associateName',
			name: "Planejador",
			//name: <CustomHeader id={'associateName'} formId={formId} fieldProps={{type:'text'}}>Planejador</CustomHeader>,
			grow:10,
			// width:'96px',
			selector: row => row?.associateName,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'createdAt',
			name: "Data",
			//name: <CustomHeader id={'created_at'} formId={formId} fieldProps={{type:'date'}}>Envio</CustomHeader>,
			grow:0,
			width:'128px',
			selector: function A(row) {
				return <DataDate value={row?.createdAt}/>
			},
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'empty',
			name: ' ',
			width:'128px',
			grow:0.5,
			selector: ' ',
			omit : compactMode,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'period',
					caption:'Período',
					type:'daterange',
					options:datePeriodOptions,
					emptyOptionText:<b>Tudo</b>,
					//noEmptyOption:true,
					required:true,
					col:4
				},
				{
					id:'associateId',
					caption:'Planejador',
					type:'select2',
					//noEmptyOption:true,
					emptyOptionText : <b>Todos</b>,
					listId:'Associate',
					required:true,
					col:8
				},
			],
		},
	], []);

	//console.log(orderDirection)
	return (<>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Recompensa por Relacionamento"  />
			}
		>
			<CustomForm
				className="needs-validation "
				formId={formId}
				model={model}
				validateOnLoad={false}
			>

				<PageDatatable
					formId={formId}
					tabs={statusTabs}
					activeTab={filterStatus}
					onTabChange={tabId => setFilterStatus(tabId)}
					TabActions={<>
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						{/*<Button*/}
						{/*	disabled={false}*/}
						{/*	colorIcon={true}*/}
						{/*	className="btn btn-outline-primary m-1 btn-sm"*/}
						{/*	// disabled={currentScreen==0}*/}
						{/*	onClick={() => setCompactMode(!compactMode)}>*/}
						{/*	<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>*/}
						{/*</Button>*/}
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						// onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</>
	);
};

export default {
	title: 'Pagination/Remote',
	component: InvestmentsFirstRelationSearch,
};
