
//import {environment} from './configuration'

const authUserStorageName = 'FiducGestora:authUser'
import lStorage from "./lStorage"

let currentInfo

export const Login = (info) => {
	currentInfo = info
	return lStorage.set(authUserStorageName, info)
}

export const Logout = () => {
	currentInfo = null
	return lStorage.set(authUserStorageName, '')
}

export const AuthInfo = () => {
	if (!currentInfo) {
		currentInfo = lStorage.get(authUserStorageName)
	}
	return currentInfo
}

export const UserCan = (permissions, anyOf=false) => {

	if (!currentInfo)
		AuthInfo()

	if (!currentInfo)
		return false

	if (typeof permissions == 'string')
		permissions = [permissions]

	const apis = currentInfo?.profile?.apis || []

	let ok = false
	for (let permission of permissions) {
		permission = permission.split('.')

		const item = apis.find(row => row.api && row.api.name==permission[0])

		if (anyOf) {
			if (item && item[permission[1]] ) {
				return true
			}
		} else {
			if (item && item[permission[1]] ) {
				ok = true
			} else {
				return false
			}
		}

		//alert(item.api.nameTranslated)
	}
	// console.log('currentInfo', currentInfo)

	return ok
}
