import React, {useState} from "react"

import InputMask from "react-input-mask";
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
import { isCnpj } from '../../../../helpers/validation_helper'
import {isValidCnpj, isValidCpfCnpj} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldCnpj(props) {

    const [value, setValue] = useState('')
    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }
    const validate = (value) => {

        if (!props.required && ((value=='__.___.___/____-__') || !value.trim()))
            return true

        return isCnpj(value) ? true : props.errorMessage || 'Informe um CNPJ válido'
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask="99.999.999/9999-99"
            maskChar=""
            tag={[Input, InputMask]}
            {...rest}
            autoComplete="disabled"
            type="text"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidCnpj(value, props) }}
            validate={props.validation}
        />
    )
}

export default FieldCnpj;
