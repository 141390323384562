import React from "react"
import RegistrationFormSection from "../RegistrationFormSection";

import {registrationFormScreens} from "../Configuration/InvestorDataConfig"

function InvestorData(props) {
    return <RegistrationFormSection screens={registrationFormScreens} {...props} />
}

export default InvestorData;
