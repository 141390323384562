import React, { useState } from "react"

import {
	 Collapse,
} from "reactstrap"

// import { useOvermind } from '../../../overmind'

import classnames from "classnames"

function AccordionDefault(
{
	options,
	id,
	//hasContent = false,
	activeOption='',
	...props
}) {

    const [active, setActive] = useState(activeOption)

    const toggle = (option, optionIndex) => {
        setActive(option.id)
        if (props.onChange)
            props.onChange(option, optionIndex)
    }

    //const screen = screenList[currentScreen]

    return (
        <>
			<div className="accordion" id={id}>

                {options && options.map((option, optionIndex) => {
				return <>
					<div className="accordion-item">
					<h2 className="accordion-header" id={option.id}>
						<button
							className={classnames(
								"accordion-button",
								"fw-medium",
								{ collapsed: active === option.id }
							)}
							type="button"
							onClick={() => toggle(option, optionIndex)}
							style={{ cursor: "pointer" }}
						>
							{option.caption}
						</button>
					</h2>

					<Collapse isOpen={active == option.id} className="accordion-collapse">
						<div className="accordion-body  pt-0">
							<div className="text-muted">
								{option.content || null}
							</div>
						</div>
					</Collapse>

					</div>
				</>
				})}

		</div>
        </>
    )
}

export default AccordionDefault;
