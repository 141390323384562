import {
	apiMockedOptions, statesBrOptions
} from "../../../../constants/formLists";
import {isValidDate, requiredIfPF} from "../../../../components/Common/CustomForm/Utils/Validations";
import moment from "moment"
import ZapSignDocuments from "../Components/ZapSignDocuments";
import MismatchTermDocument from "../Components/MismatchTermDocument";
import {defaultFiducAcceptFiles} from "../../../../helpers/gestora/configuration";
import ServiceProposalDocument from "../Components/ServiceProposalDocument";
moment.locale('pt-BR')


const validateOrgEmission = (value, props, values) => {
	const {
		expeditiondate=null,
		validDate=null,
	} = values?.client?.ocr || {}

	if (expeditiondate && validDate) {
		return moment(expeditiondate, 'DD MM YYYY')
			.isBefore(
				moment(validDate, 'DD MM YYYY'),
				'year'
			)
	}

	return isValidDate(value, props)
}

const RegistrationFormIdentification = [



    //Foto do Perfil
    {id:'titleCapturePicture', type:'title', caption:'Foto', xsubtitle:''},
    {id:'client.personPhoto', caption:'Foto do perfil', type:'upload', accept:'image/*', file_caption:'Foto',  required:false, row:true, col:6},

    {id:'titleCapture', type:'title', caption:'Documento de identificação', xsubtitle:'Documentos de identificação'},

    {id:'client.ocr.documentType', caption:'Tipo de documento', type:'radiogroup', requiredMethod:requiredIfPF, inline:true,
        options : [
            {id:'CNH', caption:'CNH'},
            {id:'RG', caption:'RG'},
            {id:'RNE', caption:'RNE'},
        ],
        onChange : (values, setFieldState) => {

            const value=values.client?.ocr?.documentType
            setFieldState('client.ocr.validDate', 'visible', value=='CNH' || value=='RNE')
            setFieldState('client.ocr.placeofemission', 'visible', value=='CNH' || value=='RG')
			//return
            //setFieldState('doccapture.photoback', 'visible', value=='RG' || value=='RNE')

            setFieldState('client.ocr.rg.documentPhotoFront', 'visible', value=='RG')
            setFieldState('client.ocr.rg.documentPhotoBack', 'visible', value=='RG')

            setFieldState('client.ocr.cnh.documentPhotoFront', 'visible', value=='CNH')

            setFieldState('client.ocr.rne.documentPhotoFront', 'visible', value=='RNE')
            setFieldState('client.ocr.rne.documentPhotoBack', 'visible',  value=='RNE')
        }
    },

    {id:'client.ocr.rg.documentPhotoFront', caption:'Foto frente', type:'upload', accept:'image/*', file_caption:'RG (frente)', requiredMethod:requiredIfPF, row:true, col:6},
    {id:'client.ocr.rg.documentPhotoBack', caption:'Foto verso', type:'upload', accept:'image/*', file_caption:'RG (verso)',  requiredMethod:requiredIfPF, col:6},

    {id:'client.ocr.cnh.documentPhotoFront', caption:'Foto frente', type:'upload', accept:'image/*', file_caption:'CNH aberta (frente e verso)', requiredMethod:requiredIfPF, row:true, col:6},

    {id:'client.ocr.rne.documentPhotoFront', caption:'Foto frente', type:'upload', accept:'image/*', file_caption:'RNE (frente)',  requiredMethod:requiredIfPF, row:true, col:6},
    {id:'client.ocr.rne.documentPhotoBack', caption:'Foto verso', type:'upload', accept:'image/*', file_caption:'RNE (verso)',  options:apiMockedOptions, requiredMethod:requiredIfPF, col:6},

    {id:'client.ocr.identificationNumber', caption:'Número do documento',
		allowNumbers : true,
		type:'text',
		requiredMethod:requiredIfPF,
	},

    {id:'client.ocr.orgemission', caption:'Orgão emissor', type:'text', requiredMethod:requiredIfPF, row:true},
    {id:'client.ocr.placeofemission', caption:'Estado', type:'select2', requiredMethod:requiredIfPF, options:statesBrOptions},
    {id:'client.ocr.expeditiondate',
		caption:'Data de emissão',
		type:'date',
		requiredMethod:requiredIfPF,
	},

    {id:'client.ocr.validDate',
		caption:'Data de validade',
		type:'date',
		validation:validateOrgEmission,
		requiredMethod:requiredIfPF,
	},


]

const RegistrationFormOtherDocuments = [

    {id:'titleRepProc', type:'title', caption:'Representante/Procurador', xsubtitle:''},

    {id:'client.responsible.documentPhoto',
        caption:'Documento de representante / procurador',
        subtitle : 'Envie a procuração caso possua um procurador ou o documento de representação caso possua um representante.',
        type:'upload',
        accept:'image/*,.pdf',
        file_caption:'Procuração / Representação',
        requiredMethod:requiredIfPF,
        row:true,
        col:6
    },

    {id:'client.responsible.incapacityPhoto',
        caption:'Documento de comprobatório  de incapacidade',
        subtitle : 'Envie a procuração caso possua um procurador ou o documento de representação caso possua um representante.',
        type:'upload',
        accept:'image/*,.pdf',
        file_caption:'Comprovação de incapacidade',
        requiredMethod:requiredIfPF,
        row:false,
        col:6
    },

    {id:'titleCaptureResidencial', type:'title', caption:'Comprovante de residência', xsubtitle:''},
    {id:'doccapture.proofOfResidence',
		caption:'Foto do comprovante de residência',
		type:'upload',
		accept:defaultFiducAcceptFiles,
		file_caption:'Comprovante',
		requiredMethod:requiredIfPF,
		row:true,
		col:6,
	},


    // {id:'titleRenounced', type:'title', caption:'Comprovante de renúncia (FATCA versão 1)', xsubtitle:''},
    // {id:'fatca.renouncedDocument',
    //     caption:'Comprovante de renúncia',
    //     type:'upload',
    //     accept:'image/*',
    //     file_caption:'Comprovação de renúncia',
    //     requiredMethod:false,
	// 	readOnly:true,
	// 	disabled:true,
    //     row:true,
    //     col:6
    // },

    {id:'titleFatcaDoc', type:'title', caption:'Fatca', xsubtitle:''},
    {
    	id:'fatca.fatcaFormEua',
        caption:'Formulário Fatca - Estados Unidos',
        type:'upload',
        accept:'image/*,.pdf',
        file_caption:'Formulário Fatca - Estados Unidos',
        requiredMethod:requiredIfPF,
        col:6
    },
    {
    	id:'fatca.fatcaFormLossCitizenship',
        caption:'Certificado de perda de nacionalidade',
        type:'upload',
        accept:'image/*,.pdf',
        file_caption:'Certificado de perda de nacionalidade',
        requiredMethod:requiredIfPF,
        col:6
    },
    {
    	id:'fatca.fatcaFormResignedCitizenshipEua',
        caption:'Declaração de renúncia de cidadania EUA',
        type:'upload',
        accept:'image/*,.pdf',
        file_caption:'Declaração de renúncia de cidadania EUA',
        requiredMethod:requiredIfPF,
        col:6
    },
    {
    	id:'fatca.fatcaFormResignedGreenCard',
        caption:'Certificado de abandono de Green Card',
        type:'upload',
        accept:'image/*,.pdf',
        file_caption:'Certificado de abandono de Green Card',
        requiredMethod:requiredIfPF,
        col:6
    },
]

const RegistrationFormSignature = [

    {id:'titleSignature',
        caption:'Assinatura',
        type:'title',
    },

    {id:'investorDeclaration.signature', caption:'Documento de assinatura', type:'upload', accept:'image/*', file_caption:'Assinatura',  requiredMethod:requiredIfPF, row:true, col:6},
    {id:'investorDeclaration.local', caption:'Local da assinatura', type:'text', requiredMethod:requiredIfPF,},
    {id:'investorDeclaration.date', caption:'Data da assinatura', type:'date', requiredMethod:requiredIfPF,},

]

const RegistrationFormFunds = [

    {
    	id:'service-proposal',
		component:ServiceProposalDocument,
    },

    {id:'titleFunds',
        caption:'Fundos escolhidos',
        type:'title',
    },

    {id:'funds',
        caption:'Fundos que o cliente deseja investir',
        type:'select2multi',
        requiredMethod:requiredIfPF,
        listId:'Funds',
        col:12
    },

    // {id:'titleTerms',
    //     caption:'Termos aceitos',
    //     type:'title',
    // },
	//
    // {id:'terms',
    //     caption:'Termos aceitos',
    //     type:'checkgroup',
    //     requiredMethod:requiredIfPF,
	//
	// 	vertical:true,
    //     listId:'Funds',
    //     col:12
    // },

    {
    	id:'zapsign-documents',
		component:ZapSignDocuments,
    },

    // {
    // 	id:'termo-desenquadramento-zapsign',
	// 	component:MismatchTermDocument,
    // },
]

export const captureDocumentsScreens = [
    {id:'identification',
        caption:'Pessoais',
        fields:RegistrationFormIdentification
    },
    {id:'otherdocuments',
        caption:'Documentos adicionais',
        fields:RegistrationFormOtherDocuments
    },
    {id:'signature',
        caption:'Assinatura',
        fields:RegistrationFormSignature
    },
    {id:'terms',
        caption:'Termos',
        fields:RegistrationFormFunds
    },
]
