import React, {useEffect, useState} from "react"

import {
    Col, Row,
} from "reactstrap"

import { AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
//import {isValidValue} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
import { loadOptions } from "../../../../constants/remoteLists";

function FieldRadioGroup(props) {

	const {listId = '', formId} = props
    const [value, setValue] = useState('')
	const [options, setOptions] = useState(props.options)
	const [loading, setLoading] = useState(listId ? true : false)

	useEffect(() => {
		if (listId) {
			loadOptions(listId)
				.then((res) => {
					//console.log("OPTIONS", res)
					//const value=getModelValueByPath({formId, path:props.name})
					//alert(value)
					setOptions(res)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, []);

    const handleChange = (e) => {
        setValue(e.target.value)
    	//console.log('FieldRadioGroup', e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
        if (props.onBlur)
            props.onBlur(e)
    }

    // const {caption, type, inputRef, inline, formId, screenId, validation, vertical, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvRadioGroup
            ref={props.inputRef || undefined}
			className={`form-control form-control-container  
				${props.fieldError ? 'is-invalid' : ''}
			`}
			// readOnly={props.disabled}
            name={props.name}
            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="disabled"
			onChange={handleChange}
			disabled={props.disabled}
			required={props.required}
            // validate={{val:() => isValidValue(value, props) }}

        >
            <Row className="pt-1" style={props.inline ? {display:'flex', alignItems:'flex:start'} : undefined}>
            {props.infoTop && (
                <p className='mt-1 mb-2' style={{paddingRight:'4em'}}>
                    {props.infoTop}
                </p>
            )}
            {options && options.map((option) => {
                //const optionsId = `cki-${props.name}-${option.id}`
                return (
                    <React.Fragment key={option.id}>
                        {props.vertical && <Row />}
                        <Col col={12} style={props.inline ? {flex:0} : undefined}>
                            <AvRadio
                                label={option.caption}
                                value={option.id.toString()}
								onBlur={props.onBlur}
								onFocus={props.onFocus}
								disabled={props.disabled}
                            />
                        </Col>
                    </React.Fragment>
                )
            })}

            {props.info && (
                <p className={'mb-1 mt-2'} style={{paddingRight:'4em'}}>
                    <small>
                    {props.info}
                    </small>
                </p>
            )}
            </Row>

        </AvRadioGroup>
    )


}

export default FieldRadioGroup;
