import api, {sleep} from "../../helpers/gestora/api";
import {dateIsoToBr, onlyNumbers} from "../../renders/utils";


export const loadById = async ( { state, actions }, {formId, id} ) => {

	return api
		.get(`users/${id}`)
		.then(async res => {
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0",
				birthDate : dateIsoToBr(res.data.birthDate),
				initialDate : dateIsoToBr(res.data.initialDate),
				phoneNumber : onlyNumbers(res.data.initialDate),
			}
		})
}

export const save = async ( { state, actions }, {formId, id, data} ) => {

	const request = id
		? api.put(`users/${id}`, data)
		: api.post(`users/`, data)

	return await request
		.then(async res => {
			return res.data
		})
}

export const deleteById = async ( { state, actions }, id ) => {

	return api.delete(`users/${id}`)
		.then(async res => {
			return res.data
		})
}

export const resetPassword = async ( { state, actions }, clientId ) => {

	return api.get(`users/passwordReset/${clientId}`)
		.then(async res => {
			return res.data
		})
}

export const changePassword = async ( { state, actions }, {document, newPassword} ) => {

	return api.post(`users/changePassword`, {document, newPassword})
		.then(async res => {
			return res.data
		})
}

