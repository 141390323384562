import {
	ethnicityOptions,
	genreOptions,
	maritalStatusOptions,
	countryOptions,
	nationalityOptions,
	civilCapacityOptions,
	professionalTypeMapped,
	riskRrofileOptions,
	yesNoOptions,
	apiMockedOptions,
	relationConnectionOptions,
	fiscalReferenceOptions,
	statesBrOptions, professionalTypeEnum, professionalTypeOptions, fatcaRequestTypes, fatcaNotFiscalResidentOptions,
} from "../../../../constants/formLists";

import moment from "moment"
moment.locale('pt-BR')

import {
	isValidDate,
	isValidOptions,
	requiredIfPF
} from "../../../../components/Common/CustomForm/Utils/Validations";

import {parseMoney} from "../../../../helpers/parser_helper";
import {isPepActive, MoneyFormat, onlyNumbers} from "../../../../renders/utils";

import api from "../../../../helpers/gestora/api"

import BadgeRenew from "../../../../components/Common/CustomForm/SubComponents/BadgeRenew";
import RenewHistory from "../RenewHistory/RenewHistory";
import BadgeIncapable from "../../../../components/Common/CustomForm/SubComponents/BadgeIncapable";

const sumPatrimonies = (values, setFieldState, setInputValue, form) => {

	const total =
		parseMoney(values?.client?.realEstatePatrimony) +
		parseMoney(values?.client?.movablePatrimony) +
		parseMoney(values?.client?.financialInvestmentsPatrimony)
	//console.log(total)
	setInputValue('client.investorFinancialSituation', MoneyFormat(total))

}

const updatePepFunctionVisibility = (values, setFieldState) => {
	const pepActive = isPepActive(values.client, values.pep)
	const professionalType = values?.professionalInformation?.professionalType
	setFieldState(
		'professionalInformation.pepFunctionId',
		'visible',
		pepActive
	)
	setFieldState(
		'professionalInformation.carrerId',
		'visible',
		!pepActive && (professionalType==professionalTypeEnum.SALARIED || professionalType==professionalTypeEnum.LIBERAL)
	)
}



const searchZipCode = (zipCode) => {
	const parsedZipCode = onlyNumbers(zipCode)

	if (parsedZipCode.length<8)
		return Promise.resolve()

	return api
		.post(`cep-new/${parsedZipCode}`)
		.then(res => {
			if (res.data?.erro)
				throw new Error('zipcode not found')
			return res.data
		})
		.catch(e => {
			console.log('searchAddress failed', {e, parsedZipCode})
		})
}

const searchMainAddressByZipCode = (values, setFieldState, setInputValue) => {

	searchZipCode(values.address?.cep)
		.then(data => {
			if (data)
			setInputValue({
				'address.street' : data.logradouro,
				'address.neighborhood' : data.bairro,
				'address.city' : data.localidade,
				'address.state' : data.uf,
				'address.complement' : data.complemento,
			})
		})

}

const searchMailingAddressByZipCode = (values, setFieldState, setInputValue) => {

	searchZipCode(values.mailing?.address?.cep)
		.then(data => {
			if (data)
			setInputValue({
				'mailing.address.street' : data.logradouro,
				'mailing.address.neighborhood' : data.bairro,
				'mailing.address.city' : data.localidade,
				'mailing.address.state' : data.uf,
				'mailing.address.complement' : data.complemento,
			})
		})

}

const validatePepEndDate = (value, props, values) => {

	const {
		initialDate=null,
		endDate=null,
	} = values?.pep || {}

	if (initialDate && endDate) {
		return moment(initialDate, 'DD MM YYYY')
			.isBefore(
				moment(endDate, 'DD MM YYYY'),
				'year'
			)
	}

	return isValidDate(value, props)
}

const requiredIfNotPep = (values, form) => {
	if (requiredIfPF(values, form) )
		return true
	return false
}

const requireOneOfPatrimonies = (values, form) => {

	if (!requiredIfPF(values, form))
		return false

	// console.log('requireOneOfPatrimonies', values)
	//
	// console.log({
	// 	realEstatePatrimony : values?.client?.realEstatePatrimony,
	// 	movablePatrimony : values?.client?.movablePatrimony,
	// 	financialInvestmentsPatrimony : values?.client?.financialInvestmentsPatrimony,
	// })
	//
	return parseMoney(values?.client?.realEstatePatrimony) == 0
		&& parseMoney(values?.client?.movablePatrimony) == 0
		&& parseMoney(values?.client?.financialInvestmentsPatrimony) == 0
}

const requireSumOfPatrimonies = (values, form) => {

	if (!requiredIfPF(values, form))
		return false

	// console.log('requireOneOfPatrimonies', values)
	//
	// console.log({
	// 	realEstatePatrimony : values?.client?.realEstatePatrimony,
	// 	movablePatrimony : values?.client?.movablePatrimony,
	// 	financialInvestmentsPatrimony : values?.client?.financialInvestmentsPatrimony,
	// })
	//
	const totalPatrimonies =
		parseMoney(values?.client?.realEstatePatrimony) +
		parseMoney(values?.client?.movablePatrimony) +
		parseMoney(values?.client?.financialInvestmentsPatrimony)
	return totalPatrimonies < 5000 //? true : "A soma dos patrimônios deve ser igual ou maior que R$ 5.000"
}

const requireIfNotNeedSalary = (values, form) => {
	if (!requiredIfPF(values, form))
		return false

	return !requireIfNeedSalary(values, form)
}

const needSalary = (professionalType) => {
	return !([
		professionalTypeEnum.CAPITALIST,
		professionalTypeEnum.HOUSEKEEPER,
		professionalTypeEnum.STUDENT
	].includes(parseInt(professionalType)))
}

const requireIfNeedSalary = (values, form) => {

	if (!requiredIfPF(values, form))
		return false

	const professionalType = values?.professionalInformation?.professionalType
	return needSalary(professionalType) ? true : false
}

const requireIfOtherIncome = (values, form) => {

	if (!requiredIfPF(values, form))
		return false

	return parseMoney(values?.client?.otherIncome) > 0
}

const requiredIfNotWelfare = (values, form) => {
	const welfare = values?.client?.welfare=='1'
	return !welfare
}

const RegistrationFormInvestorData = [

    {id:'titleInvestorData', caption:'Informações pessoais', type:'title'},

    // {id:'client.testfield',
	// 	caption:'Select 2',
	// 	listId:'Country',
	// 	type:'select2multi',
	// 	//options:riskRrofileOptions,
	// 	required:true,
	// 	col:6
	// },

    {
    	id:'client.welfare',
		caption:'Somente Previdência?',
		type:'radiogroup',
		options:yesNoOptions,
        onChange : (values, setFieldState) => {
			//const value =  values?.client?.welfare
			//setFieldState('client.welfare', 'visible', !Boolean(values?.client?.id))
        },

		required:true,
		col:6,
		inline:true,
	},


    {id:'client.document',
		caption:'CPF/CNPJ',
		type:'cpfcnpj',
		//validation:isValidCpfCnpj,
		required:true,
		row:true,
		col:6
	},

    {
    	id:'renewStatus.expired_date',
		caption:'Renovação cadastral',
		rComponent: {
    		component : BadgeRenew,
			dataPath : 'renewStatus',
		},
		type:'date',
		required:false,
		disabled:true,
		col:6,
	},
    // {id:'client.testfield2',
	// 	caption:'Select 2.2',
	// 	type:'select2',
	// 	options:riskRrofileOptions,
	// 	required:true,
	// 	col:6
	// },

    {id:'client.name', caption:'Nome do titular', type:'text', required:true, col:6, row:true},
    {id:'client.socialName', caption:'Nome social', type:'text', required:false, col:6},

    {id:'client.email', caption:'Email do titular', type:'email', required:true, col:6},
    {id:'client.birthDate',
		caption:'Data de nascimento',
		type:'date',
		required:true,
		rComponent: {
    		component : BadgeIncapable,
			dataPath : 'client.birthDate',
		},
		col:6
	},
    {id:'client.gender', defaultValue:'masculino', caption:'Sexo', type:'radiogroup', requiredMethod:requiredIfPF, optionsCol:12, options:genreOptions, col:6},

    {id:'client.phoneNumber', caption:'Telefone', type:'phone', required:true, col:6, row:true},
    {id:'client.additionalPhone', caption:'Telefone Adicional', type:'phone', required:false, col:6},

    {id:'client.motherName', caption:'Nome da mãe', type:'text', requiredMethod:requiredIfPF, col:6},
    {id:'client.fatherName', caption:'Nome do pai', type:'text', requiredMethod:requiredIfPF, col:6},

    {id:'client.maritalStatus',
		caption:'Estado Civil',
		type:'select2',
		requiredMethod:requiredIfPF,
		options:maritalStatusOptions,
		col:6,
        onChange : (values, setFieldState) => {
    		const value = values?.client?.maritalStatus
            setFieldState('client.spouseName', 'visible', value=='2')
            setFieldState('client.spouseCpf', 'visible', value=='2')
        }
    },
    {id:'client.spouseName', caption:'Nome do cônjugue', type:'text', required:true, col:6, row:true},
    {id:'client.spouseCpf', caption:'CPF do cônjugue', type:'cpf', required:true, col:6},

    {id:'client.personColor',
		caption:'Etnia',
		type:'select2',
		required:true,
		noEmptyOption:true,
		options:ethnicityOptions,
		col:6,
		row:true
	},

    {id:'client.naturality',
		caption:'Naturalidade (cidade de nasc.)',
		type:'text',
		requiredMethod:requiredIfPF,
		col:6
	},

    {id:'client.nacionality',
		caption:'Nacionalidade',
		listId:'Country',
		type:'select2',
		requiredMethod:requiredIfPF,
		options:countryOptions,
		col:6
	},

    {id:'client.country',
		caption:'País de Nascimento',
		type:'select2',
		listId:'Country',
		requiredMethod:requiredIfPF,
		options:nationalityOptions,
		col:6
	},

    {id:'client.civilCapacity',
		caption:'Capacidade civil',
		type:'radiogroup',
		required:true,
		options:civilCapacityOptions,
		row:true, col:6,
        onChange : (values, setFieldState) => {
            const civilCapacity = values?.client?.civilCapacity
            // const haveWorked = values?.client?.haveWorked=='1'
            // const haveRelation = values?.client?.haveRelation=='1'
            // const haveFatca = values?.client?.haveFatca=='1'
			//const welfare =  values?.client?.welfare=='1'

			//console.log('client.civilCapacity', value)
            setFieldState(
                [
                    'titleResponsible',
                    'client.responsible.name',
                    'client.responsible.document',
                    'client.responsible.relationType',
                    'client.responsibleAuthorization',
                    'client.responsible.documentPhoto',
                    'titleRepProc',

                ],
                'visible',
                civilCapacity!='1'
            )
            setFieldState(
                [
                    'client.responsible.incapacityPhoto',
                ],
                'visible',
                civilCapacity=='3'
            )

        }

    },

    {
    	id:'titleResponsible',
		caption:'Informações do representante/procurador',
		type:'title'
	},
    {id:'client.responsible.name',
		caption:'Nome do representante/procurador',
		type:'text',
		requiredMethod:requiredIfNotWelfare,
		required :true,
		col:12,
		row:true
	},

    {id:'client.responsible.document',
		caption:'CPF/CNPJ',
		type:'cpfcnpj',
		requiredMethod:requiredIfNotWelfare,
		col:12
	},
    {id:'client.responsible.relationType',
		caption:'Vínculo com o investidor',
		type:'text',
		requiredMethod:requiredIfNotWelfare,
		col:12
	},
    {
    	id:'client.responsibleAuthorization',
		caption:'Autoriza a transmissão de ordens por representante/procurador?',
		type:'radiogroup',
		options:yesNoOptions,
		requiredMethod:requiredIfNotWelfare,
		col:12,
		inline:true
	},

/*
    {id:'coHolderTitle',
        caption:'Possui co-titular?',
        type:'title',
    },

    {id:'coHolder.document',
        caption:'CPF do co-titular',
        type:'cpf',
        required:false,
        onChange : (values, setFieldState) => {
            const value = values?.coHolder?.document
            setFieldState(
                [
                    'coHolder.name',
                    'coHolder.birthDate',
                    'coHolder.phoneNumber',
                    'coHolder.email',
                ],
                'visible',
                value ? true : false
            )
        }
    },

    {id:'coHolder.name', caption:'Nome', type:'text', required:true, col:8, row:true},
    {id:'coHolder.birthDate', caption:'Data de nascimento', type:'date', required:true, col:8, row:true},
    {id:'coHolder.phoneNumber', caption:'Celular', type:'phone', required:true, col:8, row:true},
    {id:'coHolder.email', caption:'E-mail', type:'email', required:true, col:8, row:true},
*/
    {id:'titleProfessionalInformation',
        caption:'Informações profissionais',
        type:'title',
    },

    {id:'professionalInformation.professionalType',
        caption:'Profissão',
        type:'radiogroup',
        requiredMethod:requiredIfPF,
        vertical:true,
        options: professionalTypeOptions,
        onChange : (values, setFieldState) => {
            const professionalType = values?.professionalInformation?.professionalType
			//alert(professionalType)

            setFieldState('professionalInformation.carrerId', 'visible', false)
            setFieldState('professionalInformation.company', 'visible', false)
            setFieldState('professionalInformation.cnpj', 'visible', false)

			if (professionalType==professionalTypeEnum.SALARIED) {
				setFieldState(
					[
						'professionalInformation.carrerId',
						'professionalInformation.company',
						'professionalInformation.cnpj',
					],
					'visible',
					true
				)
			} else  if (professionalType==professionalTypeEnum.LIBERAL) {
				setFieldState(
					[
						'professionalInformation.carrerId',
						'professionalInformation.company',
					],
					'visible',
					true
				)
			} else  if (professionalType==professionalTypeEnum.BUSINESSPERSON) {
				setFieldState(
					[
						'professionalInformation.company',
						'professionalInformation.cnpj',
					],
					'visible',
					true
				)
			}
			updatePepFunctionVisibility(values, setFieldState)

			setFieldState('client.salary', 'visible', needSalary(professionalType))
        },
    },

    {	id:'professionalInformation.carrerId',
        caption:'Ocupação profissional',
        type:'select2',
		listId : 'ProfessionalOccupation',
        options:apiMockedOptions,
        requiredMethod:requiredIfNotPep,
        row:true,
        col:6,
    },

    {id:'professionalInformation.pepFunctionId',
		caption:'Cargo ou Função (PEP)',
		type:'select2',
		listId : 'Function',
		required:true,
        onChange : (values, setFieldState, setInputValue) => {
    		updatePepFunctionVisibility(values, setFieldState)
			setInputValue('pep.functionId', values?.professionalInformation?.pepFunctionId)
		},
		col:12
	},

    {	id:'professionalInformation.company',
        caption:'Empresa onde trabalha',
        type:'text',
        required:true,
        row:true,
        col:6,
    },

    {	id:'professionalInformation.cnpj',
        caption:'CNPJ da empresa',
        type:'cnpj',
        options:apiMockedOptions,
        required:true,
        row:true,
        col:6,
    },


    // {id:'titleClientStatus',
    //     caption:'Status do cliente',
    //     type:'title',
    // },

    // {id:'client.status',
	// 	caption:'Status do cliente',
	// 	type:'select2',
	// 	required:true,
	// 	noEmptyOption:true,
	// 	col:6,
	// 	options: [
	// 		{id:'1',  caption:'Ativo'},
	// 		{id:'0',  caption:'Inativo'},
	// 	]
	// },


]

const RegistrationFormMailing = [

    {	id:'address.title',
		caption:'Informações residenciais',
		type:'title',
		col:12,
	},
    {	id:'address.cep',
		caption:'CEP',
		type:'cep',
		requiredMethod:requiredIfPF,
		onChangeIntent:searchMainAddressByZipCode,
		//validation:isValidCep,
		col:4,
	},
    {id:'address.street', caption:'Logradouro', type:'text', requiredMethod:requiredIfPF, col:8, row:true, allowNumbers : true,},
    {id:'address.number', caption:'Número', type:'text', requiredMethod:requiredIfPF, col:4, maxLength:40, allowNumbers : true,},
    {id:'address.neighborhood', caption:'Bairro', type:'text', requiredMethod:requiredIfPF, col:4, allowNumbers : true,},

    {id:'address.city', caption:'Cidade', type:'text', requiredMethod:requiredIfPF, col:4, allowNumbers : true,},
    {id:'address.state', caption:'Estado', type:'select2', options:statesBrOptions, requiredMethod:requiredIfPF, col:4},
    {id:'address.complement', caption:'Complemento', type:'text', maxLength:255, required:false, col:12, allowNumbers : true,},

    {id:'titleResponsible2', type:'title', caption:'Dados para correspondência', xsubtitle:''},

    {id:'mailing.options', caption:'Informe os dados que deseja receber', type:'checkgroup', required:true, vertical:true,
        options: [
            {id:'receiveQuotaholderExtract', caption:'Extrato de cotista'},
            {id:'receiveReportIncome', caption:'Informes de rendimentos'},
            {id:'allowDistributorSendEmail', caption:'Aceito receber por e-mail extratos e demais informações'},
        ]
    },

    {id:'mailing.mailType', caption:'Endereço de recebimento', type:'radiogroup', required:true, vertical:true,
        options: [
            {id:'1', caption:'Endereço residencial'},
            {id:'2', caption:'Endereço opcional'},
        ],
        onChange : (values, setFieldState) => {
    		const value = values?.mailing?.mailType
            setFieldState(
                [
                    'mailing.title',
                    'mailing.address.cep',
                    'mailing.address.street',
                    'mailing.address.number',
                    'mailing.address.neighborhood',
                    'mailing.address.city',
                    'mailing.address.state',
                    'mailing.address.complement',
                ],
                'visible',
                value==2
            )
        }
    },

    {id:'mailing.title', caption:'Endereço opcional', type:'title', col:12},
    {
    	id:'mailing.address.cep',
		caption:'CEP',
		type:'cep',
		required:true,
		onChangeIntent:searchMailingAddressByZipCode,
		col:4
	},
    {id:'mailing.address.street', caption:'Logradouro', type:'text', required:true, col:8, row:true, allowNumbers : true,},
    {id:'mailing.address.number', caption:'Número', type:'text', required:true, col:4, maxLength:40, allowNumbers : true,},
    {id:'mailing.address.neighborhood', caption:'Bairro', type:'text', required:true, col:4, allowNumbers : true,},

    {id:'mailing.address.city', caption:'Cidade', type:'text', required:true, col:4, allowNumbers : true,},
    {id:'mailing.address.state', caption:'Estado', type:'select2', options:statesBrOptions, required:true, col:4},
    {id:'mailing.address.complement', caption:'Complemento', maxLength:255, type:'text', required:false, col:12, allowNumbers : true,},

]

const RegistrationFormFinancialData = [
    {	id:'titleSalary',
		type:'title',
		caption:'Renda',
		xsubtitle:''
	},
    {	id:'client.salary',
		caption:'Renda',
		type:'money',
		requiredMethod:requireIfNeedSalary,
	},

    {	id:'client.otherIncome',
		caption:'Outros rendimentos',
		type:'money',
		requiredMethod:requireIfNotNeedSalary,
        onChange : (values, setFieldState) => {
            const value = parseMoney(values?.client?.otherIncome)
			//console.log(value)
            setFieldState('client.otherIncomeDescription', 'visible', value > 0 )
        }
	},

    {	id:'client.otherIncomeDescription',
		caption:'Descrição de outros rendimentos',
		type:'text',
		requiredMethod:requireIfOtherIncome,
	},

    {	id:'titleFinancial',
		type:'title',
		subtitle:'A soma dos patrimônios deve ser igual ou maior que R$ 5.000,00',
		caption:'Situação financeira/patrimonial',
		xsubtitle:''
	},

    {	id:'client.realEstatePatrimony',
		caption:'Bens móveis (Carro, barco, etc...)',
		type:'money',
		requiredMethod : requireSumOfPatrimonies,
        onChange : sumPatrimonies
	},
    {	id:'client.movablePatrimony',
		caption:'Bens imóveis (Apartamento, casa, etc...)',
		type:'money',
		requiredMethod : requireSumOfPatrimonies,
        onChange : sumPatrimonies
	},
    {	id:'client.financialInvestmentsPatrimony',
		caption:'Aplicações financeiras (Ações, fundos, poupança)',
		type:'money',
		requiredMethod : requireSumOfPatrimonies,
        onChange : sumPatrimonies
	},

    {	id:'titleFinancialTotal',
		type:'title',
		caption:'Patrimônio total',
		xsubtitle:''
	},

    {	id:'client.investorFinancialSituation',
		caption:'Total do patrimônio',
		requiredMethod : requireSumOfPatrimonies,
		type:'money',
		readOnly:true
	},
    {	id:'client.patrimonyUpdateDate',
		caption:'Data atual referente ao patrimônio',
		type:'date',
		requiredMethod:requiredIfPF
	},
]

const RegistrationFormProfessionalInformation = [


]

const RegistrationFormBankInformation = [
    {id:'titleBankInformation', type:'title', caption:'Informações bancárias', xsubtitle:''},

    {id:'bankInformation.accounts.0.isBradescoAccount', caption:'É correntista do Banco Bradesco?',
		type:'radiogroup',
		options:yesNoOptions,
		requiredMethod:requiredIfPF,
		col:5,
		inline:true,
        onChange : (values, setFieldState) => {
			const accounts =  values?.bankInformation?.accounts
            const value = accounts?.length && accounts[0]?.isBradescoAccount

            if (value) {
                if (value=='1') {
                    setFieldState('bankInformation.accounts.0.debitAuthorization', 'visible', true)
                    setFieldState('bankInformation.accounts.0.bankId', 'visible', false)
                } else if (value=='0') {
                    setFieldState('bankInformation.accounts.0.debitAuthorization', 'visible', false)
                    setFieldState('bankInformation.accounts.0.bankId', 'visible', true)
                }
            }


        }
    },
    {id:'bankInformation.accounts.0.debitAuthorization', caption:'Autoriza débito em conta?',  type:'radiogroup', options:yesNoOptions, requiredMethod:requiredIfPF, col:7, inline:true},
    {id:'bankInformation.accounts.0.bankId',
		caption:'Banco',
		type:'select2',
		listId:'Bank',
		requiredMethod:requiredIfPF,
		col:7
	},

    {id:'bankInformation.accounts.0.agency', caption:'Agência', type:'number', maxLength:10, requiredMethod:requiredIfPF, col:4, row:true},
    {id:'bankInformation.accounts.0.accountNumber', caption:'Conta', type:'number', maxLength:10, requiredMethod:requiredIfPF, col:4},
    {id:'bankInformation.accounts.0.digit', caption:'Dígito', type:'number', maxLength:1, requiredMethod:requiredIfPF, col:4},

    {id:'bankInformation.accounts.0.haveCoHolders', caption:'Tem algum cotitular da conta?',  type:'radiogroup', options:yesNoOptions, requiredMethod:requiredIfPF, col:4, row:true, inline:true,
        onChange : (values, setFieldState) => {
			const accounts =  values?.bankInformation?.accounts
             const value = accounts?.length>0
				 ? accounts[0].haveCoHolders
				 : undefined

			//console.log('bankInformation.accounts.0.haveCoHolders', value)
			setFieldState('titleBankInformationCoTitular', 'visible', value=='1')
			setFieldState('bankInformation.accounts.0.coHolders.0.name', 'visible', value=='1')
			setFieldState('bankInformation.accounts.0.coHolders.0.cpf', 'visible', value=='1')
			setFieldState('bankInformation.accounts.0.coHolders.1.name', 'visible', value=='1')
			setFieldState('bankInformation.accounts.0.coHolders.1.cpf', 'visible', value=='1')


        }
    },
    {id:'titleBankInformationCoTitular', type:'title', caption:'Co-titulares da conta', row:true},
    {id:'bankInformation.accounts.0.coHolders.0.name', caption:'Nome', type:'text', required:true, col:6},
    {id:'bankInformation.accounts.0.coHolders.0.cpf', caption:'CPF', type:'cpf', required:true, col:6},

    {id:'bankInformation.accounts.0.coHolders.1.name', caption:'Nome', type:'text', required:false, col:6},
    {id:'bankInformation.accounts.0.coHolders.1.cpf', caption:'CPF', type:'cpf', required:false, col:6},

    {id:'titleBankInformationInfo', type:'title', caption:'Conta bancária adicional', xsubtitle:''},
    {id:'hasAdditionalBankAccount', caption:'Possui conta adicional?',  type:'radiogroup', options:yesNoOptions, required:true, col:12, inline:true,
        onChange : (values, setFieldState) => {
            const value=values?.hasAdditionalBankAccount
			const accounts =  values?.bankInformation?.accounts
            const isBradescoAccount = accounts?.length && accounts[1]?.isBradescoAccount

            setFieldState(
                [
                    'bankInformation.accounts.1.isBradescoAccount',
                    'bankInformation.accounts.1.agency',
                    'bankInformation.accounts.1.accountNumber',
                    'bankInformation.accounts.1.digit',
                    'bankInformation.accounts.1.haveCoHolders',
                    'titleBankInformationAnother',
                ],
                'visible',
                value=='1'
            )
			setFieldState(
				'bankInformation.accounts.1.debitAuthorization',
				'visible',
				value==1 && isBradescoAccount=='1'
			)
			setFieldState(
				'bankInformation.accounts.1.bankId',
				'visible',
				value==1 && isBradescoAccount=='0'
			)

			//const accounts =  values?.bankInformation?.accounts
            const haveCoHolders = accounts?.length && accounts[1]?.haveCoHolders
			//alert('hasAdditionalBankAccount'+value)
			setFieldState('titleBankInformationCoTitular2', 'visible', value=='1' && haveCoHolders=='1')
			setFieldState('bankInformation.accounts.1.coHolders.0.name', 'visible', value=='1' && haveCoHolders=='1')
			setFieldState('bankInformation.accounts.1.coHolders.0.cpf', 'visible', value=='1' && haveCoHolders=='1')
			setFieldState('bankInformation.accounts.1.coHolders.1.name', 'visible', value=='1' && haveCoHolders=='1')
			setFieldState('bankInformation.accounts.1.coHolders.1.cpf', 'visible', value=='1' && haveCoHolders=='1')

        }
    },

    // {id:'titleBankInformationAnother', type:'title', caption:'Informações da conta adicional', xsubtitle:''},

    {id:'bankInformation.accounts.1.isBradescoAccount', caption:'É correntista do Banco Bradesco?',  type:'radiogroup', options:yesNoOptions, required:true, col:5, inline:true,
        onChange : (values, setFieldState) => {
			const accounts =  values?.bankInformation?.accounts
            const value = accounts?.length && accounts[1]?.isBradescoAccount
            const hasAdditionalBankAccount = values.hasAdditionalBankAccount=='1'
            if (value && hasAdditionalBankAccount) {
                if (value=='1') {
                    setFieldState('bankInformation.accounts.1.debitAuthorization', 'visible', true)
                    setFieldState('bankInformation.accounts.1.bankId', 'visible', false)
                } else if (value=='0') {
                    setFieldState('bankInformation.accounts.1.debitAuthorization', 'visible', false)
                    setFieldState('bankInformation.accounts.1.bankId', 'visible', true)
                }

            }
        }
    },

    {id:'bankInformation.accounts.1.debitAuthorization', caption:'Autoriza débito em conta?',  type:'radiogroup', options:yesNoOptions, required:true, col:7, inline:true},
    {id:'bankInformation.accounts.1.bankId',
		caption:'Banco',
		type:'select2',
		listId:'Bank',
		requiredMethod:requiredIfPF,
		col:7
	},

    {id:'bankInformation.accounts.1.agency', caption:'Agência', type:'number', maxLength:10, required:true, col:4, row:true},
    {id:'bankInformation.accounts.1.accountNumber', caption:'Conta', type:'number', maxLength:10, required:true, col:4},
    {id:'bankInformation.accounts.1.digit', caption:'Dígito', type:'number', maxLength:1, required:true, col:4},

    {id:'bankInformation.accounts.1.haveCoHolders', caption:'Tem algum cotitular?',  type:'radiogroup', options:yesNoOptions, required:true, col:4, inline:true,
        onChange : (values, setFieldState) => {
			const accounts =  values?.bankInformation?.accounts
            const value = accounts?.length>1
				 ? accounts[1].haveCoHolders
				 : undefined
			setFieldState('titleBankInformationCoTitular2', 'visible', value=='1')
			setFieldState('bankInformation.accounts.1.coHolders.0.name', 'visible', value=='1')
			setFieldState('bankInformation.accounts.1.coHolders.0.cpf', 'visible', value=='1')
			setFieldState('bankInformation.accounts.1.coHolders.1.name', 'visible', value=='1')
			setFieldState('bankInformation.accounts.1.coHolders.1.cpf', 'visible', value=='1')


        }
    },
    {id:'titleBankInformationCoTitular2', type:'title', caption:'Co-titulares', xsubtitle:'', row:true},
    {id:'bankInformation.accounts.1.coHolders.0.name', caption:'Nome', type:'text', required:true, col:6},
    {id:'bankInformation.accounts.1.coHolders.0.cpf', caption:'CPF', type:'cpf', required:true, col:6},
    {id:'bankInformation.accounts.1.coHolders.1.name', caption:'Nome', type:'text', required:false, col:6},
    {id:'bankInformation.accounts.1.coHolders.1.cpf', caption:'CPF', type:'cpf', required:false, col:6},

]

const RegistrationFormPepData = [

    {id:'pepTitle',
        caption:'PEP',
        type:'title',
    },

    {id:'client.haveWorked',
        caption : 'Pessoa Politicamente Exposta?',
        info    : 'Exerce ou exerceu nos últimos cinco anos, no Brasil ou em territórios estrangeiros, algum cargo, emprego ou função pública relevante?',
        type    : 'radiogroup',
        options:yesNoOptions,
        required:true,
        col:12,
        inline:true,
		onChange : (values, setFieldState) => {
            const haveWorked = values.client?.haveWorked=='1'
			//alert(values.client?.haveWorked + '' + values.client?.civilCapacity)

            setFieldState(
                [
                    'pep.functionId',
                    'pep.initialDate',
                    'pep.endDate',
                    'pep.company',
                    'pep.cnpj',
                ],
                'visible',
                haveWorked
            )
			updatePepFunctionVisibility(values, setFieldState)
        }
    },

    {id:'pep.functionId',
		caption:'Cargo ou Função',
		type:'select2',
		listId : 'Function',
        onChange : (values, setFieldState, setInputValue) => {
			setInputValue('professionalInformation.pepFunctionId', values?.pep?.functionId)
		},
		required:true,
		col:12
	},
    {id:'pep.initialDate',
		caption:'Data Início do Exercício',
		type:'date',
		required:true,
		col:6,
        onChange : updatePepFunctionVisibility,
		row:true
	},
    {id:'pep.endDate',
		caption:'Data Fim do Exercício',
		type:'date',
		validation:validatePepEndDate,
        onChange : updatePepFunctionVisibility,
		required:true,
		col:6
	},

	{id:'pep.company', caption:'Empresa/Orgão Público', type:'text', required:true, col:6},
    {id:'pep.cnpj', caption:'CNPJ', type:'cnpj',  required:true, col:6},

    {id:'client.haveRelation',
        caption : 'Relacionamento',
        info    : 'Possui relacionamento/ligação com Pessoas Expostas Politicamente?',
        type    : 'radiogroup', options:yesNoOptions, required:true, col:12, inline:true,
        onChange : (values, setFieldState) => {

            const haveRelation=values.client?.haveRelation
            const relationTypeId = values.pep?.relationTypeId
            setFieldState(
                [
                    'pep.nameOfRelation',
                    'pep.documentRelation',
                    'pep.functionRelationId',
                    'pep.relationTypeId',
                ],
                'visible',
                haveRelation=='1'
            )

			//alert(values.client?.haveWorked + '' + values.client?.civilCapacity)

            setFieldState(
            	'pep.parentId',
                'visible',
                haveRelation==1 && relationTypeId==1
            )
            setFieldState(
            	'pep.relationId',
                'visible',
                haveRelation==1 && relationTypeId==2
            )
        }
    },

    {id:'pep.nameOfRelation', caption:'Nome do Relacionado', type:'text', required:true, col:6},
    {id:'pep.documentRelation', caption:'CPF', type:'cpf',  required:true, col:6},
    {id:'pep.functionRelationId',
		caption:'Cargo ou Função',
		type:'select2',
		required:true,
		listId : 'Function',
		col:12,
	},
    {id:'pep.relationTypeId',
		caption:'RELACIONAMENTO/LIGAÇÃO',
		type:'select2',
		options:relationConnectionOptions,
		required:true,
		row:true,
		col:6,
		onChange : (values, setFieldState) => {
            const haveRelation = values.client?.haveRelation
            const relationTypeId = values.pep?.relationTypeId
			//alert(values.client?.haveWorked + '' + values.client?.civilCapacity)

            setFieldState(
            	'pep.parentId',
                'visible',
                haveRelation==1 && relationTypeId==1
            )
            setFieldState(
            	'pep.relationId',
                'visible',
                haveRelation==1 && relationTypeId==2
            )
    	}
	},

    {id:'pep.parentId',
		caption:'RELACIONAMENTO',
		type:'select2',
		listId:'FamilyRelation',
		required:true,
		col:6
	},

    {id:'pep.relationId',
		caption:'RELACIONAMENTO',
		type:'select2',
		listId:'CloseRelation',
		required:true,
		col:6
	},



]

const RegistrationFormFatca = [

    {id:'titleFatcaMain',
        caption:'FATCA',
        type:'title',
    },

    {id:'client.haveFatca',
        caption : 'Possui Informações Fiscais?',
        info    : 'Possui alguma nacionalidade além da declarada, alguma residência fiscal além da Brasileira ou visto de residência permanente em outros países, como por exemplo Green Card?',
        type    : 'radiogroup',
        options:yesNoOptions,
        required:true,
        col:12,
        inline:true,
        onChange : (values, setFieldState) => {
            const haveFatca = values?.client?.haveFatca
            const hasRenouncedNationality = values?.fatca?.hasRenouncedNationality
    		const requestType = values?.fatca?.requestType
            const haveFatcaVersion1 = values?.client?.haveFatcaVersion1
            const fatcaTitleVersion1 = values?.client?.fatcaTitleVersion1
    		const notFiscalResidentOptions = values?.fatca?.notFiscalResidentOptions
    		const needFormFatcaEUA = values?.client?.needFormFatcaEUA
    		const haveRenews = values?.client?.haveRenews
    		const hasOtherFiscalRes = values?.fatca?.hasOtherFiscalRes

            setFieldState(
				['fatca.fatcaFormLossCitizenship'],
                'visible',
				(haveFatca==='1' && requestType==='fatca' && notFiscalResidentOptions.includes('isResignedCln'))
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
            setFieldState(
				['fatca.fatcaFormResignedCitizenshipEua'],
                'visible',
				(haveFatca==='1' && requestType==='fatca' && notFiscalResidentOptions.includes('isResignedCitizen'))
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
            setFieldState(
				'fatca.fatcaFormResignedGreenCard',
                'visible',
				(haveFatca==='1' && requestType==='fatca' && notFiscalResidentOptions.includes('isWithoutGc'))
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
            setFieldState(
				['fatca.fatcaFormEua', 'titleFatcaDoc'],
                'visible',
				(haveFatca==='1' && requestType==='fatca')
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )


            setFieldState(
                [
                    'fatca.requestType',
                	'newFatcaFiscalResTitle',
                ],
                'visible',
                haveFatca=='1'
            )

            setFieldState(
                [
                    'client.fatcaTitleVersion1',
                ],
                'visible',
                haveFatcaVersion1==1
            )

            setFieldState(
                [
                    'titleTaxInformation',
                    'fatca.options',
                    'fatcaTitle',
                    'fatca.answers.0.nif',
                    'fatca.answers.0.countryId',
                    'fatca.answers.0.options',
                    'fatca.answers.0.type',
                    'fatcaTitleOptions',
                    'fatca.hasRenouncedNationality',
                ],
                'visible',
                haveFatcaVersion1=='1' && fatcaTitleVersion1==1
            )

            setFieldState(
                [
                    'fatca.hasOtherFiscalRes',
                    'fatca.otherCountry',
                ],
                'visible',
                haveFatca=='1' && requestType=='crs'
            )

            setFieldState(
                [
                    'fatca.fiscalCountry',
                    'fatca.nifNumber',
                ],
                'visible',
                haveFatca=='1' && requestType=='crs' && hasOtherFiscalRes=='1'
            )

            setFieldState(
                [
					'fatca.bornEua',
					'fatca.isNorthAmerican',
					'fatca.isPermanentEua',
					'fatca.isFiscalEua',
					'fatca.refuseSsn',
					'fatca.ssn',
					'fatca.notFiscalResidentOptions',
					'newFatcaNotFiscalResTitle',
                ],
                'visible',
                haveFatca=='1' && requestType=='fatca'
            )

            setFieldState(
                [
                    'fatca.renouncedNationalities',
                ],
                'visible',
                haveFatca=='1' && hasRenouncedNationality=='1' && haveFatcaVersion1=='1' && fatcaTitleVersion1==1
            )

            setFieldState(
                [
                    'fatca.renouncedDocument',
                    'fatca.titleRenounced',
                ],
                'visible',
                hasRenouncedNationality=='1' && haveFatcaVersion1=='1'
            )




        }
    },

	////////// NEW FATCA

    {id:'newFatcaFiscalResTitle',
        caption:'Informações fiscais',
        type:'title',
    },
    {id:'fatca.requestType',
		caption:'Tipo de solicitação',
		type:'select2',
		required:false,
		options:fatcaRequestTypes,
		noEmptyOption:true,
		col:6,
        onChange : (values, setFieldState) => {
    		const requestType = values?.fatca?.requestType
            const haveFatca = values?.client?.haveFatca
    		const hasOtherFiscalRes = values?.fatca?.hasOtherFiscalRes
    		const haveRenews = values?.client?.haveRenews
    		const needFormFatcaEUA = values?.client?.needFormFatcaEUA

            setFieldState(
                [
                    'fatca.hasOtherFiscalRes',
                    'fatca.otherCountry',
                ],
                'visible',
                haveFatca=='1' && requestType==='crs'
            )
            setFieldState(
                [
                    'fatca.fiscalCountry',
                    'fatca.nifNumber',
                ],
                'visible',
                haveFatca=='1' && requestType==='crs' && hasOtherFiscalRes==='1'
            )
            setFieldState(
                [
					'fatca.bornEua',
					'fatca.isNorthAmerican',
					'fatca.isPermanentEua',
					'fatca.isFiscalEua',
					'fatca.refuseSsn',
					'fatca.ssn',
					'fatca.notFiscalResidentOptions',
					'newFatcaNotFiscalResTitle',
				],
                'visible',
                haveFatca=='1' && requestType=='fatca'
            )

            setFieldState(
				['fatca.fatcaFormEua', 'titleFatcaDoc'],
                'visible',
				(haveFatca==='1' && requestType==='fatca')
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )

        }


    },

    {id:'fatca.otherCountry',
		caption:'País de outra nacionalidade',
		listId:'Country',
		type:'select2',
		requiredMethod:requiredIfPF,
		options:countryOptions,
		row:true,
		col:6
	},

    {
    	id:'fatca.hasOtherFiscalRes',
		caption:'Possui residência fiscal diferente da brasileira?',
		type:'radiogroup',
		options:yesNoOptions,
		required:true,
		row:true,
		col:6, inline:true,
        onChange : (values, setFieldState) => {
            const haveFatca = values?.client?.haveFatca
    		const requestType = values?.fatca?.requestType
    		const hasOtherFiscalRes = values?.fatca?.hasOtherFiscalRes
            setFieldState(
                [
                    'fatca.fiscalCountry',
                    'fatca.nifNumber',
                ],
                'visible',
                haveFatca=='1' && requestType==='crs' && hasOtherFiscalRes==='1'
            )
        }
	},

    {id:'fatca.fiscalCountry',
		caption:'País de Residência Fiscal',
		listId:'Country',
		type:'select2',
		requiredMethod:requiredIfPF,
		options:countryOptions,
		row:true,
		col:6
	},

    {id:'fatca.nifNumber',
		caption:'Nº de Identif. Fiscal (NIF) / TIN ',
		type:'text',
		//requiredMethod:requiredIfPF,
		allowNumbers : true,
		requiredMethod:requiredIfPF,
		col:6
	},

	//FATCA - estados unidos
    {
    	id:'fatca.bornEua',
		caption:'Nasceu nos Estados Unidos ou em seus territórios?',
		type:'radiogroup',
		options:yesNoOptions,
		required:true,
		row:true,
		col:6, inline:true,
	},
    {
    	id:'fatca.isNorthAmerican',
		caption:'Possui nacionalidade ou cidadania norte-americana?',
		type:'radiogroup',
		options:yesNoOptions,
		required:true,
		col:6, inline:true,
	},
    {
    	id:'fatca.isPermanentEua',
		caption:'Possui visto de residência permanente nos EUA?',
		type:'radiogroup',
		options:yesNoOptions,
		required:true,
		col:6, inline:true,
	},
    {
    	id:'fatca.isFiscalEua',
		caption:'É residente fiscal nos Estados Unidos?',
		type:'radiogroup',
		options:yesNoOptions,
		required:true,
		col:6, inline:true,
	},

    {
    	id:'fatca.refuseSsn',
		caption:'Me recuso a fornecer o Número de Identificação Fisca',
		type:'radiogroup',
		options:yesNoOptions,
		required:true,
		col:6, inline:true,
        onChange : (values, setFieldState) => {
            const haveFatca = values?.client?.haveFatca
    		const requestType = values?.fatca?.requestType
    		const hasOtherFiscalRes = values?.fatca?.hasOtherFiscalRes
    		const refuseSsn = values?.fatca?.refuseSsn

			// alert(refuseSsn)
            setFieldState(
                [
                    'fatca.ssn',
                ],
                'visible',
                haveFatca=='1' && requestType==='fatca' && (refuseSsn=='0'|| !refuseSsn)
            )
        }
	},
    {
    	id:'fatca.ssn',
		caption:'Nº de Identificação Fiscal / SSN',
		type:'text',
		allowNumbers : true,
		requiredMethod:requiredIfPF,
		col:6
	},

    {id:'newFatcaNotFiscalResTitle',
        caption:'Opções',
        subtitle:'Caso não se enquadre na condição de residente fiscal nos E.U.A., preencher a declaração abaixo:',
        type:'title',
    },
    {
    	id:'fatca.notFiscalResidentOptions',
		caption:'',
		type:'checkgroup',
		required:false,
		vertical:true,
        options:fatcaNotFiscalResidentOptions,
        onChange : (values, setFieldState) => {
            const haveFatca = values?.client?.haveFatca
    		const requestType = values?.fatca?.requestType
    		const notFiscalResidentOptions = values?.fatca?.notFiscalResidentOptions
    		const needFormFatcaEUA = values?.client?.needFormFatcaEUA
    		const haveRenews = values?.client?.haveRenews
            setFieldState(
				['fatca.fatcaFormLossCitizenship'],
                'visible',
				(haveFatca==='1' && requestType==='fatca' && notFiscalResidentOptions.includes('isResignedCln'))
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
            setFieldState(
				['fatca.fatcaFormResignedCitizenshipEua'],
                'visible',
				(haveFatca==='1' && requestType==='fatca' && notFiscalResidentOptions.includes('isResignedCitizen'))
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
            setFieldState(
				'fatca.fatcaFormResignedGreenCard',
                'visible',
				(haveFatca==='1' && requestType==='fatca' && notFiscalResidentOptions.includes('isWithoutGc'))
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
            setFieldState(
				['fatca.fatcaFormEua', 'titleFatcaDoc'],
                'visible',
				(haveFatca==='1' && requestType==='fatca')
				&& (!haveRenews && needFormFatcaEUA!=='renew')
            )
			//console.log('notFiscalResidentOptions', notFiscalResidentOptions)
        }
    },
	 // OLD FATCA
    {
    	id:'client.fatcaTitleVersion1',
        caption:'FATCA até 09/06/2022',
        type:'collapsibletitle',
        onChange : (values, setFieldState) => {
            const haveFatcaVersion1 = values?.client?.haveFatcaVersion1
            const fatcaTitleVersion1 = values?.client?.fatcaTitleVersion1
            const hasRenouncedNationality = values?.fatca?.hasRenouncedNationality
            setFieldState(
                [
                    'titleTaxInformation',
                    'fatca.options',
                    'fatcaTitle',
                    'fatca.answers.0.nif',
                    'fatca.answers.0.countryId',
                    'fatca.answers.0.options',
                    'fatca.answers.0.type',
                    'fatcaTitleOptions',
                    'fatca.hasRenouncedNationality',
                ],
                'visible',
                haveFatcaVersion1==1 && fatcaTitleVersion1==1
            )
            setFieldState(
                [
                    'fatca.renouncedNationalities',
                ],
                'visible',
                haveFatcaVersion1==1 && fatcaTitleVersion1==1 && hasRenouncedNationality==1
            )

    	}
    },

    // {id:'titleTaxInformation',
    //     caption:'Informações Fiscais',
    //     type:'title',
    // },

    {id:'fatca.options', caption:'Informações Fiscais', type:'checkgroup',
		required:false,
		vertical:true,
		disabled:true,
        options: [
            {id:'hasOtherNationality', caption:'Possui alguma nacionalidade além da declarada?'},
            {id:'hasOtherFiscalResidence', caption:'Possui alguma residencia fiscal diferente da brasileira?'},
            {id:'hasOtherVisa', caption:'Possui visto de residencia permanente valido em outros paises, como por examplo Green Card?'},
        ],
        // onChange : (values, setFieldState) => {
        //     const value = values?.fatca?.options
        //     setFieldState(
        //         [
        //             'De quais países houve a renúncia/abdicação?',
        //         ],
        //         'visible',
        //         value=='hasOtherVisa'
        //     )
        // }
    },


    //MULTIPLE
    // {id:'fatcaTitle',
    //     caption:'Opções de NIF',
    //     type:'title',
    // },


    {id:'fatca.answers.0.nif',
        caption:'Número de identificação fiscal (NIF)',
        type:'text',
		disabled:true,
        required:false,
        col:6,
        onChange : (values, setFieldState) => {
            const value = values?.fatca?.answers?.length && values?.fatca?.answers[0].nif
            const haveFatca = values?.client?.haveFatca
            const haveFatcaVersion1 = values?.client?.haveFatcaVersion1
            const fatcaTitleVersion1 = values?.client?.fatcaTitleVersion1

            setFieldState(
                [
                    'fatca.answers.0.options',
                ],
                'visible',
                !value && haveFatcaVersion1=='1' && fatcaTitleVersion1==1
            )
        }
    },

    {id:'fatca.answers.0.countryId',
        caption:'País',
        type:'select2',
		disabled:true,
        required:false,
		noEmptyOption:true,
        listId:'Country',
        col:6
    },

    {id:'fatca.answers.0.options', caption:'Não possui NIF?', type:'radiogroup', required:false, vertical:true,
		disabled:true,
        options: [
            {id:'isWaitingNif', caption:'Aguardando a emissão do número de identificação fiscal'},
            {id:'hasNif', caption:'Sua jurisdição não exige o NIF.'},
            {id:'dontNeedNif', caption:'É dispensado do NIF, de acordo com as regras do órgão de administração tributária no exterior.'},
        ],
    },


    {id:'fatca.answers.0.type',
		disabled:true,
        caption:'Selecione a opção de referência',
        type:'select2',
        required:false,
        options:fiscalReferenceOptions,
        col:6
    },


    // {id:'fatcaTitleOptions',
    //     caption:'Opções de NIF',
    //     type:'title',
    // },

    {id:'fatca.hasRenouncedNationality', caption:'Renunciou/Abdicou a nacionalidade?',  type:'radiogroup', options:yesNoOptions, required:false, col:12, inline:true,
		disabled:true,
        onChange : (values, setFieldState) => {
            const haveFatca = values?.client?.haveFatca
            const hasRenouncedNationality = values?.fatca?.hasRenouncedNationality
            const fatcaTitleVersion1 = values?.client?.fatcaTitleVersion1

            // const civilCapacity = values?.client?.civilCapacity

			//alert(value)
            //alert('hasFatca'+hasFatca)
            setFieldState(
                [
                    'fatca.renouncedNationalities',
                    // 'fatca.renouncedDocument',
                    'titleRenounced',
                ],
                'visible',
                haveFatca=='1' && hasRenouncedNationality=='1' && fatcaTitleVersion1==1
            )
        }
    },

    {id:'fatca.renouncedNationalities',
		disabled:true,
        caption:'De quais países houve a renúncia/abdicação?',
        type:'select2multi',
        required:false,
        listId:'Country',
        col:8
    },

]

const testForm = [
    {id:'titleInvestorData', caption:'Informações pessoais', type:'title'},
    {id:'document', caption:'CPF/CNPJ', type:'cpfcnpj', required:true, col:6},

    {id:'f1', caption:'Nome do titular', type:'text', required:true, col:6, row:true},
    {id:'f2', caption:'Nome social', type:'text', required:false, col:6},

    {id:'f3', caption:'Email do titular', type:'email', required:false, col:4},
    {id:'f4', caption:'Data de nascimento', type:'date', required:false, col:4},
    {id:'genre', caption:'Sexo', type:'radiogroup', required:true, optionsCol:12, options:genreOptions, col:6},

    {id:'f6', caption:'Telefone', type:'phone', required:true, col:6},
    {id:'f7', caption:'Telefone Adicional', type:'phone', required:false, col:6},

    {id:'f8', caption:'Nome da mãe', type:'text', required:true, col:6},
    {id:'f9', caption:'Nome do pai', type:'text', required:true, col:6},

    {id:'maritalStatus', caption:'Estado Civil', type:'select2', required:true, options:maritalStatusOptions, col:6,
    },
    {id:'spouseName', caption:'Nome do cônjugue', type:'text', required:true, col:6, row:true},
    {id:'spouseCpf', caption:'CPF do cônjugue', type:'cpf', required:true, col:6},

    {id:'f10', caption:'Etnia', type:'select2', required:false, options:ethnicityOptions, col:6, row:true},
    {id:'f11', caption:'Nacionalidade *', type:'select2', required:false, options:countryOptions, col:6},
    {id:'f12', caption:'País de Nascimento *', type:'select2', required:false, options:nationalityOptions, col:6},

    {id:'responsible.name', caption:'Nome do representante/procurador *', type:'text', col:12, row:true},
    {id:'responsible.document', caption:'CPF/CNPJ', type:'cpfcnpj', required:false, col:12},




]

export const aregistrationFormScreens = [
    {id:'investordata',
        caption:'Dados do investidor',
        //fields : testForm,
        fields : RegistrationFormInvestorData,
    },
]

export const registrationFormScreens = [
    {id:'investordata',
        caption:'Investidor',
        fields : RegistrationFormInvestorData,
    },
    // {id:'professionalInformation',
    //     caption: 'Profissão',
    //     fields : RegistrationFormProfessionalInformation
    // },
    {id : 'mailing',
        caption:'Endereço',
        fields : RegistrationFormMailing
    },
    {id : 'financial',
        caption:'Financeiro',
        fields : RegistrationFormFinancialData
    },
    {id : 'bankinformation',
        caption:'Contas bancárias',
        fields : RegistrationFormBankInformation
    },
    {id:'pep',
        caption:'PEP',
        fields:RegistrationFormPepData
    },
    {id:'fatca',
        caption:'FATCA',
        fields:RegistrationFormFatca
    },
    {id:'renewhistory',
        caption:'Renovação',
		component:RenewHistory,
        fields:[]
    },
]
