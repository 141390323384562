import React from "react"

//import { AvField, AvFeedback } from "availity-reactstrap-validation"
import {CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import {getValidDomProperties} from "../Utils/DomProp";

function FormTitleDefault(props) {

    const {caption, subtitle, className, type, inputRef, formId} = props
	//const rest = getValidDomProperties(props)
    return (
        <Row>
            <Col className={props.className ? props.className : "mb-2"}>
                <CardTitle className="mb-0 mt-0 text-primary font-family-title font-weight-800 font-size-rem1 "  >
                    {caption}
                </CardTitle>
                {subtitle && (
                <CardSubtitle className="mt-2 mb-2 px-2 text-muted " style={{fontSize:'0.9rem', fontWeight:'normal'}}>
                    {subtitle}
                </CardSubtitle>
                )}
            </Col>
        </Row>
    )
}

export default FormTitleDefault;
