import React, {useEffect, useState} from "react"

import {
	Row,
	Col,
} from "reactstrap"

import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";

import {DataActionButton} from "../../../../components/Common/Tables/Datatable/DataTypes";
import Button from "../../../../components/Common/Button/Button";
import ModalForm from "../../../../components/Common/Modals/ModalForm";
import {yesNoOptions} from "../../../../constants/formLists";
import { useOvermind } from '../../../../overmind'
import {onlyNumbers} from "../../../../renders/utils";
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";

const validateScore = (value, props, values) => {

	const {
		minScore=0,
		maxScore=0,
	} = values || {minScore:0, maxScore:0}

	// alert(confirm_password)

	if (parseInt(maxScore)<=parseInt(minScore))
		return false

	// if (parseInt(maxScore)>4500) {
	// 	return false
	// }
	return true
}

const categoryFields = [
	{
		id:'title',
		caption:'Dados da classificação',
		type:'title',
		required:true,
		col:12
	},
	{
		id:'name',
		caption:'Classificação',
		type:'text',
		required:true,
		col:12
	},
	{
		id:'description',
		caption:'Descrição',
		type:'textarea',
		required:true,
		row:true,
		col:12
	},
	{
		id:'minScore',
		caption:'Score Min.',
		type:'number',
		readOnly:true,
		required:true,
		col:6
	},
	{
		id:'maxScore',
		caption:'Score Máx.',
		validation:validateScore,
		type:'number',
		required:true,

		col:6
	},
	{
		id:'rfPercent',
		caption:'Min. Renda Fixa',
		type:'percent',
		required:true,
		col:4
	},
	{
		id:'mmPercent',
		caption:'Máx. Multimercado',
		type:'percent',
		required:true,
		col:4
	},
	{
		id:'rvPercent',
		caption:'Máx. Renda Variável',
		type:'percent',
		required:true,
		col:4
	},
]

const RiskProfileCategories = ({
	formId,
	field,
	...props
}) => {
	const readOnly = !UserCan('riskProfiles.edit')

	const riskProfiles = useOvermind('riskProfiles')

	const [viewId, setViewId] = useState(null)
	const [categoryData, setCategoryData] = useState(null)

	const handleNew = async  () => {
		const length = riskProfiles.state.categories.length
		const previous = length>0
			? riskProfiles.state.categories[length-1]
			: null

		const minScore = previous
			? (parseInt(previous.maxScore)+1).toString()
			: '0'

		setViewId('')
		setCategoryData({minScore})
	}

	const handleEdit = async  (row, index) => {
		setViewId(index)
		setCategoryData(riskProfiles.state.categories[index])
	}

	const handleDelete = async  (row, index) => {
		riskProfiles.actions.deleteByIndex(index)
	}

	const checkScore =  (row, index) => {

		const categories = riskProfiles.state.categories
		let ok = riskProfiles.state.categoriesRangesOK[index]


		return <div className={`${ok ? "" : "bg-fiduc-danger text-white"} rounded p-1 font-size-em-1 text-center text-nowrap`}>
			{row.minScore}-{row.maxScore}
		</div>
	}

	useEffect(() => {
		//riskProfiles.actions.clearCategories()
	}, [])


	// if (!formId)
	// 	return null

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setFormModel, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	// const Points = () => {
	// 	return riskProfiles.state.pointsOK
	// 		?
	// 			<div className="flex-grow-1 align-items-end d-flex flex-row" >
	// 				<div className="d-flex flex-grow-1">
	//
	// 				</div>
	// 				<div className="rounded p-1 ps-4 pe-4 d-flex flex-grow-0 align-self-end bg-success text-white m-1 text-align-right" >
	// 					Score Total: {riskProfiles.state.totalPoints}
	// 				</div>
	// 			</div>
	// 		:
	// 			<div className="text-danger m-1 flex-grow-1 font-size-em1 text-align-right" >
	// 				Score Total: {riskProfiles.state.totalPoints} de 4500
	// 			</div>
	//
	// }

	return <>

		<ModalForm
			data={categoryData}
			isVisible={!!categoryData}
			fields={categoryFields}
			onCancel={() => {
				setViewId(null)
				setCategoryData(null)
			}}
			onConfirm={(values) => {
				if (viewId!=='') {
					riskProfiles.actions.replaceByIndex({index:viewId, item:values})
				} else if (!viewId) {
					riskProfiles.actions.addCategory({formId, category:values})
				}
				setViewId(null)
				setCategoryData(null)
			}}
		/>
		<Row >
			<Col md={12}>

					<>
						<div className="d-flex flex-row align-items-center">
							{(field.hasRelation || readOnly)
								? null
								: <Button
									className="btn btn-primary m-1 btn-sm flex-grow-0"
									style={{width:'200px'}}
									onClick={handleNew}
								>
									<i className="fas fa-plus m-1" />
									Adicionar Classificação
								</Button>
							}
						</div>
					</>

				{field.hasRelation
					? <UserCanAll permission="riskProfiles.edit">
						<div className="text-center text-danger font-weight-600 p-2">
							Não é possível fazer alterações nos perfis de risco pois este já está relacionado à um cliente
						</div>
						</UserCanAll>
					: null
				}

			</Col>

		</Row>

		{!riskProfiles.state.categories || riskProfiles.state.categories.length===0 ? null :
			<DefaultTable
				className="pt-0 m-0"
				columns={[
					{id:'name', caption:'Classificação', width:'25%', formatter:(row) => row.name},

					{id:'description', caption:'Descrição', width:'30%', formatter:(row) => row.description},
					{id:'minScore', caption:'Score', width:'10%',
						formatter:(row, index) => checkScore(row, index)
					},
					// {id:'maxScore', caption:'      Máx.', width:'5%', formatter:(row) => row.maxScore},

					{id:'rfPercent', caption:'Min. RF', width:'5%', formatter:(row) => `${onlyNumbers(row.rfPercent)}%`},
					{id:'mmPercent', caption:'Máx. MM', width:'5%', formatter:(row) => `${onlyNumbers(row.mmPercent)}%`},
					{id:'rvPercent', caption:'Máx. RV', width:'5%', formatter:(row) => `${onlyNumbers(row.rvPercent)}%`},

					{id:'actions', width:'20%', caption:'',
						formatter : function Actions(item, index) {


							return <div className="d-flex flex-row">
								<UserCanAll permission="riskProfiles.edit">
								<DataActionButton
									disabled={field.hasRelation}
									buttonClass="btn btn-primary btn-sm me-1"
									iconClass="fas fa-pen m-0"
									type="button"
									onClick={() => handleEdit(item, index)}
								/>
								<DataActionButton
									disabled={field.hasRelation}
									buttonClass="btn btn-danger btn-sm"
									iconClass="fas fa-trash m-0"
									onClick={item => handleDelete(item, index)}
									type="button"
								/>
								</UserCanAll>
							</div>
						}
					},
				]}
				data={riskProfiles.state.categories}
			/>
		}

	</>

}

export default RiskProfileCategories
