import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../../helpers/gestora/api"

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../../overmind";
import {Datatable} from "../../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
moment.locale('pt-BR')

import CustomForm from "../../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import Button from "../../../../components/Common/Button/Button"

import MailSigView from "../View";

import PageDatatable from "../../../../components/Common/Tables/Datatable/PageDatatable";
import DefaultPageHeader from "../../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient, downloadFileText} from "../../../../common/url";
import {yesNoOptions} from "../../../../constants/formLists";
import {DataActionButton} from "../../../../components/Common/Tables/Datatable/DataTypes";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";
import {UserCan} from "../../../../helpers/gestora/auth";
import {useLocalStorage} from "../../../../helpers/gestora/UseLocalStorage";
import {copyRichTextToClipboard, copyTextToClipboard} from "../../../../common/clipboard";
import {getFullHtmlTemplate, getHtmlTemplate} from "../htmlTemplates";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

//console.log('statusTabs', statusTabs)

export const MailSigSearch = () => {
	const formId = 'MailSigSearch'
	const location = useLocation()
	const history = useHistory()

	// const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	// const [compactMode, setCompactMode] = useState(true)
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [exportingResult, setExportingResult] = useState(false)

	const [viewId, setViewId] = useState(null)


	// const app = useOvermind('app')

	const apiEndpoint = {
		path : `mailsig/search`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			setModelValueByPath,
		}
	} = useOvermind('forms')

	const mailsig = useOvermind('mailsig')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`mailsig/downloadCsv`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
			//updatedAt : model.updatedAt ? dateBrToISO(model.updatedAt) : null,
			//value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		setViewId(row.id)
	};

	const handleNew = async () => {
		setViewId('')
	};

	const handleDownloadHtml = async (row) => {
		const html = getFullHtmlTemplate(row.email)
		downloadFileText(html)
	}

	const handleCopyHtml = async (row) => {
		const html = document.getElementById(`html-signature-${row.id}`).innerHTML
		// const html = getFullHtmlTemplate(row.filename)

		copyRichTextToClipboard(html,
			() => alert(`O código HTML foi copiado para área de transferência! \n\nEle pode ser colado diretamente no campo de assinatura do painel de emails (G-Mail, Outlook, etc...)`),
			() => alert("Não foi possível copiar o texto para a área de transferência.")
		)

	}

	const handleDelete = async (row) => {
		if (confirm(`Deletar assinatura "${row.name}" email "${row.email}"?`)) {
			mailsig
				.actions
				.deleteById(row.id)
				.then(() => {
					refreshList()
				})
				.catch((e) => {
					alert('Ocorreu um erro ao tentar deletar a assinatura')
				})
		}
	};

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'name',
			name: <CustomHeader onComplete={handleFilterChange}  id={'name'} formId={formId} fieldProps={{type:'text'}}>Nome</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.name || "(Não preenchido)",
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'email',
			name: <CustomHeader onComplete={handleFilterChange} id={'email'} formId={formId} fieldProps={{type:'text'}}>E-mail</CustomHeader>,
			grow:0,
			width:'220px',
			selector: row => row?.email,
			sortable: true,
			compact : true,
			wrap : true,

		},
		{
			id : 'phone',
			name: <CustomHeader onComplete={handleFilterChange} id={'phone'} formId={formId} fieldProps={{type:'phone'}}>Telefone</CustomHeader>,
			grow:0,
			width:'128px',
			selector: row => row?.phone,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'actions',
			name: "Ações",
			grow:0,
			minWidth:'128px',
			center:true,
			omit : !UserCan('mailsig.edit'),
			selector: function A(row) {
				return <div className="d-flex flex-row">
					<div
						id={`html-signature-${row.id}`}
						style={{display:'none'}}
						dangerouslySetInnerHTML={{__html:getHtmlTemplate(row.email)}}
					/>

					<DataActionButton
						title={"Fazer download da página HTML "}
						buttonClass={`btn btn-outline-success btn-sm m-1`}
						iconClass="bx bxs-download"
						onClick={() => handleDownloadHtml(row)}
					/>
					<DataActionButton
						title={"Copiar para área de transferência"}
						buttonClass={`btn btn-outline-success btn-sm m-1`}
						iconClass="far fa-copy"
						onClick={() => handleCopyHtml(row)}
					/>
					<DataActionButton
						title={"Apagar assinatura"}
						buttonClass="btn btn-outline-danger btn-sm m-1"
						iconClass="fas fa-trash "
						onClick={() => handleDelete(row)}
					/>
				</div>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
	], []);

	//console.log(orderDirection)
	return (<UserCanAll permission="mailsig.consult">
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Assinaturas de e-mail"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				{viewId!==null ?
				<MailSigView
					formId={formId}
					viewId={viewId}
					onConfirm={() => {
						setViewId(null)
						refreshList()
					}}
					onCancel={() => {
						setViewId(null)
					}}
				/> : null}

				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<UserCanAll permission="mailsig.edit">
							<Button
								className="btn btn-primary m-1 btn-sm"
								onClick={handleNew}
							>
								<i className="fas fa-plus m-1" />
								Adicionar Assinatura
							</Button>
						</UserCanAll>
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						{/*<Button*/}
						{/*	disabled={false}*/}
						{/*	colorIcon={true}*/}
						{/*	className="btn btn-outline-primary m-1 btn-sm"*/}
						{/*	// disabled={currentScreen==0}*/}
						{/*	onClick={() => setCompactMode(!compactMode)}>*/}
						{/*	<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>*/}
						{/*</Button>*/}
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	);
};

export default {
	title: 'Pagination/Remote',
	component: MailSigSearch,
};
