import React, {Component, useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {
	Row, Col,
	Container,
} from "reactstrap"
import { Link, matchPath, useLocation, useHistory, useParams } from "react-router-dom"
import DefaultBox from "../../components/Common/Box/DefaultBox/DefaultBox";
import LoadingSection from "../../components/Common/LoadingSection/LoadingSection";
import api, {sleep} from "../../../src/helpers/gestora/api";
import {
	datePeriodOptions,
	docFundConst,
	RegFormApprovalEnum,
	RegFormApprovalRenewEnum, registrationFormRenewStatusOptions
} from "../../constants/formLists";
import {MoneyFormat} from "../../renders/utils";
import DateRange from "../../components/Common/DateRange/DateRange";

import moment from "moment"
import DefaultPage from "../../components/Common/DefaultPage/DefaultPage";
import Button from "../../components/Common/Button/Button";
import CustomForm from "../../components/Common/CustomForm/Form";
import {useOvermind} from "../../overmind";
import Field from "../../components/Common/CustomForm/Field";
import DefaultPageHeader from "../../components/Common/DefaultPage/DefaultPageHeader";
import UserCanAll from "../../components/Common/UserCan/UserCanAll";
moment.locale('pt-BR')

const defaultDate = [
	moment().startOf('month').format('YYYY-MM-DD'),
	//moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

const Dashboard = (props) => {

	const formId = 'Dashboard'
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [loadingPeriod, setLoadingPeriod] = useState(false)
	const [clientCounter, setClientCounter] = useState({})
	const [renewCounter, setRenewCounter] = useState({})

	const [investmentsToday, setInvestmentsToday] = useState({})
	const [investmentsByPeriod, setInvestmentsByPeriod] = useState({})

	const [selectedPeriod, setSelectedPeriod] = useState(null)

	const history = useHistory()

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			// submitForm,
			setModelValueByPath,
			// setFormState, setFieldState, setFormModel, resetForm,
			// updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	const gotoInvestments = (period, status) => {
		  //resetForm({formId:'InvestmentSearch', model:{}})
		  history.push({
			  pathname:"/investments/search",
			  state : {period, status}
		  })
	}

	useEffect(() => {
		if (selectedPeriod===defaultDate) return
		if (!selectedPeriod)
			return

		refreshPeriodData()
		.then(() => {
			// console.log('refreash period')
		})
	}, [selectedPeriod])

	useEffect(() => {
		//console.log(history.location?.state)
		setModelValueByPath({formId, path:'period', value:defaultDate})
	}, [])

	useEffect(() => {

		(async () => {
			try {
				setLoading(true)
				const resClient = await api.post(`admin/clients/count`)
				const {allClients, renewClients} = resClient?.data || {}
				setClientCounter(allClients)
				setRenewCounter(renewClients)

				const resInvestToday = await api.post(`admin/investments/count`, {
					status:['pending'],
				})
				//console.log('resInvestToday', resInvestToday?.data)
				setInvestmentsToday(resInvestToday?.data || {})
				//setInvestmentsByPeriod(resInvestToday?.data || {})

			} catch(e) {
				setError('Não foi possível carregar a dashboard')
			} finally {
				setLoading(false)
			}

		})();
	}, [])

	const gotoClients = async (params) => {
		history.push({
			  pathname:"/clients/search",
			  state : params
		  })
	  }

	const refreshPeriodData = async () => {
		//alert('refreshPeriodData')
		try {
			setLoadingPeriod(true)
			const resInvestPeriod = await api.post(`admin/investments/count`, {
				periodStart : selectedPeriod[0],
				periodEnd : selectedPeriod[1],
					status:['created', 'canceled'],
			})
			setInvestmentsByPeriod(resInvestPeriod?.data || {})

		} catch(e) {
			alert('Ocorreu um erro ao carregar os dados')
			//setError('Não foi possível carregar a dashboard')
		} finally {
			setLoadingPeriod(false)
		}
	}

	useEffect(() => {
		const selectedPeriodStart = selectedPeriod?.length && selectedPeriod[0]
		const selectedPeriodEbd = selectedPeriod?.length && selectedPeriod[1]
 		const periodStart = model?.period?.length && model?.period[0] ? moment(model?.period[0]).format('YYYY-MM-DD') : null
		const periodEnd = model?.period?.length>1 && model?.period[1] ? moment(model?.period[1]).format('YYYY-MM-DD') : null
		if (
			(periodStart!=selectedPeriodStart || periodEnd != selectedPeriodEbd)) {
			setSelectedPeriod([
				periodStart,
				periodEnd
			])
		}
	}, [model?.period])

	return (
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Dashboard"  />
			}
		>
				<CustomForm
					className="needs-validation "
					formId={formId}
					model={model}
					validateOnLoad={false}
				>

					<LoadingSection loading={loading} error={error}>

						<UserCanAll permission={['clients.consult']}>
							<DefaultBox
								className="pt-2"
								title={`Cadastro de Clientes`}
								actions={
									<Row>
										<Col>
											<Button
												onClick={() => gotoClients({status:'overdue'})}
												className="p-2 pb-0 pt-0 m-0 btn d-flex flex-row btn-sm" >
												<h6 style={{paddingTop:'0.5rem', textOverflow:'ellipsis', }} className="overflow-hidden text-danger text-overflow-ellipsis font-weight-500 font-size-em1 text-align-right ">
													Em atraso
												</h6>
												<div style={{fontSize:'1.1rem'}} className="font-weight-semibold ps-2">
													{clientCounter?.pendingOverdue}
												</div>
											</Button>
										</Col>
									</Row>
								}
							>
								<Row>
									<BoxCounter
										onClick={row => gotoClients({status:RegFormApprovalEnum.STARTED.toString()})}
										text="Incompletos"
										value={clientCounter[RegFormApprovalEnum.STARTED] || 0}
									/>
									<BoxCounter
										onClick={row => gotoClients({status:'welfare'})}
										text="Previdência" value={clientCounter.welfare || 0}/>
									<BoxCounter
										onClick={row => gotoClients({status:RegFormApprovalEnum.PENDING_ADJUSTMENT.toString()})}
										text="Pendentes Ajuste" value={clientCounter[RegFormApprovalEnum.PENDING_ADJUSTMENT] || 0}/>
									<BoxCounter
										onClick={row => gotoClients({status:RegFormApprovalEnum.PENDING.toString()})}
										text="Análise Fiduc" value={clientCounter[RegFormApprovalEnum.PENDING] || 0}
									/>
									<BoxCounter
										onClick={row => gotoClients({status:RegFormApprovalEnum.SENT_TO_BANK.toString()})}
										text="Análise Bradesco" value={clientCounter[RegFormApprovalEnum.SENT_TO_BANK] || 0}/>
									<BoxCounter
										onClick={row => gotoClients({status:RegFormApprovalEnum.APPROVED.toString()})}
										text="Aprovados" value={clientCounter[RegFormApprovalEnum.APPROVED] || 0}/>
									<BoxCounter
										onClick={row => gotoClients({status:RegFormApprovalEnum.NOT_APPROVED.toString()})}
										text="Reprovados" value={clientCounter[RegFormApprovalEnum.NOT_APPROVED] || 0}/>
								</Row>
							</DefaultBox>
							<DefaultBox
								className="pt-2 mt-3"
								title={`Renovação Cadastral`}
							>
								<Row>
									{registrationFormRenewStatusOptions.map(renew =>
										<BoxCounter
											key={renew.id}
											onClick={row => gotoClients({renewStatus:renew.id.toString()})}
											text={renew.caption}
											value={renewCounter[renew.id] || 0}
										/>
									)}
									<BoxCounter
										onClick={row => gotoClients({renewStatus:'available'})}
										text={"Disponíveis"}
										value={renewCounter['available'] || 0}
									/>
									<BoxCounter
										onClick={row => gotoClients({renewStatus:'expired'})}
										text={"Vencidas"}
										value={renewCounter['expired'] || 0}
									/>
								</Row>
							</DefaultBox>
						</UserCanAll>

						<UserCanAll permission={['investments.consult']}>
							<br />
							<DefaultBox
								className="pt-2"
								actions={
									<Button color="outline-danger btn-sm" onClick={() => gotoInvestments(null, 'pending')}>Detalhes</Button>
								}
								title={"Movimentações"}
							>

								{/*<BoxInvestment title={"Em atraso"} totalByStatus={clientCounter.pendingOverdue || 0}  investments={null}/>*/}

								<BoxInvestment
									title={"Em processamento"}
									clientsByStatus={investmentsToday?.uniqueClientsByStatusOperation?.pending}
									totalByStatus={investmentsToday.totalByStatus?.pending || 0}
									investments={investmentsToday.pending}
								/>

							</DefaultBox>
							<br />
							<DefaultBox
								className="pt-2"
								title={"Finalizadas por período"}
								actions={<>
								<Row>
									<Col sm={12} className='justify-content-end d-flex'>
										<Button
											color="outline-danger btn-sm"
											onClick={() => gotoInvestments(selectedPeriod, 'created')}>Detalhes
										</Button>
									</Col>
								</Row>
								</>
								}
							>
								<Row className="mb-0">
									<Col xs={2} sm={2} md={2}></Col>
									<Col xs={12} sm={12} md={4} className='justify-content-start d-flex pt-2'>
										<Field
											formId={formId}
											id="period"
											type="daterange"
											caption="Período"
											emptyOptionText={<b>Tudo</b>}
											defaultValue={1}
											col={12}
											xs={12}

											options={datePeriodOptions}
										/>
									</Col>
								</Row>
								<BoxInvestment
									title={"Realizadas"}
									totalByStatus={investmentsByPeriod.totalByStatus?.created || 0}
									clientsByStatus={investmentsByPeriod?.uniqueClientsByStatusOperation?.created}
									investments={investmentsByPeriod.created}
								/>
								<br />
								<Row>
									<Col sm={12} className='justify-content-end d-flex pe-0'>
										<Button
											color="outline-danger btn-sm"
											onClick={() => gotoInvestments(selectedPeriod, 'canceled')}>Detalhes
										</Button>
									</Col>
								</Row>
								<BoxInvestment
									title={"Canceladas"}
									totalByStatus={investmentsByPeriod.totalByStatus?.canceled || 0}
									clientsByStatus={investmentsByPeriod?.uniqueClientsByStatusOperation?.canceled}
									investments={investmentsByPeriod.canceled}
								/>
								<div className="pb-4" />
							</DefaultBox>
							<div className="pb-4" />
						</UserCanAll>
					</LoadingSection>
				</CustomForm>
		</DefaultPage>
	)

}

const BoxCounter = (props) => {
	return (
		<Col onClick={props.onClick}
			 className="dashboard-box-counter border border-dark m-1  rounded-3 d-flex flex-column text-uppercase font-size-16"
			 style={{
			 	cursor : props.onClick ? 'pointer' : 'unset',
				 minHeight:'6em',
			 }}
		>
			<div className="d-flex" style={{fontSize:'0.6em', flex:".8 1 0%"}}>
				<div className=" align-self-end text-center" style={{flex:1}}>{props.text}</div>
			</div>
			<div className="font-weight-semibold text-center" style={{fontSize:'1.2rem', flex:1}}>{props.value}</div>
		</Col>
	)
}

const BoxInvestment = (props) => {
	const {investments = {}, title='', clientsByStatus = null} = props
//	console.log('investments', investments)

	//const groups = getValueResult(investments, 'apply')
	//console.log('groupApply', groupApply)

	const total = {
		apply : getValueResult(investments || {}, 'apply', 'total')?.sum,
		rescue_total : getValueResult(investments || {}, 'rescue_total', 'total')?.sum,
		rescue_parcial : getValueResult(investments || {}, 'rescue_parcial', 'total')?.sum,
	}

	total.liquid = total.apply-(total.rescue_total+total.rescue_parcial)
	// console.log('clientsByStatus?.apply', clientsByStatus?.apply)

	return (
		<Row className=" ">
			{ title
				? <Row >
					<Col xs={2} className="pe-0 ">
						{typeof(title)=='string'
							? <h6 style={{paddingTop:'0.3rem', textOverflow:'ellipsis'}} className="overflow-hidden text-danger text-overflow-ellipsis font-size-em1 text-align-right font-family-title font-weight-600">{title}</h6>
							: <title />
						}
					</Col>
					<Col xs={10} className="text-left  " >
						<div style={{fontSize:'1.2rem'}} className="font-weight-semibold ps-2">
							{props.totalByStatus}
						</div>
					</Col>
				</Row>
				: null
			}
			{investments && <>
			<Col xs={2} className="p-0 pt-1 font-size-12" style={{textAlign:'right', marginTop:'1px'}} >
				<div style={{minHeight:'1.5em'}}  ></div>
				<div>Aplicação</div>
				<div>Resg. Total</div>
				<div>Resg. Parcial</div>
				<div>Liq.</div>
			</Col>
			<Col xs={10} className="p-0 pt-1 ps-2 d-flex flex-row">
				<BoxInvestmentByFund title="RF" investments={investments} fundId={docFundConst.RF} />
				<BoxInvestmentByFund title="MM" investments={investments} fundId={docFundConst.MM} />
				<BoxInvestmentByFund title="RV" investments={investments} fundId={docFundConst.RV} />
				<BoxInvestmentByFund title="INTER" investments={investments} fundId={docFundConst.INT} />
				<BoxInvestmentUnit title="Total" values={[
					MoneyFormat(total.apply, 'R$ ', 0),
					MoneyFormat(total.rescue_total, 'R$ ', 0),
					MoneyFormat(total.rescue_parcial, 'R$ ', 0),
					MoneyFormat(total.liquid, 'R$ ', 0),
				]} />
				<BoxInvestmentUnit title="Clientes" values={[
					clientsByStatus?.apply || 0,
					clientsByStatus?.rescue_total || 0,
					clientsByStatus?.rescue_parcial || 0
				]} />
			</Col></>
			}

		</Row>
	)
}

const BoxInvestmentUnit = (props) => {
	const {title='', values=[]} = props

	return (
		<div className="border border-primary rounded d-flex flex-column ms-2  overflow-hidden text-center" style={{flex:1}}>
			<div className="bg-fiduc-blue text-white">{title}</div>
			{values && values.map((value, index) =>
				<div key={index} className="font-size-11 text-nowrap " style={{paddingTop:'1px'}}>
					{value}
				</div>
			)}
		</div>
	)
}

const getValueResult = (data, operation, fundId=0) => {
	return fundId ?
		((data[operation] && data[operation][fundId]) ? data[operation][fundId] : null)
		:
		(data[operation] ? data[operation] : null)
}

const BoxInvestmentByFund = (props) => {
	const {title='', investments={}, fundId} = props

	const apply = (investments.apply && investments.apply[fundId]) ? investments.apply[fundId].sum : 0
	const rescue_total = (investments.rescue_total && investments.rescue_total[fundId]) ? investments.rescue_total[fundId].sum : 0
	const rescue_parcial = investments.rescue_parcial && investments.rescue_parcial[fundId] ? investments.rescue_parcial[fundId].sum : 0
	const total = apply-(rescue_parcial+rescue_total)
	const values = [
		MoneyFormat(Math.round(apply), 'R$ ', 0),
		MoneyFormat(Math.round(rescue_total), 'R$ ', 0),
		MoneyFormat(Math.round(rescue_parcial), 'R$ ', 0),
		MoneyFormat(Math.round(total), 'R$ ', 0),
	]

	return <BoxInvestmentUnit
		title={title}
		values={values}
	/>

}

export default Dashboard;
