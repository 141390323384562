import api, {sleep} from "../../helpers/gestora/api";

export const setSearchParams = ( { state }, searchParams ) => {
    state.app.searchParams = searchParams
    state.app.searchCount++
}

export const saveInvestmentStatus = ( { state }, params ) => {

	return api
		.post(`admin/investments/updatestatus/${params.id}`, params)
		//.then(sleep(1000))

	//console.log(`saveInvestmentStatus`, params)

}

export const approveMany = ( { state }, ids ) => {

	return api
		.post(`admin/investments/approvestatusmany`, {ids})
		//.then(sleep(1000))

	//console.log(`saveInvestmentStatus`, params)

}

export const reSendEmailMany = ( { state }, ids ) => {

	return api
		.post(`admin/investments/resendemails`, {ids})
		//.then(sleep(1000))

	//console.log(`saveInvestmentStatus`, params)

}

export const changePaymentDateMany = ( { state }, {ids, date} ) => {

	return api
		.post(`admin/investments/changepaymentdatemany`, {ids, date})
		//.then(sleep(1000))

	//console.log(`saveInvestmentStatus`, params)

}

export const listInvestmentHistory = ( { state }, {investmentId} ) => {

	return api
		.post(`admin/investments/history/${investmentId}`)
		//.then(sleep(1000))
		.then(res => res.data)

	//console.log(`saveInvestmentStatus`, params)

}

export const reSendEmailToClientMany = ( { state }, ids ) => {

	return api
		.post(`admin/investments/resendemailstoclient`, {ids})
		//.then(sleep(1000))

	//console.log(`saveInvestmentStatus`, params)

}

export const loadInvestment = ( { state }, id ) => {

	return api
		.get(`admin/investments/${id}`)
		//.then(sleep(1000))
		.then(res => {
			return res.data
		})
}

