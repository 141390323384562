import React, {useEffect, useState} from "react"

import {
	Row,
	Col,
} from "reactstrap"
import Field from "../../../../components/Common/CustomForm/Field";
import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";
import {useOvermind} from "../../../../overmind";
import {dateIsoToBr} from "../../../../renders/utils";
import Button from "../../../../components/Common/Button/Button";
import {docTypes} from "../../../../constants/formLists";
import ButtonPrimary from "../../../../components/Common/Button/ButtonPrimary";
import {downloadFileClient} from "../../../../common/url";
import api from "../../../../helpers/gestora/api";

const fundsByName = {
	'adesao_rf' : 'FIDUC RENDA FIXA',
	'adesao_mm' : 'FIDUC MULTIMERCADO',
	'adesao_rv' : 'FIDUC RENDA VARIAVEL',
	'adesao_internacional' :'FIDUC INTERNACIONAL',
	'desenquadramento' :'Termo de desenquadramento',
}

const ServiceProposalDocument = ({
	formId,
	...props
}) => {

	const client  = useOvermind('clients')
	const proposalDocument = client.state.documentUploadsAvailable.find(el => el.docType == docTypes.SERVICE_PROPOSAL)
	const [downloading, setDownloading] = useState(false)

	const handleDownload = (evt, fileTitle=null) => {

		setDownloading(true)
		api
			.get(`documentUploads/download/${proposalDocument.id}`, {responseType: 'blob'})
			.then(res => downloadFileClient(res, fileTitle))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setDownloading(false))
	}

	return !proposalDocument ? null : <>
		<Field
			formId={formId}
			type="title"
			caption="Proposta de serviço"
			className="mb-0"
		/>
		<div className="d-flex debug1 mb-3" style={{height:'2.5rem'}}>
			<ButtonPrimary
				caption="Abrir proposta de serviço"
				outlined={true}
				loading={downloading}
				onClick={(e) => handleDownload(e, 'Proposta comercial')}
			/>
		</div>


	</>

}

export default ServiceProposalDocument
