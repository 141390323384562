import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
	Table,
} from "reactstrap"

function DefaultTable(
{
	data,
	columns,
	condensed=false,
	hover=false,
	containerStyle,
	responsive=true,
	...props
}) {

	return (
		<div
			className={`${responsive ? "table-responsive" : ""} ${props.className}`}
			style={containerStyle}
		>
			<Table
				className={`table 
					${condensed ? "table-condensed": ""}
					${hover ? "table-hover": ""}
				`}
			>
				<thead className="p-0 m-0">
				<tr>
				{columns && columns.map((column, columnIndex)=> {
					return <th key={column.id}>{column.caption}</th>
				})}
				</tr>
				</thead>
				<tbody>
				{data && data.map((item, itemIndex) => {
					return <tr key={itemIndex}>
						{columns && columns.map((column, columnIndex)=> {
							const value = item[column.id]
							return <td key={column.id + itemIndex.toString()} width={column.width}>
								{column.formatter ? column.formatter(item, itemIndex) : value}
							</td>
						})}
					</tr>
				})}
				</tbody>
			</Table>
		</div>
	)
}

export default DefaultTable;
