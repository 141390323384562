import React, {useEffect, useState} from "react"

import KYCInfo from "../components/KYCInfo";

function KYCLawsuits(
{
	KYCData,
	...props
}) {


	const [data, setData] = useState(null)

	useEffect(() => {

		const info = KYCData[0]
		setData([
			{caption:'Total como autor', value:info?.processes?.totalLawsuitsAsAuthor},
			{caption:'Total como réu', value:info?.processes?.totalLawsuitsAsDefendant},
			{caption:'Total', value:info?.processes?.totalLawsuits},
		])
	}, [KYCData])

	return <>
		<KYCInfo data={data} />
	</>
}

export default KYCLawsuits;
