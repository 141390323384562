import {
} from "../../../../constants/formLists";

import moment from "moment"
import KYC from "../KYC";
moment.locale('pt-BR')

const KycComponent = [

    {
    	id:'kyc.component',
		caption:'KYC',
		component:KYC,
	},
]

export const kycScreens = [
    {id:'kyc',
        caption:'KYC',
        fields : KycComponent,
    },
]
