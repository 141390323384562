export const clients = {

	registrationFormId : 0,
	saving : false,
	done : false,

	saveCount : 0,

	scoreKYC : 0,

	documentUploadsAvailable : [],

	savingStepsTotal : 0,
	savingStepsCurrent : 0,
	savingText : '',

	suitabilityQuestions : '',

	currentRegistrationForm : '',

	temporaryPassword : '',

}
