import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
	Card,
	CardBody,
	CardTitle,
	CardText,
	TabPane,
	NavLink,
	NavItem,
	TabContent,
	Nav,
	Table,
} from "reactstrap"
import moment from "moment"
moment.locale('pt-BR')

//import { registrationFormScreens } from "./Configuration"

import { useOvermind } from "../../../../overmind";

// import FormTitleDefault from "../../../../components/Common/CustomForm/SubTitles/TitleDefault";
import Field from "../../../../components/Common/CustomForm/Field";
import api, {sleep} from "../../../../helpers/gestora/api";
import {dateIsoToBr} from "../../../../renders/utils";
import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";
import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";
import Button from "../../../../components/Common/Button/Button"
import RenewAttachments from "./RenewAttachments";

function RenewHistory(props) {

	const {formId} = props
	//alert(formId)
    const {
        state   : {
            [formId]:{model}
        },
        actions : {
			setModelValueByPath
        }
    } = useOvermind('forms')

	const client = useOvermind('clients')
	const renew = useOvermind('renew')

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [downloading, setDownloading] = useState({})
	const [history, setHistory] = useState(null)
	const [viewAttachmentsId, setViewAttachmentsId] = useState(0)
	const [refreshCount, setRefreshCount] = useState(1)
	const [openingTerm, setOpeningTerm] = useState({})
	const [redoingRenew, setRedoingRenew] = useState({})

	const openTerm = docToken => {
		setOpeningTerm({
			...openingTerm,
			[docToken] : true
		})
		client.actions
			.openZasignDocument(docToken)
			.finally(() => {
				setOpeningTerm({
					...openingTerm,
					[docToken] : false
				})
			})
	}

	const redoRenew = (clientId, clientHistoryId) => {
		if (!confirm(`Tem certeza que deseja reiniciar esta renovação cadastral?`))
			return

		setRedoingRenew({
			...redoingRenew,
			[clientHistoryId] : true
		})
		renew.actions
			.redoRenew({clientId, clientHistoryId})
			.then(() => alert(`A renovação está disponível novamente para o cliente.`))
			.catch(e => alert(`Não foi possível realizar a operação`))
			.then(() => client.actions.incSaveCount())
			.finally(() => {
				setRedoingRenew({
					...redoingRenew,
					[clientHistoryId] : false
				})
			})
	}

	// const [currentStatus, setCurrentStatus] = useState(null)
	// const [changingStatus, setChangingStatus] = useState(null)
	// const [refreshCount, setRefreshCount] = useState(0)

	useEffect(() => {

		if (!client.state.currentRegistrationForm || !refreshCount)
			return
		setLoading(true)
		// const url = `renew/list/${client.state.currentRegistrationForm.form.client.id}`

		renew.actions
			.loadNeedFormFatcaEUA({clientId:client.state.currentRegistrationForm.form.client.id})
			//.then(sleep(2000))
			.then(res => {
				console.log('renew/list', res.data)

				setModelValueByPath({formId, path:'client.needFormFatcaEUA', value:res.needFormFatcaEUA})
				setModelValueByPath({formId, path:'client.haveRenews', value:res.haveRenews})

				setHistory(res.data)
			})
			.catch(e => {
				setError('Não foi possível carregar as informações de histórico')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [refreshCount])

	const handleRefreshPage = async () => {
		setViewAttachmentsId(0)
		setRefreshCount(refreshCount+1)
	}

	const downloadHistory = async (history, documentName=null) => {
		const clientData = client.state.currentRegistrationForm.form.client
		const url = `renew/downloadHistory/${history.clientOd}/${history.id}`

		//alert(url)
		setDownloading({
			...downloading,
			... { [history.id] : true }
		})
		api
			.get(url, {
				responseType: 'blob'
			})
			.then(res => {

				const a = document.createElement('a');
				//alert(res.data.length)
				//console.log(res)
				const url = window.URL.createObjectURL(res.data);
				////const fileName = `FICHA_CADASTRAL_${model.client.name.toUpperCase().replace(/\s/g, '_')}.zip`
				//console.log('res.headers', res)
				let extension = res.headers['content-type'].split('/')[1]
				if (extension=='sheet')
					extension = 'xls'
				const fileName = (documentName || `RENOVACAO_${clientData.name.toUpperCase().replace(/\s/g, '_')}`);
				//const fileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0];
				a.href = url;
				a.download = `${fileName}.${extension}`;
				document.body.append(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);
			})
			.catch(e => {
				alert('Ocorreu um erro ao gerar o arquivo: ' + e.toString())
			})
			.finally(() => {
				setDownloading({
					...downloading,
					... { [history.id] : false }
				})
			})

	}

    return (<>

		<LoadingSection loading={loading} error={error} noData={history?.length===0 ? "Não há histórico de renovação disponível." : null}>
			{viewAttachmentsId ?
			<RenewAttachments
				formId={formId}
				viewId={viewAttachmentsId}
				onConfirm={() => handleRefreshPage()}
				onCancel={() => setViewAttachmentsId(0)}
			/> : null}
			<Col className={"  "}>
				<Field
					type="title"
					formId={formId}
					caption="Histórico de renovação"
					formGroupClassName={"mb-0 pb-0"}
				/>
			</Col>

			<DefaultTable
				className="pt-0 mt-0"
				columns={[
					{id:'approved_date', caption:'Data', formatter:(row) => dateIsoToBr(row.approved_date, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					{id:'type', caption:'Renovação', formatter:(row) => 'Sem alteração'},
					{id:'author', caption:'Usuário', formatter:(row) => row.client?.name},
					{id:'channel', caption:'Canal', formatter:(row) => `APP`},
					{id:'actions', caption:'Documentação',
						formatter : function Actions(item, itemIndex) {
							const zapsignDoc = item?.zapsignUploads
							const hasDownload =  item.status=='approved' && zapsignDoc?.doc_token
							const needAttachment =  item.needAttachment
							const canRedoRenew =
								moment().diff(item.approved_date, "days")<=60
								// moment().diff(item.approved_date, "minutes")<=10
								&& itemIndex===0
								&& item.status=='approved'

							return <div className="d-flex flex-row">
								{!hasDownload ? null :
									<Button
										loading={downloading[item.id] || openingTerm[zapsignDoc?.doc_token]}
										disabled={downloading[item.id]}
										className="btn btn-primary btn-block btn-sm me-2"
										type="button"
										onClick={() => openTerm(zapsignDoc?.doc_token)}
									>
										Zapsign
									</Button>
								}
								{!needAttachment ? null :
									<Button
										className={`btn  btn-block btn-sm ${item.attachment ? "btn-primary" : "btn-danger"} me-2`}
										type="button"
										onClick={() => setViewAttachmentsId(item.id)}
									>
										Anexos
									</Button>
								}
								{!canRedoRenew ? null :
									<Button
										loading={redoingRenew[item.id]}
										disabled={redoingRenew[item.id]}
										className="btn btn-primary btn-block btn-sm "
										type="button"
										onClick={() => redoRenew(item.client_id, item.id)}
									>
										Refazer
									</Button>
								}
							</div>
						}
					},
				]}
				data={history}
			/>

		</LoadingSection>
	</>)

}

export default RenewHistory;
