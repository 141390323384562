import React, {useEffect, useState} from "react"

import {dateIsoToBr} from "../../../../../renders/utils";
import DefaultTable from "../../../../../components/Common/Tables/DefaultTable/DefaultTable";

function KYCCompliance(
{
	KYCData,
	...props
}) {

	const [data, setData] = useState(null)

	useEffect(() => {
		console.log('KYCData BASIC', KYCData)
		const info = KYCData[0]
		setData(info?.kycData?.sanctionsHistory || [])
		console.log('info?.sanctionsHistory', info?.sanctionsHistory)
	}, [KYCData])

	return (
		<DefaultTable
			columns={[
				{id:'type', caption:'Tipo'},
				{id:'source', caption:'Fonte'},
				{id:'matchRate', caption:'Taxa de correspondência'},
				{id:'startDate', caption:'Data inicial', formatter:(row) => dateIsoToBr(row.startDate)},
			]}
			data={data}
		/>
	)
}

export default KYCCompliance;
