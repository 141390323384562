import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../../components/Common/Button/Button"
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";

const apiOptions = [
	{id:'consult', caption:"Consultar"},
	{id:'edit', caption:"Modificar"},
	// {id:'exclude', caption:"Excluir"},
	// {id:'include', caption:"Incluir"},
	// {id:'approve', caption:"Aprovar"},
]

function ProfilesView(props) {

    const {
    	formId,
		viewId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const readOnly = !UserCan('profiles.edit')

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const profiles = useOvermind('profiles')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const [statusScreens, setStatusScreens] = useState([])
	const [apis, setApis] = useState([])
	//const [data, setData] = useState(null)

    useEffect(() => {
		profiles.actions
			.loadApis()
			.then(data => {
				setApis(data)
			})
			.catch(e => {
				alert('Erro ao carregas as apis disponíveis')
			})

	}, [])

    useEffect(() => {
    	setError(false)
    	setSaving(false)
    	setSaved(false)

		if (!parseInt(viewId)) {
			setLoading(true)
			setModelValueByPath({formId, path:'edit', value:{}})
			incFormValidatedCount({formId})
			setTimeout(() => setLoading(false), 1)
		} else {
			setLoading(true)
			// resetForm({formId})

			profiles.actions.loadById({formId, id:viewId})
				.then(data => {

					//setData(record)
					const record = {
						name : data.name,
						apis : {}
					}
					for (const api of data.apis) {
						if (!api.api)
							continue
						if (!record.apis[api.api.id]) {
							record.apis[api.api.id] = []
						}
						for (const option of apiOptions) {
							if (api[option.id])
								record.apis[api.api.id].push(option.id)
						}
					}

					setModelValueByPath({formId, path:'edit', value:record})
					incFormValidatedCount({formId})

				})
				.catch(e => {
					setError(e.response?.data || e.toString())
				})
				.finally(() => {
					setLoading(false)
				})
		}



    }, [viewId])

	const handleConfirm = async (e) => {

		const values = _.cloneDeep(model)

		const data = {
			...values.edit,
		}

		const apiPermissions = []

		for (const api of apis) {
			const apiSelected = data.apis && data.apis[api.id]
			if (apiSelected) {
				apiPermissions.push({
					apiId: api.id,
					consult: apiSelected.indexOf('consult')>-1,
					edit: apiSelected.indexOf('edit')>-1,
					exclude: apiSelected.indexOf('exclude')>-1,
					include: apiSelected.indexOf('include')>-1,
					approve: apiSelected.indexOf('approve')>-1,
				})
			}
		}
		// console.log('data', data)
		delete data.apis
		data.apiPermissions = apiPermissions

    	setSaving(true)
    	profiles.actions.save({
				formId,
				id:viewId,
				data,
			})
			// .then(sleep(1000))
			.then(() => {
				setSaved(true)
				onConfirm()
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
				setSaving(false)
				setSaved(false)
			})

	}

	useEffect(() => {
		if (!apis) return

		const fields = [
			{
				id:'titleProfiles',
				caption:'Permissões do perfil',
				type:'title',
			},
			{
				id:'edit.name',
				caption:'Nome do perfil',
				type:'text',
				required:true,
				row:true,
				readOnly,
				col:12
			},
		]

		for (const api of apis) {
			fields.push({
				id:`edit.apis.${api.id}`,
				caption:api.nameTranslated,
				type    : 'checkgroup',
				options:apiOptions,
				required:false,
				vertical:true,
				readOnly,
				// row:true,
				col:4,
			})
		}

		setStatusScreens([
			{
				id:formId,
				fields : fields,
			},
		])
	}, [viewId, apis]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	const handleCancel = () => {
    	onCancel()
	}


	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <>
			<SweetAlert
				// title={<h7 className="text-primary font-weight-700 font-family-title font-size-rem3 text-align-left">Permissões do perfil</h7>}
				// warning={!error && !saving}

				style={{
					overflow:'auto',
					// marginTop:'0px',
					width:'75%',
					maxHeight:'100%',
					// minHeight:'300px',
					justifyContent:'flex-start',

				}}
				openAnim={{ name: 'opacityIn', duration: 500 }}
				//closeAnim={{ name: 'slideOut', duration: 500 }}

				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						<Row className="pr-4 ps-4">
							<Col md={12} className="pt-2 ">
								<Row >
								<Col md={12} className="w-100 d-block align-items-start">
									<FormWizard
										validationInRealTime={true}
										screenId={formId}
										screens={statusScreens}
										formId={formId}
										// OnInputValueChange={handleInputValueChange}
										tabId={formId}
									/>
								</Col>
								</Row>
								<Row >
									<Col md={12} className="d-flex align-items-center justify-content-center">
										<Button
											autoFocus
											className="btn btn-outline-danger"
											onClick={handleCancel}
											style={{margin:'1em'}}
										>
											Fechar
										</Button>
										<UserCanAll permission="profiles.edit">
											<Button
												loading={saving}
												disabled={!!formValidationResult?.first /*|| viewId==1*/ }
												colorIcon={true}
												className="btn btn-primary "
												// disabled={currentScreen==0}
												style={{margin:'1em'}}
												onClick={handleConfirm}
												>
												Salvar
											</Button>
										</UserCanAll>

									</Col>
								</Row>
							</Col>
						</Row>
					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default ProfilesView;
