import React from "react"
import api from "../helpers/gestora/api"
import {formatDocument} from "../renders/utils";
import {clients} from "../overmind/clients/state"

const loadedOptions = {}

const remoteLists = {

	ProfessionalOccupation : {
		url : '/carrers',
		dataId : 'id',
		dataCaption : 'name',
	},

	Country : {
		url : '/countries',
		dataId : 'id',
		dataCaption : 'name',
	},

	Function : {
		url : '/functions/all',
		dataId : 'id',
		dataCaption : 'name',
	},

	FamilyRelation : {
		url : '/clientPep/relations/1',
		dataId : 'id',
		dataCaption : 'name',
	},

	CloseRelation : {
		url : '/clientPep/relations/2',
		dataId : 'id',
		dataCaption : 'name',
	},

	Bank : {
		url : '/banks/all',
		reducer : function(map, obj) {
			map.push({
				id:obj.id,
				caption: obj.active === 1
					? `${obj.code} - ${obj.name}`
					: `${obj.code} - ${obj.name} (Inativo)`
					// : <span>{obj.code} - {obj.name} <span className="text-danger">(banco inativo)</span></span>
			})
			return map;
		}
	},

	Associate : {
		cache : false,
		url : ({clients=null}) => {
			// console.log('clients?.state?.currentRegistrationForm', clients?.state?.currentRegistrationForm?.form)
			const url = clients?.state?.currentRegistrationForm
				? `/associates/shortCustom/${clients?.state?.currentRegistrationForm?.form.client?.associateId}`
				: `/associates/short`
			return url
		},
		reducer : function(map, obj) {
			// map.push({id:obj.id, caption:`${obj.hubspotCode} - ${obj.name}`})
			map.push({
				id:obj.id,
				caption: obj.active === true
					? `${obj.shortName || obj.name} (${formatDocument(obj.document)})`
					: `${obj.shortName || obj.name} - ${formatDocument(obj.document)} (Inativo)`
			})



			return map;
		}
	},

	Funds : {
		url : '/funds/partner/1',
		dataId : 'id',
		dataCaption : 'name',
	},

	InvestmentFunds : {
		url : 'admin/investments/fundtypes',
		dataId : 'id',
		dataCaption : 'name',
	},

	ActiveRiskProfile : {
		url : `riskProfiles/getActiveProfileList`,
		dataId : 'id',
		dataCaption : 'name',
	},

	UserProfile : {
		url : `profiles`,
		dataId : 'id',
		dataCaption : 'name',
	},


}

export const registerRemoteList = (listId, config) => {
	remoteLists[listId] = config
}

export const reloadRemoteList = async  (listId) => {
	delete loadedOptions[listId]
	await loadOptions(listId)
}

export const loadOptions = (listId, overmind) => {
	const listConfig = remoteLists[listId]

	// console.log('overmind', overmind)
	const {clients=null} = overmind || {}

	if (!listConfig) {
		console.log("List Id not registered: ", listId)
		return Promise.resolve([]);
	}

	if (loadedOptions[listId] && listConfig.cache!==false) {
		//console.log('FROM CACHE', listId)
		return Promise.resolve(loadedOptions[listId]);
	}

	const url = typeof listConfig.url === 'function'
		? listConfig.url({clients})
		: listConfig.url

	//console.log("LOADING", listId)
	return api
		.get(url)
		.then(async (response) => {

			const reducer = listConfig.reducer || function(map, obj) {
				map.push({id:obj[listConfig.dataId], caption:obj[listConfig.dataCaption]})
				return map;
			}

			loadedOptions[listId] = response.data.reduce(reducer, []);

			//console.log("LOADED", listId)
			return loadedOptions[listId]
		})
		.catch(async (error) => {
			console.log('Error loading list ', {listId, error})
			return []
		});
}

export const loadAllRemoteLists = async () => {
	console.log('Loading all remote lists...')
	const promises = []
	for (const listId in remoteLists)
		promises.push(loadOptions(listId))

	return Promise.all(promises)
}

