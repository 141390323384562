import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
} from "reactstrap"

function KeyValueInfo(
{
	data,
	title,
	...props
}) {

	return (
		<Row>
			<Col md={12}>
				{title && <Row  >
					<Col md={6} className="pt-2 p-0 pe-1 xborder border-dark d-flex justify-content-md-end align-items-md-end ">
						<h5 className="text-primary font-family-title font-weight-700 font-size-em1 text-align-right">{title}</h5>
					</Col>
					<col md={8} className=""></col>
				</Row>}


                {data && data.map((item, index) => {

                	return <Row key={index} className="m-1">
						<Col md={6}
							 style={{textAlign:'right'}}
							 className="xborder border-dark d-flex justify-content-md-end align-items-md-end font-weight-semibold"
						>{item.caption}</Col>
						<Col md={6} className="xborder border-dark align-items-start" style={{textAlign:'left'}}>{
							(item.formatter ? item.formatter(item.value) : item.value) || '-'
						}</Col>
					</Row>
				})}

			</Col>
		</Row>
	)
}

export default KeyValueInfo;
