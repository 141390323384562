import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
	Button,
	Card,
	CardBody,
	CardTitle,
	CardText,
	TabPane,
	NavLink,
	NavItem,
	TabContent,
	Nav,
	Table,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import { useOvermind } from "../../../../overmind";

import DropDown from "../../../../components/Common/DropDown/DropDown";

import {
	registrationFormRenewStatusMapped,
	registrationFormRenewReasonMapped,
	RegFormApprovalRenewEnum
} from "../../../../constants/formLists";

// import lStorage from "../../../../helpers/gestora/lStorage";
// import FormTitleDefault from "../../../../components/Common/CustomForm/SubTitles/TitleDefault";
import Field from "../../../../components/Common/CustomForm/Field";
import api, {sleep} from "../../../../helpers/gestora/api";
import {dateIsoToBr} from "../../../../renders/utils";
import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";
import {UserCan} from "../../../../helpers/gestora/auth";
import ChangeRenewStatusModal from "../Modals/ChangeRenewStatusModal";


function RenewStatus(props) {

	const {formId} = props
	//alert(formId)
    const {
        state   : {
            [formId]:{currentGroup, currentScreen, model}
        },
        actions : {
            setFormState, setCurrentScreen, setCurrentStep, setFormModel
        }
    } = useOvermind('forms')

	const client = useOvermind('clients')

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [history, setHistory] = useState([])
	const [currentStatus, setCurrentStatus] = useState(null)
	const [regFormStatus, setRegFormStatus] = useState(null)
	const [changingStatus, setChangingStatus] = useState(null)
	const [refreshCount, setRefreshCount] = useState(0)

	useEffect(() => {
		console.log('client.state.currentRegistrationForm.isApprovedRenew', client.state.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew)
	}, [])

	const loadData = () => {
		setLoading(true)
		const url = `registrationForm/renewStatus/${client.state.registrationFormId}/1`
		//alert(url)
		api.get(url)
			//.then(sleep(2000))
			.then(res => {
				console.log('registrationForm/renewStatus', res.data)

				if (res.data.length>0) {
					setCurrentStatus(parseStatus(res.data[0]))
					client.actions.setIsApprovedRenew(res.data[0]?.status)
				}
				setRegFormStatus(

					statusOptions.find(option => option.id == client.state.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew)

				)
				setHistory(res.data)
			})
			.catch(e => {
				setError('Não foi possível carregar as informações de status de renovação')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (refreshCount===undefined && refreshCount>0)
			return

		loadData()

	}, [refreshCount])

	// useEffect(() => {
	// 	loadData()
	// }, [])



	const parseStatus = (row) => {
		return {
			date	:dateIsoToBr(row.createDate, null, 'DD/MM/YYYY [às] HH:mm:ss'),
			status	:registrationFormRenewStatusMapped[row.status]?.caption || row.status,
			reason	:registrationFormRenewReasonMapped[row.reason]?.caption || '-',
			message	:row.message,
			userName:row.user?.name || row.userId || <b>Sistema</b>,
			row,
		}
	}

	const statusOptions = [
		{id:RegFormApprovalRenewEnum.PENDING,		caption:"Renovação Pendente", class:'light', color:'black', icon:"bx bx-file-blank"},
		{id:RegFormApprovalRenewEnum.IN_REVIEW,		caption:"Renovação em análise", class:'dark-blue', color:'primary', icon:"bx bxs-bank"},
		{id:RegFormApprovalRenewEnum.OK,			caption:"Renovação OK", class:'success', color:'success', icon:"bx bx-badge-check"},
		{id:RegFormApprovalRenewEnum.NOT_APPROVED,	caption:"Renovação reprovada", class:'danger', color:'danger', icon:'bx bx-block'},
	]

    return (
        <>
		{changingStatus && <ChangeRenewStatusModal
			formId={formId}
			changingStatus={changingStatus}
			onConfirm={async (id) => {
				await client.actions.setIsApprovedRenew(id)
				setRefreshCount(refreshCount+1)

				setTimeout(() => {
					setChangingStatus(null)
				}, 2000)
				//setFormState({formId, key:'readOnly', value:true})
			}}
			onCancel={() => {
				setChangingStatus(null)
			}}
		/> }
		<LoadingSection
			loading={loading}
			error={error}
			noData={!model?.client?.haveRenews ? "Cliente não possui renovação cadastral" : null}
		>
            <Row className="justify-content-center ">
				<Col md={12} >

						<Col className={" mt-0 mb-0"}>

							{statusOptions &&
								<DropDown
									disabled={!UserCan('clients.edit')}
									placeHolder="Nenhum status definido"
									options={statusOptions}
									// defaultValue={currentStatus?.row?.status}
									selected={statusOptions.find(option => option.id == client.state.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew)}
									onChange={option => {
										if (
											option.id!=client.state.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew
										) {
											setChangingStatus(option)
										}
									}}
								/>
							}
							{currentStatus && (
								<div className="p-2 ps-0">
									<strong>por: </strong>{currentStatus?.userName} em {currentStatus?.date}
								</div>
							)}
						</Col>

						{currentStatus && (
						<Col className={"mt-3"}>
							<Field
								type="title"
								formId={formId}
								caption="Histórico"
							/>
							<div className="table-responsive">
								<Table className="table mb-0">
									<thead>
									<tr>
										<th>Data e horário</th>
										<th>Status</th>
										<th>Motivo</th>
										<th>Mensagem</th>
										<th>Usuário</th>
									</tr>
									</thead>
									<tbody>
									{history.map((historyItem)=> {
										const parsedItem = parseStatus(historyItem)
										return (
											<tr key={historyItem.id}>
												<th width={100} scope="row">{parsedItem.date}</th>
												<td>{parsedItem.status}</td>
												<td>{parsedItem.reason}</td>
												<td>{parsedItem.message}</td>
												<td>{parsedItem.userName}</td>
											</tr>
										)
									})}
									</tbody>
								</Table>
							</div>

						</Col>
						)}
				</Col>
			</Row>
		</LoadingSection>
        </>
    )
}

export default RenewStatus;
