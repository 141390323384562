const _ = require('lodash')
import moment from "moment"
moment.locale('pt-BR')

export const genreOptions = [
	{id:'MASCULINO', caption:"Masculino"},
	{id:'FEMININO', caption:"Feminino"},
]

export const maritalStatusOptions = [
	{ id: 1, caption: 'Solteiro' },
	{ id: 2, caption: 'Casado' },
	{ id: 3, caption: 'Viúvo' },
	{ id: 4, caption: 'Divorciado' },
	{ id: 5, caption: 'Separado' },
];

export const deviceTypesOptions = [
	{id:'mobile', caption:"Celular"},
	{id:'tablet', caption:"Tablet"}, //, canRemove:false
	{id:'browser', caption:"Desktop"},
]
export const deviceTypesMapped = _.keyBy(deviceTypesOptions, 'id')


export const clientStatusOptions = [
	{id:'1', caption:"Ativo"},
	{id:'0', caption:"Inativo"}, //, canRemove:false
]

export const clientStatusMapped = _.keyBy(clientStatusOptions, 'id')


export const fatcaRequestTypes = [
	{ id: 'fatca', caption: 'Estados Unidos - FATCA' },
	{ id: 'crs', caption: 'Demais países - CRS' },
];

export const fatcaNotFiscalResidentOptions = [
	{ id: 'isEuaLearner', caption: '1 - Sou estudante, professor, trainee ou estagiário de uma entidade de ensino norte-americana ou participo de um programa de intercâmbio cultural ou educacional e possuo o visto para presença nos E.U.A. especificamente relacionado a essas modalidades de estadia (vistos "F", "J", "M", ou "Q").' },
	{ id: 'isPolitical', caption: '2 - Nos E.U.A., atuo como diplomata estrangeiro ou ocupo cargos em consulados, embaixadas ou organizações internacionais.' },
	{ id: 'isResponsible', caption: '3 - Sou esposa(o) ou filho solteiro menor de 21 (vinte e um) anos de pessoas que atendem às condições 1 ou 2.' },
	{ id: 'isNotIrs', caption: '4 - Não foi caracterizada minha presença substancial nos E.U.A. conforme estabelece o "Substantial Presence Test" pelo IRS - Internal Revenue Service (site: https://www.irs.gov/Individuals/ International-Taxpayers/ Substantial-Presence-Test).' },
	{ id: 'isResignedCln', caption: '5 - Abdiquei da nacionalidade ou cidadania dos E.U.A. conforme Certificado de Perda de Nacionalidade (Certificate of Loss Of Nationality), emitido pelo Bureau of Consular Affairs do Estado dos E.U.A., anexo a esta declaração.' },
	{ id: 'isResignedCitizen', caption: '6 - Apesar de ter nascido nos E.U.A., conforme declaração anexa, renunciei à cidadania dos E.U.A. (embora ainda não possua o Certificado correspondente) ou não adquiri tal cidadania ao nascer.' },
	{ id: 'isWithoutGcNotRequested', caption: '7 - Não possuo visto do tipo "Green Card" e não solicitei tal visto aos E.U.A.' },
	{ id: 'isWithoutGc', caption: '8 - Abdiquei do meu visto do tipo "Green Card", conforme Certificado de Abandono do Green Card, emitido por autoridade consular dos E.U.A. no Brasil, anexo a e sta declaração.' },
	{ id: 'isWithoutFiscalResidence', caption: '9 - Não possuo residência fiscal nos E.U.A., apesar de apresentar em meu cadastro indícios de ser US Person, tais como: endereço, telefone, caixa postal nos EUA, procurador com endereço nos E.U.A. ou transferência de recurso para os E.U.A.' },
];

export const ethnicityOptions = [
	{ id: 1, caption: 'Branco' },
	{ id: 2, caption: 'Preto' },
	{ id: 3, caption: 'Amarela' },
	{ id: 4, caption: 'Parda' },
	{ id: 5, caption: 'Indígena' },
	{ id: 6, caption: 'Sem declaração' },
];

export const relationConnectionOptions = [
	{ id: 1, caption: 'Familiar' },
	{ id: 2, caption: 'Relacionamento Próximo' }
];


export const docTypes = {
	INCAPACITY: 1,
	REPRESENTATIVE_ATTORNEY: 2,
	ATTORNEY_PROBATION: 3,
	RENOUNCE: 5,
	ADDRESS: 6,
	PEP: 7,
	FATCA: 8,
	REGISTRATION_FORM: 9,
	SIGNATURE: 4,
	ADHESION_THERM: 10,
	DOCUMENT_PHOTO_FRONT: 11, // foto documento frontal
	DOCUMENT_PHOTO_BACK: 12, // fodo documento traseira
	BANK_SHEET: 13,
	RENEW_FORM : 14, // formulário de renovação

	FATCA_FORM_EUA : 15,
	CERTIFICATE_LOST_NATIONALITY : 16,
	DECLARATION_RESIGNED_CITIZENSHIP_EUA : 18,
	DECLARATION_RESIGNED_GREENCARD : 19,
	SERVICE_PROPOSAL : 20,

}

export const docTypesCaption = {
	[docTypes.INCAPACITY] : "Comprovante de Incapacidade",
	[docTypes.REPRESENTATIVE_ATTORNEY] : "Representante/Procurador",
	[docTypes.RENOUNCE] : "Renúncia de nacionalidade",
	[docTypes.ADDRESS] : 'Comprovante de endereço',
	[docTypes.PEP] :  'Documento PEP',
	[docTypes.FATCA] : 'Documento FATCA',
	[docTypes.REGISTRATION_FORM] : "Ficha cadastral pessoa física",
	[docTypes.SIGNATURE] : "Imagem de assinatura",
	[docTypes.ADHESION_THERM] : "Termo de adesão",
	[docTypes.BANK_SHEET] : "Planilha Bradesco",

	// [docTypes.FATCA_FORM_EUA] : "Formulário Fatca - Estados Unidos",
	[docTypes.CERTIFICATE_LOST_NATIONALITY] : "Certificado de perda de nacionalidade",
	[docTypes.DECLARATION_RESIGNED_CITIZENSHIP_EUA] : "Declaração de renúncia de cidadania EUA",
	[docTypes.DECLARATION_RESIGNED_GREENCARD] : "Certificado de abandono de Green Card",
	[docTypes.SERVICE_PROPOSAL] : "Proposta Comercial",
}

export const docFundConst = {
	RF : '1',
	RV : '3',
	MM : '2',
	INT : '4',
}

export const docFundTypes = {
	[docFundConst.RF] : {caption:'Renda Fixa', id:docFundConst.RF, short:'RF', gestoraFundId:1},
	[docFundConst.RV] : {caption:'Ações', id:docFundConst.RV, short:'RV', gestoraFundId:2},
	[docFundConst.MM] : {caption:'Multimercado', id:docFundConst.MM, short:'MM', gestoraFundId:3},
	[docFundConst.INT] : {caption:'Internacional', id:docFundConst.INT, short:'IT', gestoraFundId:4},
}

export const fiscalReferenceOptions = [
	{ id: 1, caption: 'Para informações relativas ao país de nascimento' },
	{ id: 2, caption: 'Para informações relativas à nacionalidade' },
	{ id: 3, caption: 'Para informações relativas à residência fiscal' },
	{ id: 4, caption: 'Para informações relativas ao visto de residência permanente' },
];

export const nationalityOptions = [
	{ id: 1, caption: '(loadNationality)' },
];

export const countryOptions = [
	{ id: 1, caption: '(loadnaCountry)' },
];

export const civilCapacityOptions = [
	{ id: 1, caption: 'Maior' },
	{ id: 2, caption: 'Menor' },
	{ id: 3, caption: 'Incapaz' },
];

export const riskRrofileOptions = [
	{ id: 1, caption: 'CONSERVADOR' },
	{ id: 2, caption: 'MODERADO' },
	{ id: 3, caption: 'BALANCEADO' },
	{ id: 4, caption: 'SOFISTICADO' },
	{ id: 5, caption: 'ESPECIALISTA' },
];

export const yesNoOptions = [
	{ id: '1', caption: 'Sim' },
	{ id: '0', caption: 'Não' },
];

export const apiMockedOptions = [
	{ id: '1', caption: 'Opção 1 (api)' },
	{ id: '2', caption: 'Opção 2 (api)' },
];

export const statesBrOptions = [
	{ id: 'AC', caption: 'AC' },
	{ id: 'AL', caption: 'AL' },
	{ id: 'AM', caption: 'AM' },
	{ id: 'AP', caption: 'AP' },
	{ id: 'BA', caption: 'BA' },
	{ id: 'CE', caption: 'CE' },
	{ id: 'DF', caption: 'DF' },
	{ id: 'ES', caption: 'ES' },
	{ id: 'GO', caption: 'GO' },
	{ id: 'MA', caption: 'MA' },
	{ id: 'MG', caption: 'MG' },
	{ id: 'MS', caption: 'MS' },
	{ id: 'MT', caption: 'MT' },
	{ id: 'PA', caption: 'PA' },
	{ id: 'PB', caption: 'PB' },
	{ id: 'PE', caption: 'PE' },
	{ id: 'PI', caption: 'PI' },
	{ id: 'PR', caption: 'PR' },
	{ id: 'RJ', caption: 'RJ' },
	{ id: 'RN', caption: 'RN' },
	{ id: 'RO', caption: 'RO' },
	{ id: 'RR', caption: 'RR' },
	{ id: 'RS', caption: 'RS' },
	{ id: 'SC', caption: 'SC' },
	{ id: 'SE', caption: 'SE' },
	{ id: 'SP', caption: 'SP' },
	{ id: 'TO', caption: 'TO' }
]

export const RegFormApprovalEnum = {
	STARTED: '0',
	PENDING: '1',
	APPROVED: '2',
	NOT_APPROVED: '3',
	SENT_TO_BANK: '5',
	PENDING_ADJUSTMENT: '4'
}

export const registrationFormStatusOptions = [
	{ id: RegFormApprovalEnum.STARTED, caption: 'Cadastro iniciado' },
	{ id: RegFormApprovalEnum.PENDING, caption: 'Análise Fiduc' },
	{ id: RegFormApprovalEnum.APPROVED, caption: 'Aprovado' },
	{ id: RegFormApprovalEnum.NOT_APPROVED, caption: 'Reprovado' },
	{ id: RegFormApprovalEnum.PENDING_ADJUSTMENT, caption: 'Pendente de ajuste' },
	{ id: RegFormApprovalEnum.SENT_TO_BANK, caption: 'Enviado ao Bradesco' },
]

export const registrationFormStatusMapped = _.keyBy(registrationFormStatusOptions, 'id')


export const RegFormApprovalRenewEnum = {
	OK: '0',
	NOT_APPROVED: '1',
	IN_REVIEW: '2',
	PENDING: '3',
}

export const registrationFormRenewStatusOptions = [
	{ id: RegFormApprovalRenewEnum.PENDING, caption: 'Renovação Pendente' },
	{ id: RegFormApprovalRenewEnum.IN_REVIEW, caption: 'Em análise' },
	{ id: RegFormApprovalRenewEnum.OK, caption: 'Renovação OK' },
	{ id: RegFormApprovalRenewEnum.NOT_APPROVED, caption: 'Reprovado' },
]

export const registrationFormRenewStatusMapped = _.keyBy(registrationFormRenewStatusOptions, 'id')

export const registrationFormRenewReasonOptions = [
	{ id: 1, caption: 'Não passou KYC Bradesco' },
	{ id: 3, caption: 'Outros' },
]

export const registrationFormRenewReasonMapped = _.keyBy(registrationFormRenewReasonOptions, 'id')


export const registrationFormPendingReasonOptions = [
	{ id: 1, caption: 'Documento' },
	{ id: 2, caption: 'Comprovante Residência' },
	{ id: 3, caption: 'Outros' },
]

export const registrationFormPendingReasonMapped = _.keyBy(registrationFormPendingReasonOptions, 'id')

export const investmentStatusCanceledReasonOptions = [
	{ id: 1, caption: 'Recurso não enviado' },
	{ id: 2, caption: 'Termo de Adesão não enviado' },
	{ id: 3, caption: 'Solicitação Cliente' },
	{ id: 4, caption: 'Termo de Desenquadramento não enviado' },
	{ id: 5, caption: 'Nova Conta Bancária não Cadastrada' },

	{ id: 6, caption: 'Ordem em duplicidade' },
	{ id: 7, caption: 'Saldo mínimo de permanência' },
	{ id: 8, caption: 'Resgate total em andamento' },
]

export const investmentStatusCanceledReasonMapped = _.keyBy(investmentStatusCanceledReasonOptions, 'id')

export const investmentStatusPendingReasonOptions = [
	{ id: 1, caption: 'Análise Fiduc' },
	{ id: 2, caption: 'Aguardando Recursos' },
	{ id: 3, caption: 'Preenchimento/envio de Termo de Adesão' },
	{ id: 4, caption: 'Preenchimento/envio de Termo de Desenquadramento' },
	{ id: 5, caption: 'Aguardando cadastro de nova conta para transferência de valores' },
]

export const investmentStatusPendingReasonMapped = _.keyBy(investmentStatusPendingReasonOptions, 'id')

export const investmentStatusEnum = {
	PENDING: 'pending',
	CREATED: 'created',
	CANCELED: 'canceled',
}

export const investmentStatusOptions = [
	{ id: investmentStatusEnum.PENDING, caption: 'Em processamento', color:'secondary' },
	{ id: investmentStatusEnum.CREATED, caption: 'Realizada', color:'success' },
	{ id: investmentStatusEnum.CANCELED, caption: 'Cancelada', color:'danger' },
]

export const investmentStatusMapped = _.keyBy(investmentStatusOptions, 'id')

export const riskProfileEnum = {
	CONSERVATIVE: 1,
	MODERATED: 2,
	BALANCED: 3,
	SOPHISTICATED: 4,
	SPECIALIST: 5,
}

export const riskProfileOptions = [
	{ id: riskProfileEnum.CONSERVATIVE, caption: 'CONSERVADOR' },
	{ id: riskProfileEnum.MODERATED, caption: 'MODERADO' },
	{ id: riskProfileEnum.BALANCED, caption: 'BALANCEADO' },
	{ id: riskProfileEnum.SOPHISTICATED, caption: 'SOFISTICADO' },
	{ id: riskProfileEnum.SPECIALIST, caption: 'ESPECIALISTA' },
]

export const riskProfileMapped = _.keyBy(riskProfileOptions, 'id')

export const investmentOperationEnum = {
	APPLY: 'apply',
	RESCUE_PARCIAL: 'rescue_parcial',
	RESCUE_TOTAL: 'rescue_total',
}

export const investmentOperationOptions = [
	{ id: investmentOperationEnum.APPLY, caption: 'Aplicação', shortCaption:'A' },
	{ id: investmentOperationEnum.RESCUE_PARCIAL, caption: 'Resgate Parcial', shortCaption:'RP'  },
	{ id: investmentOperationEnum.RESCUE_TOTAL, caption: 'Resgate Total', shortCaption:'RT'  },
]

export const investmentOperationMapped = _.keyBy(investmentOperationOptions, 'id')

export const professionalTypeEnum = {
	WITHOUT_BOND: 0,

	SALARIED: 1,
	LIBERAL: 2,

	BUSINESSPERSON: 3,
	RETIRED: 4,
	CAPITALIST: 5,
	STUDENT : 6,
	HOUSEKEEPER : 7,
}

export const professionalTypeOptions = [
	{id:professionalTypeEnum.WITHOUT_BOND.toString(), caption:'Não possui vínculo empregatício (antigos)'},

	{id:professionalTypeEnum.SALARIED.toString(), caption:'Assalariado'},
	{id:professionalTypeEnum.LIBERAL.toString(), caption:'Profissional liberal'},

	{id:professionalTypeEnum.BUSINESSPERSON.toString(), caption:'Empresário'},
	{id:professionalTypeEnum.RETIRED.toString(), caption:'Aposentado'},
	{id:professionalTypeEnum.CAPITALIST.toString(), caption:'Capitalista'},
	{id:professionalTypeEnum.STUDENT.toString(), caption:'Estudante'},
	{id:professionalTypeEnum.HOUSEKEEPER.toString(), caption:'Do lar'},
]

export const professionalTypeMapped = _.keyBy(professionalTypeOptions, 'id')

export const datePeriodOptions = [
	{ id: 'today', caption: 'Hoje', value:[
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
	{ id: 'yesterday', caption: 'Ontem', value:[
			//moment().startOf('month').format('YYYY-MM-DD'),
			moment().subtract(1, 'days').format('YYYY-MM-DD'),
			moment().subtract(1, 'days').format('YYYY-MM-DD'),
	]},
	{ id: 'thisWeek', caption: 'Semana Atual', value:[
			moment().startOf('week').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
	{ id: 'thisMonth', caption: 'Mês Atual', value:[
			moment().startOf('month').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
	{ id: 'thisYear', caption: 'Desde o início do ano', value:[
			moment().startOf('year').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
]

export const datePeriodMapped = _.keyBy(datePeriodOptions, 'id')

export const datePeriodOptionsMonthly = [
	{ id: 'last3Months', caption: 'Últimos 3 meses', value:[
			moment().startOf('month').subtract(2, 'months').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
	{ id: 'last6Months', caption: 'Últimos 6 meses', value:[
			moment().startOf('month').subtract(5, 'months').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
	{ id: 'last9Months', caption: 'Últimos 9 meses', value:[
			moment().startOf('month').subtract(8, 'months').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
	{ id: 'thisYear', caption: 'Desde o início do ano', value:[
			moment().startOf('year').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD'),
	]},
]

export const datePeriodMappedMonthly = _.keyBy(datePeriodOptionsMonthly, 'id')

