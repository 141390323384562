import {clientLocalToApi} from "../../renders/registrationForm/localToApi";

const _ = require('lodash')
import api from "../../helpers/gestora/api"

import { clientApiToLocal } from "../../renders/registrationForm/apiToLocal";
import {dateBrToISO, dateTimestampToISO, onlyNumbers} from "../../renders/utils";
import {AuthInfo} from "../../helpers/gestora/auth";
import {docTypes, docTypesCaption, docFundTypes} from "../../constants/formLists";

import * as Scroll from 'react-scroll';
import {registerRemoteList} from "../../constants/remoteLists";

//fiduc/clients/download/base64/files/1091/1

export const setRegistrationFormId = ({state}, registrationFormId ) => {
	state.clients.registrationFormId = registrationFormId
}

export const setTemporaryPassword = ({state}, temporaryPassword ) => {
	state.clients.temporaryPassword = temporaryPassword
}

export const setScoreKYC = ({state}, score ) => {
	state.clients.scoreKYC = score
}

export const setIsApprovedAdm = ({state}, isApprovedAdm ) => {
	if (state.clients.currentRegistrationForm?.form?.registrationForm) {
		state.clients.currentRegistrationForm.form.registrationForm.isApprovedAdm = isApprovedAdm
	}
}

export const setIsApprovedRenew = ({state}, isApprovedRenew ) => {
	if (state.clients.currentRegistrationForm?.form?.registrationForm) {
		state.clients.currentRegistrationForm.form.registrationForm.isApprovedRenew = isApprovedRenew
	}
}

export const resetState = ({state}) => {
	state.clients.registrationFormId = 0
	state.clients.currentRegistrationForm = ''
}

export const loadZapsignDocuments = async ({state} ) => {

	const userId = state.clients.currentRegistrationForm?.form?.client?.id
	if (!userId)
		return Promise.resolve(null)

	return api
		.get(`documentUploads/zapsign/${userId}`)
		.then(res => {
			return res.data
		})
}

export const getClientLogDashboard = async ({state}, data ) => {
	return api
		.post(`admin/clientaccesslog/count`, data)
		.then(res => res.data)
}


export const loadRegistrationForm = async ({actions, state}, registrationFormId ) => {

	console.log('loadRegistrationForm !!!!!!!!!!! ')

	//try {
		state.clients.registrationFormId = registrationFormId
		state.clients.temporaryPassword = ''


		//REGISTRATION FORM
		const regFormRes = await api.get(`registrationForm/${registrationFormId}`)

		//console.log('ACCOUNTS', regFormRes?.data?.holderBankInformation?.accounts)

		//CLIENT INFORMATION
		const clientId = regFormRes?.data?.clientHolder?.id
		const clientDocument = regFormRes?.data?.clientHolder?.document
		const clientRes = await api.get(`clients/${clientId}`)
		// console.log({clientRes:clientRes.data})

		if (clientRes.data?.riskProfileCategory?.id) {
			registerRemoteList(`RiskProfileCategory${clientRes.data.riskProfileCategory.id}`, {
				url : `riskProfiles/getByProfileCategory/${clientRes.data.riskProfileCategory.id}`,
				dataId : 'id',
				dataCaption : 'name',
			})
		}

		//PROFESSIONAL INFORMATION
		let professionalRes
		try {
			professionalRes = await api.get(`professionalInformations/client/${clientId}`)
		} catch(e) {}

		//MAILING
		let mailingRes
		if (regFormRes?.data?.mailing) {
			try {
				mailingRes = await api.get(`mailing/${regFormRes?.data?.mailing?.id}`)
			} catch(e) {}
		}

		//PEP
		let pepRes
		if (clientRes?.data?.haveWorked || clientRes?.data?.haveRelation) {
			try {
				pepRes = await api.get(`clientPep/client/${clientId}`)
			} catch(e) {}
		}

		//FATCA
		let fatcaRes
		// if (clientRes?.data?.haveFatca){
			try {
				fatcaRes = await api.get(`fatcas/${clientId}`)
			} catch(e) {}
		// }

		//Suitability
		let suitabilityRes
		// if (clientRes?.data?.haveFatca){
			try {
				suitabilityRes = await api.get(`suitabilities/getClientSuitability/${clientId}`)
				state.clients.suitabilityQuestions = suitabilityRes?.data?.suitability
			} catch(e) {
				state.clients.suitabilityQuestions = ''
			}
		// }


		//Renew Status
		let renewStatusRes
		try {
			renewStatusRes = await api.get(`renew/status/${clientId}`)
		} catch(e) {}

		const {needFormFatcaEUA, haveRenews} = await actions.renew.loadNeedFormFatcaEUA({clientId})

		// //Funds
		// let fundsRes, mergedFunds
		// try {
		// 	fundsRes = await api.get(`investments/references/fundtypes/listWithZapSigners/${clientId}`, {
		// 		params : {
		// 		}
		// 	})
		//
		// 	// mergedFunds = mergeFundsSigned(documentRes?.data, fundsRes?.data)
		// 	//console.log('mergedFunds', mergedFunds)
		// } catch(e) {}


		const apiData = {
			client:clientRes?.data,
			regForm:regFormRes?.data,
			professionalInformation:professionalRes?.data,
			mailing:mailingRes?.data,
			pep:pepRes?.data,
			fatca:fatcaRes?.data,
			suitability : suitabilityRes?.data,
			renewStatus : renewStatusRes?.data,
			needFormFatcaEUA, haveRenews
		}

		console.log('API DATA', apiData)
		const form = clientApiToLocal(apiData)

		const documentUploads = await loadDocumentUploads({state}, {clientId})

		return Promise.resolve({
			form :  form,
			documentUploads,
			inlineFiles : {
				personPhoto : clientRes?.data.personPhoto
			}
		});
	// } catch(error) {
	// 	console.log('ERROR LOADING REGFORM', error)
	// 	return Promise.reject(error);
	// }
}


export const loadDocumentUploads = async ({state}, {clientId} ) => {
	//DOCUMENT UPLOADS
	let documentRes
	try {
		documentRes = await api.get(`documentUploads`, {
			params : {
				clientId,
				//registrationFormId,
				size : 99999,
			}
		})

	} catch(e) {}

	const documentUploads = documentRes?.data?.documents
	await setDocumentUploadsAvailable({state}, documentUploads)
	console.log({documentUploads})
	return documentUploads
}

export const getDocumentName = (documentUpload) => {
	let name = null
	if (documentUpload.docType==docTypes) {
		name = "Termo de adesão de fundo"
	} else if (documentUpload.fundId) {
		name = `Termo de adesão - ${Object.values(docFundTypes).find(el => el.gestoraFundId==documentUpload.fundId)?.caption}` || name
	} else {
		name = docTypesCaption[documentUpload.docType] || name
	}
	return name
}

export const setDocumentUploadsAvailable = ({state}, documentUploads ) => {
	const documentUploadsAvailable = documentUploads?.reduce(function(map, obj) {
		const docName = getDocumentName(obj)
		if (docName) {
			map.push({
				id : obj.id,
				caption:docName,
				docType : obj.docType,
			})
		}
		return map;
	}, [])


	state.clients.documentUploadsAvailable =  _.orderBy(documentUploadsAvailable, 'caption', 'asc')
	//console.log('documentUploadsAvailable', {documentUploadsAvailable, documentUploads})
}

export const setCurrentRegistrationForm = ({state}, registrationForm ) => {
	// console.log('setCurrentRegistrationForm', registrationForm)
	state.clients.currentRegistrationForm = registrationForm
}

export const saveRegistrationFormStatus = async ({state}, {formId, status, description='', reason=0, createDate=null} ) => {
	const stateFormModel = state.forms[formId].model
	const registrationFormId = stateFormModel.client.registrationFormId
	try {
		await api.post(`registrationForm/approvalAdm/${registrationFormId}`, {approvedId:status})
		await api.post(`registrationForm/status`, {
			status,
			registrationFormId,
			createDate,
			userId : AuthInfo().id,
			message:description,
			reason
		})
		return Promise.resolve();
	} catch(error) {
		return Promise.reject(error);
	}
}

export const saveRegistrationFormRenewStatus = async ({state}, {formId, status, description='', reason=0, createDate=null} ) => {
	const stateFormModel = state.forms[formId].model
	const registrationFormId = stateFormModel.client.registrationFormId
	return api.post(`registrationForm/renewStatus`, {
		status,
		registrationFormId,
		createDate,
		userId : AuthInfo().id,
		message:description,
		reason
	})
}

export const cancelAssociateChange = async ({state}, clientId) => {
	return api.get(`clients/cancelAssociateChange/${clientId}`)
}

export const changeAssociateMany = ( { state }, {ids, associateId} ) => {

	return api
		.post(`clients/changeAssociateMany`, {ids, associateId})
		//.then(sleep(1000))

	//console.log(`saveInvestmentStatus`, params)

}

export const saveRegistrationForm = async ({state, actions}, {formId} ) => {



	try {
		//CLIENT INFO
		const stateFormModel = state.forms[formId].model

		state.clients.saving = true
		state.clients.done = false

		//console.log("LOCAL", form)

		//console.log("apiSaveData", apiData)
		const form =  _.cloneDeep(stateFormModel);
		const client = form.client
		const uploadedFiles =  _.cloneDeep(state.forms.regForm.uploadedFiles);

		if (!client.id) {
			state.clients.savingText = "Cadastrando novo cliente..."
			//alert('new user')

			//CREATE USER
			const createData = {
				name: client.name,
				email: client.email,
				phoneNumber: onlyNumbers(client.phoneNumber),
				document: onlyNumbers(client.document),
				birthDate: dateBrToISO(client.birthDate),
				personTypeId: Number(client.personTypeId || 1), //PF
				personColor: Number(client.personColor),
				password: 'Fiduc2020!',
				createRegForm:1,
			}
			const resCreate = await api.post(`/clients/create`, createData)
			stateFormModel.client.id = resCreate.data.id
			client.id = resCreate.data.id
			client.registrationFormId = resCreate.data.registrationForm.id
			//console.log('resCreate.data.id', resCreate.data.id)
//		alert('aq')

			//stateFormModel.client.id =
			//console.log('stateFormModel', stateFormModel)

			//CREATE REGISTRATION FORM
			const regData = {
				partnerId: 1,
				associateId: client.associateId,
				clientHolderId: client.id,
				needCoholder: null,
			};

			const resReg = await api.put(`registrationForm/${client.registrationFormId}`, regData)

			stateFormModel.client.registrationFormId = client.registrationFormId

			console.log('createData', {createData, resCreate, resReg})


			//return
		}


		//return

		const apiData = clientLocalToApi(stateFormModel)
		console.log('save apiData', apiData)

		const changedFiles = {}
		for (let d in uploadedFiles) {
			const file = uploadedFiles[d]
			if (!file.changed) continue
			changedFiles[d] = file
			// console.log('changed file', file)
		}

		if (changedFiles['client.personPhoto']) {
			apiData.client.personPhoto = changedFiles['client.personPhoto'].base64String
		}

		if (client.ocr?.documentType=='RG' ) {
			if (changedFiles['client.ocr.rg.documentPhotoFront'] || changedFiles['client.ocr.rg.documentPhotoBack']) {
				state.clients.savingText = "Salvando RG..."
				await api.put(`clients/update/${client.id}/ocr`, {
					registrationFormId: client.registrationFormId,
					documentPhotoFront : uploadedFiles['client.ocr.rg.documentPhotoFront'].base64String,
					documentPhotoBack : uploadedFiles['client.ocr.rg.documentPhotoBack'].base64String,
					isPhotoFront: false,
					documentType: "RG"
				})
			}
		} else if (client.ocr?.documentType=='RNE' ) {
			if (changedFiles['client.ocr.rne.documentPhotoFront'] || changedFiles['client.ocr.rne.documentPhotoBack']) {
				state.clients.savingText = "Salvando RNE..."
				await api.put(`clients/update/${client.id}/ocr`, {
					registrationFormId: client.registrationFormId,
					documentPhotoFront : uploadedFiles['client.ocr.rne.documentPhotoFront'].base64String,
					documentPhotoBack : uploadedFiles['client.ocr.rne.documentPhotoBack'].base64String,
					isPhotoFront: false,
					documentType: "RNE"
				})
			}
		} else if (client.ocr?.documentType=='CNH' ) {
			state.clients.savingText = "Salvando CNH..."
			if (changedFiles['client.ocr.cnh.documentPhotoFront']) {
				await api.put(`clients/update/${client.id}/ocr`, {
					registrationFormId: client.registrationFormId,
					documentPhotoFront : uploadedFiles['client.ocr.cnh.documentPhotoFront'].base64String,
					isPhotoFront: true,
					documentType: "CNH"
				})
			}
		}

		//proofOfResidence
		if (changedFiles['doccapture.proofOfResidence']) {
			state.clients.savingText = "Salvando comprovante de residência..."
			await api.put(`clients/proof-of-residence/${client.id}`, {
				registrationFormId: client.registrationFormId,
				proofOfResidence : uploadedFiles['doccapture.proofOfResidence'].base64String,
			})
		}

		//Responsible
		if (changedFiles['client.responsible.documentPhoto']) {
			state.clients.savingText = "Salvando documento de responsável/procurador..."
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.REPRESENTATIVE_ATTORNEY,
				document : uploadedFiles['client.responsible.documentPhoto'].base64String,
			})
		}

		//Incapacity
		if (changedFiles['client.responsible.incapacityPhoto']) {
			state.clients.savingText = "Salvando documento de incapacidade..."
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.INCAPACITY,
				document : uploadedFiles['client.responsible.incapacityPhoto'].base64String,
			})
		}

		//Renounce
		if (changedFiles['fatca.renouncedDocument']) {
			state.clients.savingText = "Salvando documento de renúncia..."
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.RENOUNCE,
				document : uploadedFiles['fatca.renouncedDocument'].base64String,
			})
		}

		//Form Fatca - EUA
		if (changedFiles['fatca.fatcaFormEua']) {
			state.clients.savingText = "Salvando formulário FATCA - Estados Unidos..."
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.FATCA_FORM_EUA,
				document : uploadedFiles['fatca.fatcaFormEua'].base64String,
			})
		}

		//fatcaFormLossCitizenship
		if (changedFiles['fatca.fatcaFormLossCitizenship']) {
			state.clients.savingText = "Salvando Certificado de perda de nacionalidade..."
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.CERTIFICATE_LOST_NATIONALITY,
				document : uploadedFiles['fatca.fatcaFormLossCitizenship'].base64String,
			})
		}

		//fatcaFormResignedCitizenshipEua
		if (changedFiles['fatca.fatcaFormResignedCitizenshipEua']) {
			state.clients.savingText = "Salvando  Declaração de renúncia de cidadania EUA"
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.DECLARATION_RESIGNED_CITIZENSHIP_EUA,
				document : uploadedFiles['fatca.fatcaFormResignedCitizenshipEua'].base64String,
			})
		}

		//fatcaFormResignedGreenCard
		if (changedFiles['fatca.fatcaFormResignedGreenCard']) {
			state.clients.savingText = "Salvando  Certificado de abandono de Green Card"
			await api.post(`documentUploads`, {
				clientId:client.id,
				registrationFormId: client.registrationFormId,
				docType:docTypes.DECLARATION_RESIGNED_GREENCARD,
				document : uploadedFiles['fatca.fatcaFormResignedGreenCard'].base64String,
			})
		}

		// console.log('changedFiles', changedFiles)
		// console.log('uploadedFiles', uploadedFiles)
		if (apiData.client) {
			state.clients.savingText = "Salvando informações do cliente..."
			await api.put(`clients/${client.id}`, apiData.client)
		}

		if (apiData.investorDeclaration) {
			state.clients.savingText = "Salvando aceite da assinatura..."
			await api.put(`registrationForm/${client.registrationFormId}`, {
				id:client.registrationFormId,
				registrationFormId:client.registrationFormId,
				local:apiData.investorDeclaration?.local,
				date:apiData.investorDeclaration?.date,
			})

			//Signature
			if (changedFiles['investorDeclaration.signature']) {
				state.clients.savingText = "Salvando imagem de assinatura..."
				const signatureRes = await api.post(`documentUploads`, {
					clientId:client.id,
					registrationFormId: client.registrationFormId,
					docType:docTypes.SIGNATURE,
					document : uploadedFiles['investorDeclaration.signature'].base64String,
				})
				if (signatureRes) {
					await api.post(`documentUploads/validSignature`, {
						documentId:signatureRes?.data?.id,
						isValidSignature: true,
					})
				}

			}
		}

		if (apiData.bankInformation) {
			state.clients.savingText = "Salvando informações bancárias..."
			await api.post(`bank-account`, apiData.bankInformation)
		}

		if (apiData.pep) {
			state.clients.savingText = "Salvando informações de PEP..."
			await api.post(`clientPep`, apiData.pep)
		}

		if (apiData.fatca) {
			state.clients.savingText = "Salvando informações de FATCA..."
			await api.post(`fatcas`, apiData.fatca)
		}

		if (1) {
		if (apiData.professionalInformation) {
			state.clients.savingText = "Salvando informações profissionais..."
			await api.post(`professionalInformations`, apiData.professionalInformation)
		}

		if (apiData.mailing && apiData.mailing.mailType) {
			state.clients.savingText = "Salvando informações de correspondência..."
			await api.post(`mailing`, apiData.mailing)
		}

		// if (client.status) {
		// 	state.clients.savingText = "Salvando status..."
		// 	const statusStr = client.status
		// 	await api.put(`clients/${client.id}/update/status/${statusStr}`)
		// }

		if (form.funds) {
			state.clients.savingText = "Salvando fundos escolhidos..."
			await api.post(`registrationForm/funds`, {
				registrationFormId:client.registrationFormId,
				fundsId : form.funds,
				termsId : form.funds,
			})
		}

		state.clients.savingText = "Salvando suitability..."
		await actions.clients.saveSuitability({formId, clientId:client.id})

		state.clients.savingText = "Validando a ficha..."
		await api.get(`checkListForm/status/${client.registrationFormId}/${client.id}/1`)
	}

		// if (1) {
		// 	//SCORE167
		// 	state.clients.savingText = "Gerando Score167..."
		// 	await api.post(`score/generate/${client.id}`)
		// }


		state.clients.savingText = ""
		state.clients.saving = false
		state.clients.done = true
		try {
			const scroller = Scroll.animateScroll;
			scroller.scrollToTop({
				containerId : 'main-content',
				smooth: true,
				offset: -700,
				duration:500,
			})
		} catch(e) {
			//alert('nao deu')
		}
		await incSaveCount({state})

		//console.log('changedFiles', changedFiles)

	} catch(e) {
		let errorCode=null
		try {
			errorCode = e?.response?.data.code || e?.response?.data[0].code
		} catch(e) {}

		if (['ERR063', 'ERR007'].includes(errorCode)) {
			state.clients.savingText = "Este documento já está cadastrado"
		} else {
			state.clients.savingText = e.toString()
		}
		console.log('save Error', e?.response?.data || e)
		throw e

		//state.clients.savingText = "Ocorreu um erro ao salvar: " + e.toString()

	} finally {
		state.clients.saving = false
		state.clients.done = false
	}
}

export const updateClientStatus = async ({state, actions}, {clientId, status}) => {
	await api.put(`clients/${clientId}/update/status/${status}`)
}

export const deleteClient = async ({state, actions}, clientId) => {
	await api.get(`admin/clients/delete/${clientId}`)
}

export const openZasignDocument = async ({state, actions}, documentUploadId) => {
	return api
		.get(`documentUploads/zapsign/url/${documentUploadId}`)
		.then(res => {
			const url = res?.data?.url
			if (url) {
				window.open(url, "_zapsign_" + documentUploadId)
			} else {
				alert(`Arquivo não foi encontrado`)
			}
			console.log(res?.data)
		})
}

export const saveSuitability = async ({state, actions}, {formId, clientId}) => {
	const model = state.forms[formId].model
	const registrationForm = model.registrationForm

	const formAnswers = model.suitability?.answers

	if (!formAnswers)
		return true

	const answers = []

	for (const answer in formAnswers) {
		answers.push({answerId:formAnswers[answer]})
	}

	return api
		.post(`suitabilities/saveSuitability/${clientId}/${registrationForm.id}/${state.clients.suitabilityQuestions.id}`, {answers})
		.then(res => {
			console.log('saveSuitability', res.data)
			return res.data
		})
		// .catch(e => {
		// 	console.log('saveSuitability Error', e?.response?.data || e.toString())
		// })

}

export const incSaveCount = async ({state}) => {
	if (isNaN(state.clients.saveCount))
		state.clients.saveCount = 0
    state.clients.saveCount++
}

/*

export const loadRegistrationForm = ({state}, registrationFormId ) => {
    state.clients.loadingRegistrationForm = true
    return api
        .get(`registrationForm/${registrationFormId}`)
        ///.then(sleeper(2000))
        .then( res => {
        	console.log("API", res?.data)
            return apiToLocal(res?.data)
        })
        .finally(() => {
		    state.clients.loadingRegistrationForm = false
        })
}*/

