import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
	Card,
	CardBody,
	CardTitle,
	CardText,
	TabPane,
	NavLink,
	NavItem,
	TabContent,
	Nav,
	Table,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import { useOvermind } from "../../../../../overmind";

// import FormTitleDefault from "../../../../components/Common/CustomForm/SubTitles/TitleDefault";
import Field from "../../../../../components/Common/CustomForm/Field";
import {dateIsoToBr} from "../../../../../renders/utils";
import LoadingSection from "../../../../../components/Common/LoadingSection/LoadingSection";
import DefaultTable from "../../../../../components/Common/Tables/DefaultTable/DefaultTable";
import {deviceTypesMapped} from "../../../../../constants/formLists";

const actionsList = {
	block : "Bloqueio",
	unblock : "Desbloqueio",
	"unblock-try" : "Tentativa",
	"change-status" : "Alteração de status",
	"session-delete" : "Encerramento de sessões",
	"change-client-password" : "Senha temporária",
}

const getDevicesInfo = (devices) => {
	if (!devices?.length)
		return null

	const devicesStr = []
	for (let i=0; i<=devices.length-1; i++) {
		const name = deviceTypesMapped[devices[i]]?.caption || devices[i]
		devicesStr.push(name)
		if (i>=devices.length-1)
			continue
		devicesStr.push(i>=devices.length-2 ? " e " : ", ")
	}

	return <div className="font-size-rem-3">
		Dispositivos: {devicesStr}
	</div>
}

function BlockHistory(props) {

	const formId = 'BlockedDevicesForm'
	const client = useOvermind('clients')
	const security = useOvermind('security')

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [history, setHistory] = useState(null)

	useEffect(() => {

		setLoading(true)
		// const url = `renew/list/${client.state.currentRegistrationForm.form.client.id}`

		security.actions
			.listHistory(client.state.currentRegistrationForm.form.client.id)
			//.then(sleep(2000))
			.then(data => {
				setHistory(data)
			})
			.catch(e => {
				setError('Não foi possível carregar as informações')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

    return (<>

		<LoadingSection loading={loading} error={error} noData={history?.length===0 ? "Não há histórico registrado." : null}>
			<Col className={"  "}>
				<Field
					type="title"
					formId={formId}
					caption="Histórico de segurança"
					formGroupClassName={"mb-0 pb-0"}
				/>
			</Col>

			<DefaultTable
				className="pt-0 mt-0"
				columns={[
					{id:'created_date', caption:'Data', formatter:(row) => dateIsoToBr(row.createdAt, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					{id:'action', caption:'Ação', formatter:(row) => actionsList[row.action] || row.action},
					{id:'author', caption:'Usuário', formatter:(row) => row.user?.name || row.client?.name},
					{id:'message', caption:'Mensagem', formatter:function A(row) {
						let message = row.message
						const devices = row.userId ? getDevicesInfo(row.deviceTypes) : null

						return <>
							{message}
							{devices}
						</>
					}},
					{id:'deviceTypes', caption:'Origem/Dispositivo', formatter:function A(row) {
						return row.userId
							? <strong>Sistema</strong>
							: row.deviceTypes?.length && deviceTypesMapped[row.deviceTypes[0]].caption
					}},
				]}
				data={history}
			/>

		</LoadingSection>
	</>)

}

export default BlockHistory;
