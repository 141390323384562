import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../../helpers/gestora/api"

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../../overmind";
import {Datatable} from "../../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
moment.locale('pt-BR')

import CustomForm from "../../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import Button from "../../../../components/Common/Button/Button"

import ProfilesView from "../View";

import PageDatatable from "../../../../components/Common/Tables/Datatable/PageDatatable";
import DefaultPageHeader from "../../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../../common/url";
import {yesNoOptions} from "../../../../constants/formLists";
import {DataActionButton} from "../../../../components/Common/Tables/Datatable/DataTypes";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";
import {UserCan} from "../../../../helpers/gestora/auth";
import {useLocalStorage} from "../../../../helpers/gestora/UseLocalStorage";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

//console.log('statusTabs', statusTabs)

export const ProfilesSearch = () => {
	const formId = 'ProfilesSearch'
	const location = useLocation()
	const history = useHistory()

	// const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	// const [compactMode, setCompactMode] = useState(true)
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [exportingResult, setExportingResult] = useState(false)

	const [viewId, setViewId] = useState(null)


	// const app = useOvermind('app')

	const apiEndpoint = {
		path : `profiles/search`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			setModelValueByPath,
		}
	} = useOvermind('forms')

	const profiles = useOvermind('profiles')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`profiles/downloadCsv`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
			//updatedAt : model.updatedAt ? dateBrToISO(model.updatedAt) : null,
			//value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		setViewId(row.id)
	};

	const handleNew = async () => {
		setViewId('0')
	};

	const handleDelete = async (row) => {
		if (confirm(`Deletar perfil "${row.name}" ?`)) {
			profiles
				.actions
				.deleteById(row.id)
				.then(() => {
					refreshList()
				})
				.catch((e) => {
					const errCode = e.response?.data?.error?.message
					if (errCode==='being_used') {
						alert(`\nNão foi possível remover este perfil, ele está sendo utilizado por algum usuário.`)
					} else {
						alert('Ocorreu um erro ao tentar deletar este perfil')
					}
				})
		}
	};

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'name',
			name: <CustomHeader onComplete={handleFilterChange}  id={'name'} formId={formId} fieldProps={{type:'text'}}>Nome do perfil</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'actions',
			name: "Ações",
			grow:0,
			minWidth:'72px',
			center:true,
			omit : !UserCan('profiles.edit'),
			selector: function A(row) {
				return <>
					<DataActionButton
						disabled={row.id==1}
						buttonClass="btn btn-outline-danger m-1 btn-sm"
						iconClass="fas fa-trash m-1"
						onClick={() => handleDelete(row)}
					/>
				</>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
	], []);

	//console.log(orderDirection)
	return (<UserCanAll permission="profiles.consult">
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Perfis de usuário"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				{viewId!==null ?
				<ProfilesView
					formId={formId}
					viewId={viewId}
					onConfirm={() => {
						setViewId(null)
						refreshList()
					}}
					onCancel={() => {
						setViewId(null)
					}}
				/> : null}

				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<UserCanAll permission="profiles.edit">
						<Button
							className="btn btn-primary m-1 btn-sm"
							onClick={handleNew}
						>
							<i className="fas fa-plus m-1" />
							Adicionar Perfil
						</Button>
						</UserCanAll>

						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						{/*<Button*/}
						{/*	disabled={false}*/}
						{/*	colorIcon={true}*/}
						{/*	className="btn btn-outline-primary m-1 btn-sm"*/}
						{/*	// disabled={currentScreen==0}*/}
						{/*	onClick={() => setCompactMode(!compactMode)}>*/}
						{/*	<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>*/}
						{/*</Button>*/}
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	);
};

export default {
	title: 'Pagination/Remote',
	component: ProfilesSearch,
};
