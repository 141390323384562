import moment from "moment"
moment.locale('pt-BR')
import {isCpfFormat} from "../components/Common/CustomForm/Utils/Validations";

export const onlyNumbers = (value) => {
	if (typeof value == 'number' && value)
		return value.toString()

	return value ? value.replace(/[^\d]+/g,'') : ''
}

export const booleanValue = (value) => {
	return (value==true || value=='true' || value=='1')  ? '1' : '0'
}

export const optionValue = (value) => {
	return value!==undefined && value!==null && value ? value.toString() : ''
}

export const formatDocument = (document) => {
	try {
		return isCpfFormat(document)
				? formatCpf(document)
				: formatCnpj(document)
	} catch(e) {
		return document
	}
}

export const formatCpf = (cpf) => {
	try {
		return cpf
			.replace(/[^\d]/g, "")
			.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
	} catch(e) {
		return cpf
	}
}

export const formatCnpj = (cnpj) => {
	try {
    return cnpj
		.replace(/[^\d]/g, "")
		.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
	} catch(e) {
		return cnpj
	}
}

// export const dateISOToBr = (strDatetime, skipTime = false) => {
// 	if (!strDatetime) return '';
// 	const datetime = strDatetime.split(' ');
// 	const date = datetime[0].split('-');
// 	const time =
// 		datetime[1] === '00:00:00' || skipTime ? null : datetime[1].split(':');
//
// 	let ret = `${date[2]}/${date[1]}/${date[0]}`;
//
// 	if (time) ret += ` ${time[0]}:${time[1]}`;
//
// 	return ret;
// };

export const dateTimestampToBr = (timestamp, skipTime = true) => {
	//console.log('dateTimestampToBr', {timestamp, skipTime})
	if (!timestamp) return '';

	try {
		return moment(timestamp).format('DD/MM/YYYY')
	} catch(e) {
		return ''
	}

	// const date = new Date(timestamp);
	// let day = `${date.getDate()}`;
	// let month = `${date.getMonth() + 1}`;
	// const year = `${date.getFullYear()}`;
	// if (day.length < 2) day = `0${day}`;
	// if (month.length < 2) month = `0${month}`;
	//
	// const str = `${day}/${month}/${year}`;
	// // if (str=='01/01/1970')
	//
	// return str;
};


export const MoneyFormat = (value, prefix='', decimals=2) => {
	try {
		if (!value || value === undefined) value = 0;
		//console.log("MoneyFormat", value)
		const result = Number(value)
			.toFixed(decimals)
			.toString()
			.replace('.', ',')
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		//console.log("MoneyFormatResult", result)
		return prefix+result.toString()
	} catch (e) {
		 console.log('MoneyFormat error: ', {e, value})
		return prefix ? prefix + '0,0' : 0.0;
	}
};

export const dateTimestampToISO = (timestamp, skipTime = true) => {
	const date = new Date(timestamp);
	let day = `${date.getDate()}`;
	let month = `${date.getMonth() + 1}`;
	const year = `${date.getFullYear()}`;
	if (day.length < 2) day = `0${day}`;
	if (month.length < 2) month = `0${month}`;

	return `${year}-${month}-${day}`;
};

export const dateBrToISO = (value) => {
	if (value?.length===10)
		value = `${value} 12:00:00`

	let MD = moment(value, 'DD/MM/YYYY')
	const result = MD.isValid()
		? MD.format('YYYY-MM-DD')
		: undefined

	// console.log({length:value.length, value, result})
	return result
};

export const dateIsoToBr = (value, withTime=false, format=null) => {
	//console.log('dateIsoToBr', {value, withTime, format})
	//let dateObj = new Date(value)
	let MD = moment(value)
	return MD.isValid()
		? MD.format(
			format ? format : (
				withTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY'
			)
		)
		: undefined
}

export const clearSpaces = (str, allowNumbers=false) => {
	const reg = allowNumbers
		? /[^a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g
		: /[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g

	return str.replace(reg, "").trim();
}

export const clearPonctuation = (str, allowNumbers=false) => {

	if (!str) return '';

	const reg = allowNumbers
		? /[^a-zA-Z0-9 ]/g
		: /[^a-zA-Z ]/g

	//str = str.replace(/[^a-zA-Z ]/g, "")
	//console.log('str', str)

	if (!str.normalize)
		return str.replace(reg, " ");

	const normal = str.normalize('NFD');

	if (!normal)
		return str.replace(reg, " ");

	return str.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(reg, " ");
};

export const isPepActive = (client, pep) => {
		let pepActive = false;
		// console.log({
		// 	pep, client
		// })
		if (pep && client && client.haveWorked=='1' && pep.initialDate && pep.endDate) {
			// alert()
			// moment.locale('pt-br');

			const currentDate = moment().format('YYYY-MM-DD');
			const initialDate = dateBrToISO(
				pep.initialDate
			);
			const endDate = dateBrToISO(
				pep.endDate
			);
			pepActive =
				currentDate == initialDate ||
				currentDate == endDate ||
				moment(currentDate).isBetween(
					initialDate,
					endDate
				);
			//alert(`${currentDate} is between ${initialDate} - ${endDate}  = ${pa}`)
		}
		//console.log('isPepOcuppationActive', pepActive)
		return pepActive
}

