import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useLocation, useHistory, useParams } from "react-router-dom"

import { useOvermind } from '../../../../overmind'

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../../components/Common/Button/Button"

import DefaultPage from "../../../../components/Common/DefaultPage/DefaultPage";
import DefaultPageHeader from "../../../../components/Common/DefaultPage/DefaultPageHeader";
import CustomForm from "../../../../components/Common/CustomForm/Form";

import RiskProfileCategories from "./RiskProfileCategories";
import {parsePercent} from "../../../../helpers/parser_helper";
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";
import PageDatatable from "../../../../components/Common/Tables/Datatable/PageDatatable";

const statusTabs = [
	{id:'all', caption:"Informações"},
]

function RiskProfileView(props) {

    const {
		// viewId,
	} = props

	const formId = 'RiskProfileView'
	const readOnly = !UserCan('riskProfiles.edit')

	const location = useLocation()
	const history = useHistory()
	const params = useParams();

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setFormModel, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const riskProfiles = useOvermind('riskProfiles')

	const [viewId, setViewId] = useState(null)
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [hasRelation, setHasRelation] = useState(false)
	const [viewProfileId, setViewProfileId] = useState(null)

	//const [data, setData] = useState(null)

    useEffect(() => {
		if (viewId==null) return

    	setError(false)
    	setSaving(false)
    	setSaved(false)

		if (viewId==='0') {
			setLoading(true)
			setFormModel({formId, model:{edit:{}}})
			riskProfiles.actions.clearCategories()
			incFormValidatedCount({formId})
			setTimeout(() => setLoading(false), 1)

		} else {
			setLoading(true)
			// resetForm({formId})
			riskProfiles.actions.loadById({formId, id:viewId})
				.then(async record => {

					//setData(record)
					setHasRelation(record.hasRelation)
					setFormModel({formId, model:{suitabilityName:record.suitabilityName}})
					await riskProfiles.actions.updateTotalPoints()
					incFormValidatedCount({formId})
					setTimeout(() => setLoading(false), 100)

				})
				.catch(e => {
					setError(e.response?.data || e.toString())
				})
				.finally(() => {
					setLoading(false)
				})
		}



    }, [viewId])

	useEffect(() => {
		setViewId(params?.id || '')
	}, [params])

	const handleConfirm = async (e) => {

    	const categories = []
		for (const category of riskProfiles.state.categories) {
			categories.push({
				...category,
				rfPercent : parsePercent(category.rfPercent),
				rvPercent : parsePercent(category.rvPercent),
				mmPercent : parsePercent(category.mmPercent),
			})
		}

		const data = {
			suitabilityName:model.suitabilityName,
			partners : [1],
			categories,
		}
		delete data.edit
		if (hasRelation) {
			delete data.categories
		}

    	setSaving(true)
    	riskProfiles.actions.save({
				formId,
				id:viewId,
				data,
			})
			// .then(sleep(1000))
			.then(() => {
				history.push(`/supportTables/riskProfile/search`)
			})
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				if (errCode=='cant_edit') {
					alert('Não é possível salvar este perfil')
					setSaving(false)
				} else {
					setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
					setSaving(false)
					setSaved(false)
				}

			})

	}

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				// {
				// 	id:'title1',
				// 	caption:'Informações',
				// 	type:'title',
				// 	required:true,
				// 	row:true,
				// 	col:12
				// },
				{
					id:'suitabilityName',
					caption:'Nome do perfil',
					type:'text',
					readOnly,
					required:true,
					row:true,
					col:12
				},
				// {
				// 	id:'title2',
				// 	caption:'Classificações',
				// 	type:'title',
				// 	required:true,
				// 	row:true,
				// 	col:12
				// },
				{
					id:'riskProfilesCategories',
					component:RiskProfileCategories,
					hasRelation:hasRelation,
				},
			],
		},
	], [viewId, hasRelation]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	const handleCancel = () => {
		if (readOnly || confirm("Cancelar as alterações?")) {
	    	history.push(`/supportTables/riskProfile/search`)
		}
	}


	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <>
		<DefaultPage headerComponent={<DefaultPageHeader title="Perfil de investimento"  />}>
			<LoadingSection loading={loading} error={error}>

				<PageDatatable
					formId={formId}
					tabs={statusTabs}
					activeTab={'all'}
					TabActions={null}
				>
					<Row className="pr-4 ps-4">
						<Col md={12} className="pt-2 ">
							<Row >
							<Col md={12} className="w-100 d-block align-items-start">
								<CustomForm
									className="needs-validation"
									formId={formId}
									model={model}
									validateOnLoad={false}
								>
									<FormWizard
										validationInRealTime={true}
										screenId={formId}
										screens={statusScreens}
										formId={formId}
										// OnInputValueChange={handleInputValueChange}
										tabId={formId}
									/>
								</CustomForm>
							</Col>
							</Row>
							<Row className="position-sticky position-absolute bg-white " style={{bottom:0}}>
								{(hasRelation || riskProfiles.state.categoriesRangesPointOK ) ? null :<>
								<UserCanAll permission="riskProfiles.edit">
									<div className="text-center text-danger font-weight-600 font-size-em-1 pt-2">
										{riskProfiles.state.categories.length
											? "Para salvar, verifique se os limites de score estão corretos, erros estão marcados em laranja."
											: 'Adicione pelo menos 1 classificação'}

									</div>
								</UserCanAll>
								</>}
								<Col md={12} className="d-flex align-items-center justify-content-center">
									<Button
										className="btn btn-outline-danger"
										onClick={handleCancel}
										style={{margin:'1em'}}
									>
										Voltar
									</Button>
									<UserCanAll permission="riskProfiles.edit">
									<Button
										loading={saving}
										disabled={!!formValidationResult?.first  || !riskProfiles.state.categoriesRangesPointOK }
										colorIcon={true}
										className="btn btn-primary "
										// disabled={currentScreen==0}
										style={{margin:'1em'}}
										onClick={handleConfirm}
										>
										Salvar
									</Button>
									</UserCanAll>

								</Col>
							</Row>
						</Col>
					</Row>
				</PageDatatable>
			</LoadingSection>
		</DefaultPage>
        </>
    )
}

export default RiskProfileView;
