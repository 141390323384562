import React, {useEffect, useState} from "react"
import {Spinner} from "reactstrap";
import {
	Dropdown,
	DropdownToggle, DropdownMenu, DropdownItem,
	Button
} from "reactstrap"

const DropDownIcon = ({option, ...props}) =>
	<>
		<i className={`${option?.icon} font-size-16 align-middle me-1`}></i>
		{" "}
	</>

function DropDown({
		children,
		options = [],
		loading = false,
		placeHolder = 'Nenhum',
		unselected = {id:-1, caption:placeHolder, color:'primary', class:'primary'},
		selected = unselected,
		defaultValue = null,
		disabled=false,
		onChange=() => {},
		...props
	}) {

	const [isOpen, setIsOpen] = useState(false)

	const handleOnChange = (evt) => {
		if (!evt?.target?.id)
			return;

		const selectedOption = options.find(option => option.id == evt.target.id)
		//alert(evt.target.id)
		//if (selectedOption.id!=selected.id)
			onChange(selectedOption)
		// console.log('selectedId', evt.target.id)
		// console.log('selectedOption', selectedOption)
	}

    return (
		<Dropdown
			isOpen={isOpen}
			toggle={() => setIsOpen(!isOpen)}
			onClick={handleOnChange}
			disabled={loading || disabled}
			className="text-nowrap"
			style={{
				opacity:loading || disabled ? 0.8 : undefined,
				pointerEvents:loading || disabled ? "none" : undefined,
			}}
		>
			<DropdownToggle tag="button" type="button" className={`btn btn-${selected?.class}`}>
				  {loading ? (
					<Spinner
					  style={{ width: "0.5rem", height: "0.5rem", marginRight:"0.5em", marginTop: '.2em', marginBottom:'.2em' }}
					  type="grow"
					  color={"light"}
					/>
				  ):selected?.icon ? <DropDownIcon option={selected}/> : null}

				{selected && selected.caption} {disabled ? null :  <i className="mdi mdi-chevron-down" />}
			</DropdownToggle>
			<DropdownMenu>
				{options.map((option) => {
					return <DropdownItem key={option.id} id={option.id} className={`text-${option.color} ${option.color}`} >
						<DropDownIcon option={option}/>
						{option.caption}
					</DropdownItem>
				})}
			</DropdownMenu>
		</Dropdown>

    )
}

export default DropDown;
