import api, {sleep} from "../../helpers/gestora/api";


export const updateStatus = async ( { state, actions }) => {
	let suitabilityOK = true
	const questions = state.suitability.questions

	let ok=false
	const questionsOK = []
	for (let i=0; i<questions.length; i++) {
		const question = questions[i]
		if (question.answers.length>=2) {
			ok=true
		} else {
			suitabilityOK = false
		}
		questionsOK.push(ok)

	}

	if (!questions.length)
		suitabilityOK = false

	// alert(suitabilityOK)
	// console.log('categoriesRangesOK', categoriesRangesOK)
	state.suitability.suitabilityOK = suitabilityOK
	state.suitability.questionsOK = questionsOK
}

export const clearQuestions = async ( { state, actions } ) => {
	state.suitability.questions = []
	await actions.suitability.updateStatus()
}

export const setQuestions = async ( { state, actions  }, questions ) => {
	const parsed = []
	for (const question of questions) {
		parsed.push({
			...question,
			//minScore : category.minScore.toString()
		})
	}
	state.suitability.questions = parsed
	await actions.suitability.updateStatus()
}

export const addQuestion = async ( { state, actions }, {formId, question} ) => {
	state.suitability.questions.push(question)
	await actions.suitability.updateStatus()
}

export const deleteQuestionByIndex = async ( { state, actions }, index ) => {
	state.suitability.questions.splice(index, 1);
	await actions.suitability.updateStatus()
}

export const replaceQuestionByIndex = async ( { state, actions }, {index, question} ) => {
	if (index !== -1) {
		state.suitability.questions[index] = question;
	}
	await actions.suitability.updateStatus()
}

export const addAnswer = async ( { state, actions }, {formId, answer, questionIndex} ) => {
	state.suitability.questions[questionIndex].answers.push(answer)
	await actions.suitability.updateStatus()
}

export const deleteAnswerByIndex = async ( { state, actions }, {index, questionIndex} ) => {
	const questions = [...state.suitability.questions]

	questions[questionIndex].answers.splice(index, 1);
	state.suitability.questions = questions;
	await actions.suitability.updateStatus()
}

export const replaceAnswerByIndex = async ( { state, actions }, {index, answer, questionIndex} ) => {
	if (index !== -1) {
		state.suitability.questions[questionIndex].answers[index] = answer;
	}
	await actions.suitability.updateStatus()
}

export const loadById = async ( { state, actions }, {formId, id} ) => {

	return api
		.get(`suitabilities/${id}`)
		.then(async res => {
			const asks = res.data.asks
			for (const ask of asks) {
				ask.active = ask.active ? '1' : '0'
			}

			await actions.suitability.setQuestions(asks)
			return {
				...res.data,
				active : asks.active ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, {formId, id, data} ) => {

	const request = parseInt(id)
		? api.put(`suitabilities/${id}`, data)
		: api.post(`suitabilities/`, data)

	return await request
		.then(async res => {
			return res.data
		})
}

export const deleteById = async ( { state, actions }, id ) => {

	return api.delete(`suitabilities/${id}`)
		.then(async res => {
			return res.data
		})
}

export const markActive = async ( { state, actions }, id ) => {

	return api.get(`suitabilities/activate/${id}`)
		.then(async res => {
			return res.data
		})
}

export const getClientSuitability = async ( { state, actions }, clientId ) => {
	return api.get(`suitabilities/getClientSuitability/${clientId}`)
		.then(async res => {
			return res.data
		})
}

