import React, {useEffect, useState} from "react"

//import { AvField, AvFeedback } from "availity-reactstrap-validation"
import {CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import { AvField, AvFeedback, InputGroupAddon  } from "availity-reactstrap-validation"
import {getValidDomProperties} from "../Utils/DomProp";

function FormTitleCollapsible(props) {

    const {caption, subtitle, type, inputRef, formId} = props
	const [open, setOpen] = useState(false)

	const rest = getValidDomProperties(props)

	const handleToogle = () => {
		setOpen(!open)
	}

	useEffect(() => {
        if (props.onChange)
            props.onChange(props.name, open ? '1' : '0')
	}, [open])

    return (
        <Row>
			<AvField
				ref={props.inputRef || undefined}
				type="hidden"
				{...rest}
				autoComplete="disabled"
				// enabled={true}
				required={props.required}
				// validate={{val:() => isValidValue(value, props) }}
				validate={props.validation}
				value={open ? '1' : '0'}

			/>
            <Col className="mb-2">
                <CardTitle className="mb-0 mt-0 text-primary font-family-title font-weight-700 font-size-rem1" style={{cursor:'pointer'}} onClick={handleToogle}>
                    {caption}
                    <i className={`fa ${!open ? "fa-sort-up align-bottom" : "fa-sort-down align-top"} font-size-20 ms-2`}></i>
                </CardTitle>
                {subtitle && (
                <CardSubtitle className="mt-2 mb-2 px-2 text-muted " style={{fontSize:'0.9rem', fontWeight:'normal'}}>
                    {subtitle}
                </CardSubtitle>
                )}
            </Col>
        </Row>
    )
}

export default FormTitleCollapsible;
