import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../../helpers/gestora/api"
import {Col, Row, Badge} from "reactstrap";

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../../overmind";
import {Datatable} from "../../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
moment.locale('pt-BR')
import {dateBrToISO, MoneyFormat} from "../../../../renders/utils";

import CustomForm from "../../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import Button from "../../../../components/Common/Button/Button"

import FundTypesView from "../View";

import {parseMoney} from "../../../../helpers/parser_helper";
import PageDatatable from "../../../../components/Common/Tables/Datatable/PageDatatable";
import {DataBank, DataDate} from "../../../../components/Common/Tables/Datatable/DataTypes";
import DefaultPageHeader from "../../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../../common/url";
import {useLocalStorage} from "../../../../helpers/gestora/UseLocalStorage";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

//console.log('statusTabs', statusTabs)

export const FundTypesSearch = () => {
	const formId = 'FundTypesSearch'
	const location = useLocation()
	const history = useHistory()

	// const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	// const [compactMode, setCompactMode] = useState(true)
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [exportingResult, setExportingResult] = useState(false)

	const [viewId, setViewId] = useState(0)


	// const app = useOvermind('app')

	const apiEndpoint = {
		path : `admin/fundtypes/search`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			setModelValueByPath,
		}
	} = useOvermind('forms')

	const fundTypes = useOvermind('fundTypes')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`admin/fundtypes/downloadxls`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	// useEffect(() => {
	// 	// setModelValueByPath({formId, path:'period', value:history.location?.state?.period || null})
	//
	// 	// if (history.location?.state?.status)
	// 	// 	setModelValueByPath({formId, path:'status', value:history.location?.state?.status})
	//
	// }, [history.location])

	// useEffect(() => {
	// 	updateModelValueValues({formId, values:{
	// 		fundId:null,
	// 		operation:null,
	// 	}})
	//
	// 	// if (!history.location?.state)
	// }, [location])


	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
			updatedAt : model.updatedAt ? dateBrToISO(model.updatedAt) : null,
			value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		setViewId(row.id)
	};

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'version',
			name: <CustomHeader onComplete={handleFilterChange} id={'version'} formId={formId} fieldProps={{type:'number'}}>Versão</CustomHeader>,
			// name: "Versão",
			grow:0,
			width:'96px',
			selector: row => row?.version,
			sortable: true,
			compact : true,
			wrap : true,

		},
		{
			id : 'name',
			name: <CustomHeader onComplete={handleFilterChange}  id={'fundId'} formId={formId} fieldProps={{type:'select2multi', listId:'InvestmentFunds'}}>Nome do fundo</CustomHeader>,
			// name: "Nome do fundo",
			grow:1,
			minWidth:'128px',
			selector: row => row?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'account',
			name: <CustomHeader onComplete={handleFilterChange} id={'account'} formId={formId} fieldProps={{type:'text'}}>Conta</CustomHeader>,
			// name: "Conta",
			grow:0,
			width:'80px',
			selector: row => row?.account,
			omit : compactMode,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'bank',
			name: <CustomHeader onComplete={handleFilterChange} id={'bank'} formId={formId} fieldProps={{type:'text'}}>Banco</CustomHeader>,
			// name: "Banco",
			width:'128px',
			grow:0.1,
			selector: row => row?.bank,
			omit : compactMode,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'agency',
			name: <CustomHeader onComplete={handleFilterChange} id={'agency'} formId={formId} fieldProps={{type:'text'}}>Agência</CustomHeader>,
			// name: "Agência",
			grow:0.1,
			width:'96px',
			selector: row =>  row?.agency,
			omit : compactMode,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'digit',
			// name: "Dígito",
			name: <CustomHeader onComplete={handleFilterChange} id={'digit'} formId={formId} fieldProps={{type:'text'}}>Dígito</CustomHeader>,
			grow:0.7,
			width:'80px',
			selector: row => row?.digit,
			sortable: true,
			omit : compactMode,
			compact : true,
			wrap : true,
		},
		{
			id : 'cnpj',
			name: <CustomHeader onComplete={handleFilterChange} id={'cnpj'} formId={formId} fieldProps={{type:'cnpj'}}>CNPJ</CustomHeader>,
			// name: "CNPJ",
			width:'156px',
			grow:0.7,
			selector: row => row?.cnpj,
			omit : compactMode,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'initial_value',
			name: <CustomHeader onComplete={handleFilterChange}  id={'initialValue'} formId={formId} fieldProps={{type:'money'}}>Aporte inicial</CustomHeader>,
			// name: "Aporte inicial",
			grow:0.1,
			width:'128px',
			selector: row => MoneyFormat(row?.initialValue),
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'minimum_value',
			name: <CustomHeader onComplete={handleFilterChange}  id={'minimumValue'} formId={formId} fieldProps={{type:'money'}}>Aporte mínimo</CustomHeader>,
			// name: "Aporte mínimo",
			grow:0.1,
			width:'140px',
			selector: row => MoneyFormat(row?.minimumValue),
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'updated_at',
			name: <CustomHeader onComplete={handleFilterChange} id={'updatedAt'} formId={formId} fieldProps={{type:'date'}}>Data de alteração</CustomHeader>,
			// name: "Data de alteração",
			grow:0.1,
			width:'172px',
			selector: function A(row) {
				return <DataDate value={row?.updatedAt}/>
			},
			sortable: true,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
	], []);

	//console.log(orderDirection)
	return (<>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Fundos"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				{viewId ?
				<FundTypesView
					formId={formId}
					viewId={viewId}
					onConfirm={() => {
						setViewId(0)
						refreshList()
					}}
					onCancel={() => {
						setViewId(null)
					}}
				/> : null}

				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						<Button
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={() => setCompactMode(!compactMode)}>
							<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>
						</Button>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</>
	);
};

export default {
	title: 'Pagination/Remote',
	component: FundTypesSearch,
};
