import React, {useEffect, useState} from "react"
import {Col, Row, Spinner} from "reactstrap";
import MetaTags from 'react-meta-tags';

import Sidebar from "../../VerticalLayout/Sidebar"
import Header from "../../VerticalLayout/Header"
import { useSelector, useDispatch } from "react-redux"

import {environment} from "../../../helpers/gestora/configuration";

import {
	changeLayout,
	changeSidebarTheme,
	changeSidebarThemeImage,
	changeSidebarType,
	changeTopbarTheme,
	changeLayoutWidth,
} from "../../../store/actions"
import {useOvermind} from "../../../overmind";

function DefaultPage( {children, ...props} ) {
	const [preloaded, setPreloaded] = useState(false)
	const app = useOvermind('app')
	useEffect(() => {
		app.actions.loadRemoteLists()
			.then(() => {
				setPreloaded(true)
			})
	}, [])

	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
	const {
		leftSideBarType,
	} = useSelector(state => ({
		// isPreloader: state.Layout.isPreloader,
		// leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
		leftSideBarType: state.Layout.leftSideBarType,
		// layoutWidth: state.Layout.layoutWidth,
		// topbarTheme: state.Layout.topbarTheme,
		// showRightSidebar: state.Layout.showRightSidebar,
		// leftSideBarTheme: state.Layout.leftSideBarTheme,
	}))

	const toggleMenuCallback = () => {
		if (leftSideBarType === "default") {
			dispatch(changeSidebarType("condensed", isMobile))
		} else if (leftSideBarType === "condensed") {
			dispatch(changeSidebarType("default", isMobile))
		}
	}

	// console.log('leftSideBarType', leftSideBarType)

	if (!preloaded)
		return null

	const pageTitle = environment==='production'
		? 'Fiduc Gestão - Admin'
		: `[${environment}] Fiduc Gestão - Admin`

	return <>
		<div className="page-content">
			<MetaTags>
				<title>{pageTitle}</title>
			</MetaTags>
			<Header toggleMenuCallback={toggleMenuCallback} headerComponent={props.headerComponent}/>
			{children}
		</div>
	</>
}

export default DefaultPage;
